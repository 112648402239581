import history from '../../history';
import showToaster from '../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';
import { addCancelReasonMutation as addMutation, updateCancelReasonMutation as updateMutation } from '../lib/graphql';
import {
    ADD_CANCEL_REASON_START,
    ADD_CANCEL_REASON_SUCCESS,
    ADD_CANCEL_REASON_ERROR
} from '../../constants/index';

export function addCancelReason(values) {
    return async (dispatch, getState, { client }) => {
        try {
            let status, mutation;
            dispatch({
                type: ADD_CANCEL_REASON_START
            });
            dispatch(setLoaderStart('AddCancellation'));

            mutation = values?.id ? updateMutation : addMutation;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id: values?.id,
                    reason: values?.reason,
                    userType: values?.userType,
                    isActive: values?.isActive == '0' ? 0 : 1
                }
            });

            status = values?.id ? data?.updateCancelReason?.status : data?.addCancelReason?.status;

            if (status == '200') {
                history.push('/siteadmin/cancel-reasons');
                showToaster({ messageId: 'cancelReasonStatus', toasterType: 'success', requestContent: values?.id ? 'updated' : 'added' });
                dispatch(setLoaderComplete('AddCancellation'));
                await dispatch({
                    type: ADD_CANCEL_REASON_SUCCESS,
                });
            } else {
                dispatch(setLoaderComplete('AddCancellation'));
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error' });
                await dispatch({
                    type: ADD_CANCEL_REASON_ERROR,
                });
            }
        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
            dispatch({ type: ADD_CANCEL_REASON_ERROR });
        }
    };
};

import fetch from 'node-fetch';
import { toastr } from 'react-redux-toastr';
import { pushNotificationMessage } from './pushNotificationMessage';
import { siteUrl } from '../../config';
import { getShortPushNotification } from './getShortPushNotification';

export async function sendNotifications(type, requestContent, userId, userType, lang) {
    let content = {};

    if (type !== 'notification') {
        const { title, message } = await pushNotificationMessage(type, requestContent, lang);
        const trimContent = await getShortPushNotification(message);
        content = requestContent;
        content['title'] = title;
        content['message'] = trimContent || message;
    } else {
        content = requestContent;
    }

    const resp = await fetch(siteUrl + '/push-notification', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            content,
            userId,
            userType
        }),
        credentials: 'include'
    });

    const { status, errorMessge } = await resp.json();

    return await {
        status,
        errorMessge
    };
}

export default sendNotifications;

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._4KnjQ{\n    text-align: right;\n}\n._2hmKA {\n    text-align: center;\n}\n.nXSZh {\n  width: 100%;\n  max-width: 220px;\n  margin: auto 0 auto auto;\n}\n.H1ILg {\n  position: relative;\n}\n.H1ILg::after {\n  bottom: 40px !important;\n  left: 0 !important;\n}\n._4jdyi {\n  padding-right: 5px;\n}\n._2Uc0y{\n  position: absolute;\n  top: 15px;\n  right: 18px;\n  cursor: pointer;\n}\n._3RH78{\n  position: relative;\n}\n ", ""]);

// exports
exports.locals = {
	"textAlignRight": "_4KnjQ",
	"textAlignCenter": "_2hmKA",
	"btnWidth": "nXSZh",
	"titleBottom": "H1ILg",
	"changePassPadding": "_4jdyi",
	"passwordIcon": "_2Uc0y",
	"positionRelative": "_3RH78"
};
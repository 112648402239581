import React, { Component } from 'react';
import s from './FooterSettingsForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
    Form,
    Col,
    Card,
    Row,
    FormControl,
    FormGroup,
    Container
} from 'react-bootstrap';
import Link from '../../Link/Link';
import messages from '../../../locale/messages';
import validate from './validate';
import Loader from '../../Common/Loader';
import { api, homepageUploadDir } from '../../../config';

export class FooterSettingsForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }

    render() {
        const { handleSubmit, footerLogo1, footerLogo2, footerLogo3, footerLogo4, loading, submitting } = this.props;
        const { formatMessage } = this.props.intl;
        return (

            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col lg={8} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <h1 className={s.titleTextAdmin}>{formatMessage(messages.footerSectionSettings)}</h1>
                                    </Col>
                                    {/* <Row>
                                    <Col lg={6} md={12} sm={12} xs={12} className={s.space2}>
                                        <Form.Group className={s.space3}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <label>{formatMessage(messages.footerLogo1)}</label><br />
                                                {
                                                    footerLogo1 &&
                                                    <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir + footerLogo1})` }} />
                                                }
                                                <div>
                                                    <Dropzone
                                                        className={s.btnSecondary}
                                                        subTextClass={s.subText}
                                                        fieldName={'footerLogo1'}
                                                        subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                        defaultMessage={formatMessage(messages.chooseFile)}
                                                        inputContainer={'.dzInputContainerFooterImage1'}
                                                        inputContainerClass={'dzInputContainerFooterImage1'}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12} className={s.space2}>
                                        <Form.Group className={s.space3}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <label>{formatMessage(messages.footerLogo2)}</label><br />
                                                {
                                                    footerLogo2 &&
                                                    <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir + footerLogo2})` }} />
                                                }
                                                <div>
                                                    <Dropzone
                                                        className={s.btnSecondary}
                                                        subTextClass={s.subText}
                                                        fieldName={'footerLogo2'}
                                                        subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                        defaultMessage={formatMessage(messages.chooseFile)}
                                                        inputContainer={'.dzInputContainerFooterImage2'}
                                                        inputContainerClass={'dzInputContainerFooterImage2'}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12} className={s.space2}>
                                        <Form.Group className={s.space3}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <label>{formatMessage(messages.footerLogo3)}</label><br />
                                                {
                                                    footerLogo3 &&
                                                    <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir + footerLogo3})` }} />
                                                }
                                                <div>
                                                    <Dropzone
                                                        className={s.btnSecondary}
                                                        subTextClass={s.subText}
                                                        fieldName={'footerLogo3'}
                                                        subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                        defaultMessage={formatMessage(messages.chooseFile)}
                                                        inputContainer={'.dzInputContainerFooterImage3'}
                                                        inputContainerClass={'dzInputContainerFooterImage3'}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12} className={s.space2}>
                                        <Form.Group className={s.space3}>
                                            <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                <label>{formatMessage(messages.footerLogo4)}</label><br />
                                                {
                                                    footerLogo4 &&
                                                    <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir + footerLogo4})` }} />
                                                }
                                                <div>
                                                    <Dropzone
                                                        className={s.btnSecondary}
                                                        subTextClass={s.subText}
                                                        fieldName={'footerLogo4'}
                                                        subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                        defaultMessage={formatMessage(messages.chooseFile)}
                                                        inputContainer={'.dzInputContainerFooterImage3'}
                                                        inputContainerClass={'dzInputContainerFooterImage3'}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row> */}
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="footerTitle1" type="text" component={this.renderField} label={formatMessage(messages.footerTitleLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="footerContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.footerContentLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="footerLinkTitle" type="text" component={this.renderField} label={formatMessage(messages.footerURLTitleLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="footerBottom" type="text" component={this.renderField} label={formatMessage(messages.copyRightsContentLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                        <Form.Group className={s.noMargin}>
                                            <div className={s.displayInlineBlock}>
                                                <Loader
                                                    type={"button"}
                                                    label={formatMessage(messages.submitButton)}
                                                    show={loading}
                                                    buttonType={'submit'}
                                                    className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                                                    disabled={submitting || loading}
                                                    isSuffix={true}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

FooterSettingsForm = reduxForm({
    form: 'FooterSettingsForm',
    onSubmit: submit,
    validate
})(FooterSettingsForm);

const selector = formValueSelector('FooterSettingsForm')
const mapState = (state) => ({
    footerLogo1: selector(state, 'footerLogo1'),
    footerLogo2: selector(state, 'footerLogo2'),
    footerLogo3: selector(state, 'footerLogo3'),
    footerLogo4: selector(state, 'footerLogo4'),
    loading: state.loader.SignupSettingsForm
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(FooterSettingsForm)));
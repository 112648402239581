import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';

import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages'
import { flowRight as compose } from 'lodash';

import UserDetails from './UserDetails';
import Link from '../../Link/Link';
import MessageItem from './MessageItem';

import defaultProfile from '../../../../public/RestaurantIcon/chatDefaultProfile.png';

import s from './ViewChatDetails.css';
import bt from '../../../components/commonStyle.css';

class ViewChatDetails extends React.Component {

  render() {
    const { threadItems: { getAllThreadItems }, loadMore, from } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={s.tabView}>
        <div className={cx(s.pagecontentWrapper, s.widthInner, bt.space5, 'bgBlackTwo')}>

          <div className={s.contentBox}>
            <h1 className={cx(s.titleTextAdmin, s.noMargin)}>{formatMessage(messages.chatMessage)}</h1>
            <Link to={'/siteadmin/' + from} className={cx(s.gobackBtn, s.btnSecondary, 'gobackBtnRtl',)}>
              <FormattedMessage {...messages.goBack} />
            </Link>
          </div>
          <div>
            <UserDetails
              userDetails={getAllThreadItems?.userDetails}
              deliveryPartnerDetails={getAllThreadItems?.deliveryPartnerDetails} />
          </div>
          {
            getAllThreadItems?.threadItems?.map((item, index) => {
              return (
                <div key={index}>
                  <MessageItem
                    avatarImage={defaultProfile}
                    text={item.message}
                    chatPadding={(item.authorId == getAllThreadItems?.userDetails?.id) ? 'left' : 'right'}
                    userImage={(item.authorId == getAllThreadItems?.userDetails?.id) ? getAllThreadItems?.userDetails?.profile?.picture : getAllThreadItems?.deliveryPartnerDetails?.profile?.picture}
                  />
                </div>
              )
            })
          }
          {
            getAllThreadItems?.threadItems?.length > 0 && getAllThreadItems.threadItems.length < getAllThreadItems.count && <div className={s.textCenter}>
              <a href="javascript:void(0)" onClick={() => loadMore()} className={cx(bt.button, bt.btnPrimary)}><FormattedMessage {...messages.loadMoreLabel} /></a>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default compose(injectIntl,
  withStyles(s, bt)
)(ViewChatDetails);
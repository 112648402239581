import React, { Component } from 'react'
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';

// Styles
import s from './AdminEarningsList.css';

// Helpers
import messages from '../../../../locale/messages';
import debounce from '../../../../helpers/debounce';
import { bookingStatus } from '../../../../helpers/bookingStatus';

// Component
import CustomPagination from '../../../CustomPagination';
import Link from '../../../Link';
import CurrencyConverter from '../../../CurrencyConverter';

// Icon
import ExportImage from '../../../../../public/Icons/export.png';

export class AdminEarningsList extends Component {

    static propTypes = {
        bookingDetails: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.state = {
            currentPage: 1,
            keyword: null
        };

        this.paginationData = this.paginationData.bind(this);
        this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
        this.calculateAdminEarnings = this.calculateAdminEarnings.bind(this);
    }

    handleKeywordSearch(keyword) {
        const { bookingDetails: { refetch } } = this.props
        let variables = {
            currentPage: 1,
            keyword,
            requestType: 'earnings'
        };

        this.setState({ keyword, currentPage: 1 });
        refetch({ currentPage: 1, keyword, requestType: 'earnings' });
    }

    paginationData(currentPage) {
        const { bookingDetails: { refetch } } = this.props;
        const { keyword } = this.state;

        let variables = {
            currentPage,
            keyword,
            requestType: 'earnings'
        };

        this.setState({ currentPage });
        refetch(variables);
    }

    calculateAdminEarnings(data) {
        let earnings = 0, adminEarnings = 0, adminOffered = 0;

        earnings = Number(data.userServiceFare) + Number(data.shopServiceFare) + Number(data.driverServiceFare);
        adminEarnings = earnings;
        if (data.isSpecialDelivery) {
            if (Number(earnings) > Number(data.specialDeliveryFare)) {
                adminEarnings = Number(earnings) - Number(data.specialDeliveryFare);
            } else {
                adminEarnings = 0;
                adminOffered = Number(data.specialDeliveryFare) - Number(earnings);
            }
        }

        return {
            adminEarnings,
            adminOffered
        }
    }

    render() {
        const { formatMessage, locale } = this.props.intl;
        const { bookingDetails, bookingDetails: { getAllOrdersAdmin } } = this.props;
        const { currentPage, keyword } = this.state;
        let type = 'view-earnings/';

        return (
            <div className={cx(s.widthInner, s.tableSection, 'widthInnerNoBorder')}>
                <div className={cx(s.alignItemCenter, s.tabelExport)}>

                    <div className={s.searchInput}>
                        <FormControl
                            type='text' placeholder={formatMessage(messages.search)}
                            onChange={(event) => this.handleKeywordSearch(event.target && event.target.value)}
                            className={s.formControlInput}
                        />
                    </div>

                    <div>
                        {
                            bookingDetails && getAllOrdersAdmin && getAllOrdersAdmin.count > 0 && <a
                                href={"/export-admin-data?type=earnings&keyword=" + keyword}
                                className={cx(s.exportText)}>
                                <span className={cx(s.vtrMiddle, s.exportText, 'exportTextRTL')}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                                <span className={s.vtrTextBottom}>
                                    <img src={ExportImage} className={s.exportImg} />
                                </span>
                            </a>
                        }
                    </div>
                </div>
                <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
                    <Table className="table">
                        <thead>
                            <tr>
                                <th scope="col"><FormattedMessage {...messages.orderId} /></th>
                                <th scope="col"><FormattedMessage {...messages.orderStatus} /></th>
                                <th scope="col"><FormattedMessage {...messages.paidVia} /></th>
                                <th scope="col"><FormattedMessage {...messages.orderAmount} /></th>
                                <th scope="col"><FormattedMessage {...messages.earnings} /></th>
                                <th scope="col"><FormattedMessage {...messages.overdraft} /></th>
                                <th scope="col"><FormattedMessage {...messages.action} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bookingDetails && getAllOrdersAdmin && getAllOrdersAdmin.count > 0 && getAllOrdersAdmin.results.map((item, key) => {
                                    let earnings = this.calculateAdminEarnings(item);

                                    return (
                                        <tr key={key}>
                                            <td data-label={formatMessage(messages.orderId)}>
                                                <Link to={'/siteadmin/admin-earnings/view/' + item.id}>
                                                    {`#${item.orderId}`}
                                                </Link>
                                            </td>
                                            <td data-label={formatMessage(messages.orderStatus)}>{bookingStatus(item.bookingStatus, locale)}</td>
                                            <td data-label={formatMessage(messages.paidVia)}>
                                                {
                                                    item.paymentType === 1 && <FormattedMessage {...messages.cashOnHand} />
                                                }
                                                {
                                                    item.paymentType === 2 && <FormattedMessage {...messages.card} />
                                                }
                                                {
                                                    item.paymentType === 3 && <FormattedMessage {...messages.wallet} />
                                                }
                                            </td>
                                            <td data-label={formatMessage(messages.orderAmount)}>
                                                <CurrencyConverter
                                                    from={item.currency}
                                                    amount={item.userPayableFare}
                                                />
                                            </td>
                                            <td data-label={formatMessage(messages.earnings)}>
                                                <CurrencyConverter
                                                    from={item.currency}
                                                    amount={earnings.adminEarnings}
                                                />
                                            </td>
                                            <td data-label={formatMessage(messages.overdraft)}>
                                                <CurrencyConverter
                                                    from={item.currency}
                                                    amount={earnings.adminOffered}
                                                />
                                            </td>
                                            <td data-label={formatMessage(messages.action)}>
                                                <Link to={'/siteadmin/admin-earnings/view/' + item.id}>
                                                    <FormattedMessage {...messages.view} />
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                bookingDetails && getAllOrdersAdmin && getAllOrdersAdmin.count === 0 && <tr>
                                    <td colSpan={50} className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </div>
                {
                    getAllOrdersAdmin && getAllOrdersAdmin.count > 0 && <div className={cx(s.space5, s.spaceTop5)}>
                        <CustomPagination
                            total={getAllOrdersAdmin.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={formatMessage(messages.ordersLabel)}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default injectIntl(withStyles(s)(AdminEarningsList));
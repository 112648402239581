import React, { Component } from 'react';

import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';

import { api, profilePhotouploadDir } from '../../../config';

import batchIcon from '../../../../public/Icons/driverChatIcon.svg';

import s from './ViewChatDetails.css';
import bt from '../../../components/commonStyle.css';
class MessageItem extends Component {
  render() {
    const { avatarImage, text, userImage, chatPadding } = this.props;
    return (
      <>
        <div className={cx({ [s.displayFlexSender]: chatPadding == 'left' }, { [s.displayFlexReverse]: chatPadding == 'right' })}>
          <div className={cx({ [s.senderBgScetion]: chatPadding == 'left' }, { [s.receiverBgScetion]: chatPadding == 'right' }, { ['receiverBgScetionRTL']: chatPadding == 'right' }, { ['senderBgScetion']: chatPadding == 'left' })}>
            <div className={s.positionRelative}>
              {
                userImage ?
                  <div>
                    <img src={`${api.apiEndpoint + profilePhotouploadDir}small_${userImage}`} className={s.bgImage} />
                    {chatPadding == 'right' && <img src={batchIcon} className={cx(s.batchIconDriver, 'batchIconDriverRTL')} />}
                  </div> :
                  <div className={s.avatarImageBg}>
                    <img src={avatarImage} className={s.avatarImage} />
                    {chatPadding == 'right' && <img src={batchIcon} className={s.batchIcon} />}
                  </div>}
            </div>
          </div>
          <div className={cx({ [s.senderRadius]: chatPadding == 'left' }, { [s.receiverRadius]: chatPadding == 'right' }, { ['receiverRadius']: chatPadding == 'right' }, { ['senderRadius']: chatPadding == 'left' })}>
            <span className={s.chatMessageTextWrap}>{text}</span>
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(withStyles(s, bt)((MessageItem)));
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Container, Row, Col } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { connect } from 'react-redux'
import { flowRight as compose } from 'lodash'
import { graphql } from 'react-apollo';
import {FaInstagram, FaXTwitter} from 'react-icons/fa6'
import { FiFacebook} from "react-icons/fi";
import { TfiYoutube} from "react-icons/tfi";

import Link from '../Link';

import getAllHomePageSettings from './getAllHomePageSettings.graphql'
import getContentPageDetails from './getContentPage.graphql'
import { siteUrl, api, homepageUploadDir } from '../../config'
import messages from '../../locale/messages';

import AppStoreIcon from '../../../public/SiteImages/app-store.png';
import googleIcon from '../../../public/SiteImages/play-store.png';

import s from './Footer.css';


class Footer extends React.Component {
  render() {
    const { getAllHomePageSettings: { loading, getAllHomePageSettings } } = this.props
    const { getContentPageDetails: { getContentPageDetails } } = this.props;
    const { youtubeLink, twitterLink, facebookLink, instagramLink, siteName } = this.props

    let homeSettings = {}
    if (!loading && getAllHomePageSettings && getAllHomePageSettings.homePageData) {
      getAllHomePageSettings && getAllHomePageSettings.homePageData.map((item) => {
        homeSettings[item.name] = item.value
      })
    }

    const apiEndpoint = api && api.apiEndpoint;

    return (
      <div>
        {!loading && getAllHomePageSettings && getAllHomePageSettings.homePageData &&
          <div className={cx(s.footer, s.heroBkgAnimated)} id="footer-section">
            <Container fluid className={cx(s.containerWidth)}>
              <Row>
                <Col lg={4} md={4} sm={12} xs={12} className={cx(s.space3)}>
                  <div className={cx(s.content)}>
                    <h3>
                      <span className={s.eatsColor}>{siteName}</span>
                    </h3>
                    <div>
                      <Col xs={12} sm={12} md={12} lg={12} className={cx(s.noPadding, s.googleIconMargin, 'noPaddingFooterRTL')}>
                        <div>
                          <div className={cx(s.displayInlineBlock, s.paddingRight, 'applogoFooterRTL')}>
                            <a href={homeSettings.safetyGridLink1} target="_blank">
                              <img src={googleIcon} className={s.appIconImg} alt="googlePlay" />
                            </a>
                          </div>
                          <div className={cx(s.displayInlineBlock)}>
                            <a href={homeSettings.safetyGridLink2} target="_blank">
                              <img src={AppStoreIcon} className={s.appIconImg} alt="app-store" />
                            </a>
                          </div>
                        </div>
                      </Col>
                    </div>
                    <ul>
                      <li className={cx(s.displayInlineBlock, s.iconPaddingRight, 'noIconPaddingRightRTL')}>
                        <a href={facebookLink} target="_blank" className={s.fontAwesomeIcon}>
                          {/* <img src={apiEndpoint + homepageUploadDir + homeSettings.footerLogo1} className={s.socialIcon} /> */}
                          <FiFacebook />
                        </a>
                      </li>
                      <li className={cx(s.displayInlineBlock, s.iconPaddingRight)}>
                        <a href={instagramLink} target="_blank" className={s.fontAwesomeIcon}>
                          {/* <img src={apiEndpoint + homepageUploadDir + homeSettings.footerLogo2} className={s.socialIcon} /> */}
                          <FaInstagram />
                        </a>
                      </li>
                      <li className={cx(s.displayInlineBlock, s.iconPaddingRight)}>
                        <a href={twitterLink} target="_blank" className={s.fontAwesomeIcon}>
                          {/* <img src={apiEndpoint + homepageUploadDir + homeSettings.footerLogo3} className={s.socialIcon} /> */}
                          <FaXTwitter />
                        </a>
                      </li>
                      <li className={cx(s.displayInlineBlock, 'iconPaddingRightRTL')}>
                        <a href={youtubeLink} target="_blank" className={s.fontAwesomeIcon}>
                          {/* <img src={apiEndpoint + homepageUploadDir + homeSettings.footerLogo4} className={s.socialIcon} /> */}
                          <TfiYoutube />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={5} md={5} sm={12} xs={12} className={cx(s.space3)}>
                  <div className={cx(s.content)}>
                    <h3>{homeSettings && homeSettings.footerTitle1}</h3>
                    <p>
                      {homeSettings && homeSettings.footerContent1}
                    </p>
                  </div>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className={cx(s.space3)}>
                  <div className={cx(s.content, s.floatRight)}>
                    <h3>{homeSettings && homeSettings.footerLinkTitle}</h3>
                    <ul>
                      <li><Link to={'/contact'}><FormattedMessage {...messages.contact} /></Link></li>
                      <li><Link to={'/privacy'}><FormattedMessage {...messages.privacyPolicies} /></Link></li>
                      <li><Link to={'/faq'}><FormattedMessage {...messages.faq} /></Link></li>
                      {
                        getContentPageDetails && getContentPageDetails.map((item, index) => {
                          if (item.isEnable === true) {
                            return (
                              <li key={index}><Link to={`/page/${item.pageUrl}`}>{item.pageTitle}</Link></li>
                            )
                          }
                        })
                      }
                    </ul>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className={cx(s.spaceTop5, s.space6, s.responsiveNoMargin)}>
                  <div className={cx(s.bottomLine)} />
                </Col>
              </Row>
              <div className={s.copyRight}>
                {homeSettings && homeSettings.footerBottom}
              </div>
            </Container>
          </div>}
      </div>
    );
  }
}

const mapState = (state) => ({
  youtubeLink: state.siteSettings.data.youtubeLink,
  twitterLink: state.siteSettings.data.twitterLink,
  facebookLink: state.siteSettings.data.facebookLink,
  instagramLink: state.siteSettings.data.instagramLink,
  siteName: state.siteSettings.data.siteName
})

const mapDispatch = {

}

export default compose(
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(getAllHomePageSettings, {
    name: 'getAllHomePageSettings',
    options: {
      ssr: true
    }
  }),
  graphql(getContentPageDetails, {
    name: 'getContentPageDetails',
    options: {
      fetchPolicy: "network-only",
      ssr: false
    }
  })
)
  (Footer);

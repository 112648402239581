import showToaster from '../../../helpers/showToaster';
import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { setRuntimeVariable } from '../../runtime';
import { loadAccount } from '../../shop/userAccount/userAccount';
import { getAllShopModifier } from '../modifier/getAllShopModifier';
import { getDeviceId } from '../../../core/firebase/firebase';
import { createShopUser as mutation } from '../../lib/graphql';
import {
    SHOP_SIGNUP_ERROR,
    SHOP_SIGNUP_START,
    SHOP_SIGNUP_SUCCESS
} from '../../../constants';

export function shopSignup(values) {
    return async (dispatch, getState, { client }) => {
        let errorMessage;

        try {
            dispatch({
                type: SHOP_SIGNUP_START,
                payload: {
                    restaurantSignupLoading: true
                }
            });
            dispatch(setLoaderStart('RestaurantSignup'));

            const deviceId = await getDeviceId();

            const { data } = await client.mutate({
                mutation,
                variables: {
                    shopName: values?.shopName,
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    email: values?.email,
                    password: values?.password,
                    phoneDialCode: values?.phoneDialCode,
                    phoneNumber: values?.phoneNumber,
                    phoneCountryCode: values?.phoneCountryCode,
                    address: values?.address,
                    lat: values?.lat,
                    lng: values?.lng,
                    city: values?.city,
                    state: values?.state,
                    zipcode: values?.zipcode,
                    country: values?.country,
                    categoryType: values?.categoryType,
                    businessId: values?.businessId,
                    healthMinistryId: values?.healthMinistryId,
                    deviceId
                }
            });

            if (data?.createShopUser?.status == 200) {

                showToaster({ messageId: 'signedUp', toasterType: 'success' })
                dispatch({
                    type: SHOP_SIGNUP_SUCCESS,
                    payload: {
                        restaurantSignupLoading: false
                    }
                });

                dispatch(setRuntimeVariable({
                    name: 'isRestaurantAuthenticated',
                    value: true,
                }));
                dispatch(getAllShopModifier());
                dispatch(setLoaderComplete('RestaurantSignup'));
                dispatch(loadAccount());
                history.push('/restaurant/dashboard');
            } else {

                errorMessage = data?.createShopUser?.errorMessage;
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: errorMessage })
                dispatch({
                    type: SHOP_SIGNUP_ERROR,
                    payload: {
                        restaurantSignupLoading: false,
                        error: errorMessage
                    }
                });
                dispatch(setLoaderComplete('RestaurantSignup'));
            }
        } catch (error) {

            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            dispatch({
                type: SHOP_SIGNUP_ERROR,
                payload: {
                    restaurantSignupLoading: false,
                    error: errorMessage
                }
            });
            dispatch(setLoaderComplete('RestaurantSignup'));
        }
    }
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';

import AddCancelReasonForm from '../../../components/SiteAdmin/AddCancelReasonForm';
import Loader from '../../../components/Common/Loader/Loader';

import messages from '../../../locale/messages';
import getCancelReason from './getCancelReason.graphql'
import s from './EditCancelReason.css';

export class EditCancelReason extends Component {

    render() {
        const { cancelReason: { getCancelReason, loading } } = this.props;

        let initialValues = {};

        if (!loading && getCancelReason?.status == 200) {
            initialValues = {
                id: getCancelReason?.result[0]?.id,
                reason: getCancelReason?.result[0]?.reason,
                userType: getCancelReason?.result[0]?.userType,
                isActive: getCancelReason?.result[0]?.isActive
            }
        }

        return (
            <Loader type={"page"} show={loading}>
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.heading}>
                            <FormattedMessage {...messages.editCancelReason} />
                        </div>
                        <div>
                            {!loading && <AddCancelReasonForm initialValues={initialValues} />}
                        </div>
                    </div>
                </div>
            </Loader>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default compose(
    withStyles(s),
    connect(mapStateToProps, mapDispatchToProps),
    graphql(getCancelReason, {
        name: 'cancelReason',
        options: (props) => ({
            variables: {
                id: props.id
            },
            ssr: false,
            fetchPolicy: 'network-only'
        })
    })
)(EditCancelReason)

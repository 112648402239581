import React from "react";
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { change } from 'redux-form';
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import cx from 'classnames';
import Loader from '../Common/Loader/Loader';
import Dropzone from '../Common/Dropzone';

import updateFavIcon from "../../actions/siteadmin/updateFavIcon";

import TrashIcon from '/public/RestaurantIcon/dlty.png';
import Faq from '/public/Icons/question_1.svg';

import defaultImage from '/public/SiteImages/ImageUpload.svg';


import s from './ImageUploadComponent.css';

class ImageUploadComponent extends React.Component {

    handleDropzone = (fileName, fieldName, formName) => {
        const { change, updateFavIcon } = this.props;
        change(formName, fieldName, fileName)
        if (fieldName === 'favicon') updateFavIcon(fileName);
    };

    renderTooltip = (label) => {
        return (
            <>
                <OverlayTrigger overlay={<Popover className='imageUploadTooltipContainer'>{label}</Popover>}>
                    <img src={Faq} />
                </OverlayTrigger>
            </>
        )
    }

    render() {

        const { imageSrc, label, className, defaultMessage, loader, inputContainer, inputContainerClass, loaderName, isDefaultImg } = this.props;
        const { postUrl, handleDropzone, fieldName, formName, isRemove, handleDelete, subTextClass, subText, toolTip, labelContainerClass } = this.props;

        return (
            <div className="positionRelative">
                {label && <label className={labelContainerClass}>{label} {toolTip && this.renderTooltip(toolTip)}</label>}
                {imageSrc && <Loader show={loader} type={"page"}>
                    <div className={cx(s.backgroundImg, { [s.defaultImageBackSize]: isDefaultImg })} style={{ backgroundImage: `url(${!isDefaultImg ? imageSrc : defaultImage})` }} />
                </Loader>}
                {
                    isRemove && imageSrc &&
                    <Button onClick={handleDelete} className={cx(s.btnTrash, 'btnTrashRtl')}><img src={TrashIcon} alt='Delete' /> </Button>
                }

                <div>
                    <Dropzone
                        className={className}
                        subTextClass={subTextClass}
                        subText={subText}
                        defaultMessage={defaultMessage}
                        componentConfig={{
                            iconFiletypes: ['.jpg', '.png', '.svg'],
                            multiple: false,
                            showFiletypeIcon: false,
                            postUrl
                        }}
                        loaderName={loaderName}
                        fieldName={fieldName}
                        formName={formName}
                        handleDropzone={handleDropzone ? handleDropzone : this.handleDropzone}
                        inputContainer={inputContainer}
                        inputContainerClass={inputContainerClass}
                        isLoader
                    />
                </div>
            </div>
        )
    }
}


const mapState = state => ({});

const mapDispatch = {
    updateFavIcon,
    change
};

export default withStyles(s)(connect(mapState, mapDispatch)(ImageUploadComponent));  
import showToaster from '../../../helpers/showToaster';
import { loadAccount } from './userAccount';
import { shopUserActivityChange as mutation } from '../../lib/graphql';
import {
    SHOP_USER_STATUS_CHANGE_START,
    SHOP_USER_STATUS_CHANGE_SUCCESS,
    SHOP_USER_STATUS_CHANGE_ERROR
} from '../../../constants/index';

export default function shopUserActivityChange(isActive) {
    return async (dispatch, getState, { client }) => {

        dispatch({
            type: SHOP_USER_STATUS_CHANGE_START
        })

        try {

            const { data } = await client.mutate({
                mutation,
                variables: {
                    isActive
                },
            })

            if (data?.shopUserActivityChange?.status === 200) {

                dispatch({
                    type: SHOP_USER_STATUS_CHANGE_SUCCESS
                })
                dispatch(loadAccount())
            } else {

                dispatch({
                    type: SHOP_USER_STATUS_CHANGE_ERROR
                });
                dispatch(loadAccount())
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.shopUserActivityChange?.errorMessage });
            }

        } catch (error) {

            dispatch({
                type: SHOP_USER_STATUS_CHANGE_ERROR
            });
        }
    }
}
import showToaster from '../../../helpers/showToaster';
import { loadAccount } from '../userAccount/userAccount';
import { getAllMenu as query, updateItemStatus as mutation } from '../../lib/graphql';
import {
	SHOP_MENU_UPDATE_START,
	SHOP_MENU_UPDATE_SUCCESS,
	SHOP_MENU_UPDATE_ERROR
} from '../../../constants/index';

export default function updateItemStatus(id, status, currentPage, menuId) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: SHOP_MENU_UPDATE_START,
			payload: {
				updateLoading: true
			}
		});

		try {
			const { data } = await client.mutate({
				mutation,
				variables: {
					id,
					status: status == 'false' ? 0 : 1
				},
				refetchQueries: [{ query, variables: { currentPage, menuId } }]
			});

			if (data?.updateItemStatus?.status === 200) {
				dispatch({
					type: SHOP_MENU_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'itemStatusUpdated', toasterType: 'success' })
				dispatch(loadAccount())
			} else {
				dispatch({
					type: SHOP_MENU_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});

				showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateItemStatus?.errorMessage })
			}
		} catch (err) {
			showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: err })
			dispatch({
				type: SHOP_MENU_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};

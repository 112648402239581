import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import ViewChatting from './ViewChatting';
import messages from '../../../locale/messages';

function action({ store, intl, params }) {

    const title = intl.formatMessage(messages.viewChat);

    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let isSuperAdmin = store.getState().runtime.isSuperAdmin;

    if (!isAdminAuthenticated) return { redirect: '/siteadmin/login' }
    if (!isSuperAdmin) return { redirect: '/siteadmin' }

    let bookingId = Number(params.id), from = params.from;
   
    return {
        title,
        component: (<AdminLayout><ViewChatting bookingId={bookingId} from={from} /></AdminLayout>)
    }
}

export default action;
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Loader from '../../../components/Common/Loader/Loader';
import FoodChoiceList from '../../../components/SiteAdmin/FoodChoiceList/FoodChoiceList';
import { getFoodChoices } from '../../../actions/lib/graphql';
import messages from '../../../locale/messages';
import s from './ManageFoodChoice.css';

export class ManageFoodChoice extends Component {
  static propTypes = {
    getFoodChoices: PropTypes.object
  }
  static defaultProps = {
    loading: true
  }
  render() {
    const { title, getFoodChoices: { loading, getFoodChoices, refetch } } = this.props;

    return (
      <div>
        <Loader type={"page"} show={loading}>
          <div className={s.root}>
            <div className={s.container}
            >
              <div className={s.heading}><FormattedMessage {...messages.manageFoodChoices} /></div>
              <div className={s.paddingRoutesSection}>
                <FoodChoiceList getFoodChoices={getFoodChoices} refetch={refetch} />
              </div>
            </div>
          </div>
        </Loader>
      </div>
    )
  }
}

export default compose(withStyles(s),
  graphql(getFoodChoices, {
    name: 'getFoodChoices',
    options: {
      variables: {
        currentPage: 1,
        searchList: ''
      },
      fetchPolicy: 'network-only'
    }
  }))(ManageFoodChoice)

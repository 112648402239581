import gql from "graphql-tag";

export const userAccount = gql`
query userAccount{
    userAccount {
      id,
      email,
      phoneNumber,
      phoneDialCode,
      phoneCountryCode,
      userStatus,
      isActive,
      isBan,
      userType,
      createdAt,
      activeStatus,
      overallRating,
      deletedAt,
      profile {
        userId,
        firstName,
        lastName,
        preferredLanguage,
        preferredCurrency,
        country,
        city,
        state,
        zipcode,
        profileId,
        lat,
        lng,
        picture,
        shopName,
        description,
        priceRangeId,
        address
      }
      shopCategory {
        categoryId
        categoryName
      }
      
    }
}`;

export const checkUserAvailabilityQuery = gql`query ($id:Int!) {
    checkUserAvailability (id:$id) {
      status        
    }
  }
`;

export const deleteUserMutation = gql`
mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
        status
        errorMessage
    }
}
`;

export const updateDriver = gql`
mutation updateDriver(
    $id: ID,
    $firstName: String,
    $lastName: String,
    $email: String,
    $password: String,
    $phoneDialCode: String
    $phoneNumber: String,
    $vehicleName: String,
    $vehicleNumber: String,
    $vehicleType: Int,
    $userStatus: String,
    $isBan: Int,
    $phoneCountryCode: String
    $organization : Int
    $otherOrganization : String
   
    ) {
    updateDriver(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email:$email
      password: $password
      phoneDialCode: $phoneDialCode
      phoneNumber: $phoneNumber
      vehicleName: $vehicleName
      vehicleNumber: $vehicleNumber
      vehicleType: $vehicleType
      userStatus: $userStatus
      isBan: $isBan
      phoneCountryCode: $phoneCountryCode
      organization:$organization
      otherOrganization:$otherOrganization
   
    )
    {
      status
      errorMessage
    }
  }`;

export const getAllDrivers = gql`
query getAllDrivers($currentPage: Int, $searchList: String){
  getAllDrivers(currentPage: $currentPage, searchList: $searchList){
    count
    userData {
      id
      email
      phoneDialCode
      phoneNumber
      isBan
      userStatus
      createdAt
      profile {
        profileId
        firstName
        lastName
        country
      }
    }   
  }
}`;

export const updateRider = gql`
mutation updateRider(
    $id: ID,
    $firstName: String,
    $lastName: String,
    $email: String,
    $password: String,
    $phoneDialCode: String
    $phoneNumber: String,
      $userStatus: String,
      $isBan: Int
      $phoneCountryCode: String
    ) {
    updateRider(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email:$email
      password: $password
      phoneDialCode: $phoneDialCode
      phoneNumber: $phoneNumber
      userStatus: $userStatus
      isBan: $isBan
      phoneCountryCode: $phoneCountryCode
    )
    {
      status
      errorMessage
    }
  }
`;

export const updateVehicle = gql`
mutation updateVehicle($id: Int, $vehicleName: String, $vehicleType: Int, $vehicleNumber: String, $vehicleStatus: String) {
    updateVehicle(
    id: $id
    vehicleName: $vehicleName,
    vehicleType: $vehicleType
    vehicleNumber: $vehicleNumber,
    vehicleStatus: $vehicleStatus
    ){
    status
    }
}`;

export const getAllVehicles = gql`
query getAllVehicles($currentPage: Int){
    getAllVehicles(currentPage: $currentPage){
    count
    vehicleData{
        id
        vehicleType
        vehicleNumber
        vehicleName 
        vehicleStatus
        category{
            categoryName
        }
        user{
            email
            profile{
            firstName
            }
        }
    }
    }
}
`;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Stripe from './Stripe';
import { Elements } from 'react-stripe-elements';

class PayoutConfirm extends Component {

  render() {
    const { previousPage } = this.props;

    return (
      <Elements>
        <Stripe
          previousPage={previousPage}
        />
      </Elements>
    );

  }
};

const mapState = (state) => ({

});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(PayoutConfirm);
import showToaster from '../../../helpers/showToaster';
import updateShopProfile from '../../../actions/shop/editProfile/updateShopProfile';

async function submit(values, dispatch) {

    let profilePicError;

    values?.picture ? profilePicError = false : profilePicError = true;

    if (profilePicError) {
        return showToaster({ messageId: 'uploadRestaurantImage', toasterType: 'error' })
    } else {
        await dispatch(
            updateShopProfile(values)
        )
    }
}

export default submit;
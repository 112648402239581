import showToaster from '../../../helpers/showToaster';
import { uploadShopDocument as mutation } from '../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import {
    SHOP_DOCUMENT_UPDATE_ERROR,
    SHOP_DOCUMENT_UPDATE_START,
    SHOP_DOCUMENT_UPDATE_SUCCESS
} from '../../../constants';

export default function uploadShopDocument(documentList) {
    return async (dispatch, getState, { client }) => {
        let errorMessage;

        try {
            dispatch({
                type: SHOP_DOCUMENT_UPDATE_START
            });
            dispatch(setLoaderStart('RestaurantDocumentUpdate'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    documentList
                }
            });

            if (data?.uploadShopDocument?.status == 200) {
                showToaster({ messageId: 'documentUpload', toasterType: 'success' })
                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('RestaurantDocumentUpdate'));
            } else {
                errorMessage = data?.uploadShopDocument?.errorMessage
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: errorMessage })
                await dispatch({
                    type: SHOP_DOCUMENT_UPDATE_ERROR
                });
                dispatch(setLoaderComplete('RestaurantDocumentUpdate'));
            }
        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            await dispatch({
                type: SHOP_DOCUMENT_UPDATE_ERROR
            });
            dispatch(setLoaderComplete('RestaurantDocumentUpdate'));
        };
    };
};
import React, { Component } from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Table from 'react-bootstrap/Table';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
// style
import s from './ViewBookingTransactionDetails.css';
import rs from '../../../restaurantCommon.css';
// helpers
import messages from '../../../../locale/messages';
// components
import CurrencyConverter from '../../../CurrencyConverter';

export class ViewTransactionAccordion extends Component {
    static defaultProps = {
        data: []
    }

    constructor(props) {
        super(props);
        this.renderPayoutHistory = this.renderPayoutHistory.bind(this);
    }

    renderPayoutHistory(data, requestType) {
        const { formatMessage } = this.props.intl;
        let tableHeaders = [messages.amount, messages.date, messages.transactionIdLabel];
        tableHeaders = requestType === 'failed' ? [messages.amount, messages.date, messages.reason] : tableHeaders;

        return (
            <Table size="sm">
                <thead>
                    <tr>
                        {
                            tableHeaders.map((header, index) => {
                                return (
                                    <th scope="col" key={index}>{formatMessage(header)}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.length > 0 && data.map((item, key) => {
                            return (
                                <tr>
                                    <td data-label={formatMessage(messages.amount)}><CurrencyConverter from={item.currency} amount={item.amount} /></td>
                                    <td data-label={formatMessage(messages.date)}>{moment(item.createdAt).format('MM-DD-YYYY hh:mm:ss a')}</td>
                                    {
                                        requestType !== 'failed' && <td data-label={formatMessage(messages.transactionIdLabel)}>{item.transactionId ? item.transactionId : '-'}</td>
                                    }
                                    {
                                        requestType === 'failed' && <td data-label={formatMessage(messages.reason)}>{item.reason}</td>
                                    }
                                </tr>
                            )
                        })
                    }
                    {
                        (data && data.length === 0 || !data) && <tr className={'text-center'}>
                            <td colSpan={50}>
                                <FormattedMessage {...messages.noResult} />
                            </td>
                        </tr>
                    }
                </tbody>
            </Table>
        )
    }

    render() {
        const { headerText, data, requestType } = this.props;

        return (
            <div className={cx(rs.space2)}>
                <Collapsible trigger={headerText}>
                    <div className={cx('NewAdminResponsiveTable', rs.spaceTop2)}>
                        {this.renderPayoutHistory(data, requestType)}
                    </div>
                </Collapsible>
            </div>
        );
    }
}

const mapState = state => ({
    locale: state.intl.locale
});
const mapDispatch = {};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(ViewTransactionAccordion)));
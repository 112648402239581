import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import {
    Form,
    Col,
    Card,
    Row,
    FormControl,
    Container
} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

import s from './SignupForm.css';
import rs from '../../restaurantCommon.css';
import messages from '../../../locale/messages';
import validate from './validate';
import submit from './submit';
import CountryList from '../CountryList';
import Loader from '../../Common/Loader';
import PlacesSuggest from '../PlacesSuggest';

export class SignupForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            countryCode: 'US',
            countryDialCode: '+1',
            phoneNumber: ''
        };

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleLocationData = this.handleLocationData.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    static defaultProps = {
        loading: false
    };

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={s.formGroup}>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={cx(s.arrow_box, 'arrow_boxRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    };

    renderFieldPhoneNumber = ({ input, label, writeOnce, type, meta: { touched, error }, fieldClass, placeholder }) => {
        return (
            <Form.Group className={s.formGroup}>
                <Form.Control {...input} readOnly={writeOnce} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={cx(s.arrow_box, 'arrow_boxRTL')}>{error.defaultMessage}</span>}
            </Form.Group>
        )
    };

    renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <FormControl as="select" {...input} className={className} >
                    {children}
                </FormControl>
                {touched && error && <span className={cx(s.arrow_box, 'arrow_boxRTL')}>{formatMessage(error)}</span>}
            </div>
        )
    }

    componentDidMount() {
        const { initialize, getCuisine } = this.props;
        const { countryCode, countryDialCode } = this.state;

        initialize({ 'phoneCountryCode': countryCode, 'phoneDialCode': countryDialCode });
    };


    handleLocationData(location) {
        const { change } = this.props;

        let addressLabel = location && location.label;
        let addressPosition = location && location.location;
        let locationArray = addressLabel && addressLabel.split(',').slice(-3).reverse();

        change('address', addressLabel)
        change('city', locationArray && locationArray[2] && locationArray[2].trim());
        change('state', locationArray && locationArray[1] && locationArray[1].trim());
        change('country', locationArray && locationArray[0] && locationArray[0].trim());
        change('lat', addressPosition && addressPosition.lat);
        change('lng', addressPosition && addressPosition.lng);
    };

    handleCountryChange(country, event) {
        const { change } = this.props;

        this.setState({
            countryCode: country.countryCode,
            countryDialCode: country.dialCode
        });

        change('phoneCountryCode', country.countryCode)
        change('phoneDialCode', country.dialCode)
    };

    handleOnChange(value, event) {
        const { change } = this.props;

        this.setState({ phoneNumber: value });
        let formattedNumber = value.replace(/[^0-9]+/g, '').slice(event.dialCode.length);
        let dialCode = '+' + event.dialCode;
        let countryCode = event.countryCode.toUpperCase();

        change('phoneCountryCode', countryCode)
        change('phoneDialCode', dialCode)
        change('phoneNumber', formattedNumber)

    }

    render() {
        const { countryCode, countryDialCode, phoneNumber } = this.state;
        const { handleSubmit, submitting, getCuisine, loading, error } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className={cx(s.responsiveNoPadding, 'tabLabelText', 'placeHolderText')}>
                <Container fluid>
                    <Row>
                        <Col md={12} lg={12} sm={12} xs={12}>
                            <Card className={s.card}>
                                <h1 className={s.titleText}>
                                    <FormattedMessage {...messages.signUpFormTitle} />
                                </h1>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field
                                                    name="shopName"
                                                    type="text"
                                                    placeholder={formatMessage(messages.shopName)}
                                                    component={this.renderField}
                                                    fieldClass={s.formControlInput}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                        <Form.Group className={s.formGroup}>
                                            <div className={'singUpFormPlaceSuggest'}>
                                                <PlacesSuggest
                                                    form={'signup'}
                                                    label={formatMessage(messages.address)}
                                                    className={s.formGroup}
                                                    handleData={this.handleLocationData}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field
                                                    name="zipcode"
                                                    type="text"
                                                    placeholder={formatMessage(messages.zipcode)}
                                                    component={this.renderField}
                                                    fieldClass={s.formControlInput}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={6} md={12} sm={12} xs={12} className={cx(rs.space1, s.paddingReduseRight, 'paddingReduseRightRTL')}>
                                                <Form.Group className={s.formGroup}>
                                                    <div>
                                                        <Field
                                                            name="firstName"
                                                            type="text"
                                                            placeholder={formatMessage(messages.firstName)}
                                                            component={this.renderField}
                                                            labelClass={s.labelText}
                                                            fieldClass={s.formControlInput}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12} className={cx(rs.space1, s.paddingReduseLeft, 'paddingReduseLeftRTL')}>
                                                <Form.Group className={s.formGroup}>
                                                    <div>
                                                        <Field
                                                            name="lastName"
                                                            type="text"
                                                            placeholder={formatMessage(messages.lastName)}
                                                            component={this.renderField}
                                                            labelClass={s.labelText}
                                                            fieldClass={s.formControlInput}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field
                                                    name="email"
                                                    type="text"
                                                    placeholder={formatMessage(messages.email)}
                                                    component={this.renderField}
                                                    labelClass={s.labelText}
                                                    fieldClass={s.formControlInput}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.space1, 'restaurantActiveSelect', 'countryInputPhone')}>
                                        {
                                            <PhoneInput
                                                country={'us'}
                                                value={phoneNumber}
                                                onChange={this.handleOnChange}
                                                countryCodeEditable={false}
                                            />
                                        }
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                        <Form.Group className={s.formGroup}>
                                            {error && <span className={s.arrow_box}>{error.defaultMessage}</span>}
                                            <Field name="categoryType" className={cx(s.formControlSelect, s.formControlInput)}
                                                component={this.renderFormControlSelect}
                                                placeholder={formatMessage(messages.category)}
                                            >
                                                <option value="">{formatMessage(messages.chooseCuisine)}</option>
                                                {
                                                    getCuisine && getCuisine.result && getCuisine.result.map((item, key) =>
                                                        <option value={item.id} key={key}>{item.categoryName}</option>
                                                    )
                                                }
                                            </Field>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                        <Form.Group className={s.formGroup}>
                                            <Field
                                                name="businessId"
                                                type="text"
                                                placeholder={formatMessage(messages.businessId)}
                                                component={this.renderField}
                                                labelClass={s.labelText}
                                                fieldClass={s.formControlInput}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field
                                                    name="healthMinistryId"
                                                    type="text"
                                                    placeholder={formatMessage(messages.healthMinistryId)}
                                                    component={this.renderField}
                                                    labelClass={s.labelText}
                                                    fieldClass={s.formControlInput}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field
                                                    name="password"
                                                    type="password"
                                                    placeholder={formatMessage(messages.password)}
                                                    component={this.renderField}
                                                    labelClass={s.labelText}
                                                    fieldClass={s.formControlInput}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Field
                                                    name="confirmPassword"
                                                    type="password"
                                                    placeholder={formatMessage(messages.confirmPassword)}
                                                    component={this.renderField}
                                                    labelClass={s.labelText}
                                                    fieldClass={s.formControlInput}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={rs.spaceTop2}>
                                        <div>
                                            <span className={s.singupDescriction}>
                                                <FormattedMessage {...messages.singUpDesc} />
                                            </span>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, rs.spaceTop2)}>
                                        <Form.Group className={s.formGroup}>
                                            <div>
                                                <Loader
                                                    type={"button"}
                                                    label={formatMessage(messages.signup)}
                                                    show={loading}
                                                    buttonType={'submit'}
                                                    className={cx(s.button, s.btnPrimary, s.singUpBtn, 'rtlBtnLoader')}
                                                    disabled={submitting || loading}
                                                    isSuffix={true}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
};

SignupForm = reduxForm({
    form: 'SignupForm',
    onSubmit: submit,
    validate
})(SignupForm);

const mapStateToProps = (state) => ({
    loading: state.loader.RestaurantSignup
});

const mapDispatchToProps = {

};

export default injectIntl(withStyles(s, rs)(connect(mapStateToProps, mapDispatchToProps)(SignupForm)));
import showToaster from '../../../../helpers/showToaster';
import { addUpdateDeliveryVehicle } from '../../../../actions/siteadmin/DeliveryVehicle/addUpdateDeliveryVehicle';

async function submit(values, dispatch) {
    if (!values.vehicleImage) {
        showToaster({ messageId: 'vehicleImageRequired', toasterType: 'error' })
        return;
    }

    await dispatch(
        addUpdateDeliveryVehicle(
            values.id, 
            values.vehicleName,
            values.vehicleImage,
            values.description,
            values.isActive
        )
    );
}

export default submit;
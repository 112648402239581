import {
    OPEN_LOGIN_MODAL,
    CLOSE_LOGIN_MODAL,
    OPEN_FORGOT_PASSWORD_MODAL,
    CLOSE_FORGOT_PASSWORD_MODAL,
    OPEN_ORDER_MODAL,
    CLOSE_ORDER_MODAL,
    OPEN_FOOD_CHOICE_MODAL,
    CLOSE_FOOD_CHOICE_MODAL,
    OPEN_ORGANIZATION_MODAL,
    CLOSE_ORGANIZATION_MODAL
} from '../constants';
import { initialize } from 'redux-form';

export function openLoginModal() {

    return (dispatch, getState) => {

        dispatch({
            type: OPEN_LOGIN_MODAL,
            isLoginModalOpen: true,
            isForgotPasswordModalOpen: false
        });
    }
}

export function closeLoginModal() {

    return (dispatch, getState) => {

        dispatch({
            type: CLOSE_LOGIN_MODAL,
            isLoginModalOpen: false
        });
    }
}

export function openForgotPasswordModal() {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_FORGOT_PASSWORD_MODAL,
            isForgotPasswordModalOpen: true,
            isLoginModalOpen: false
        });
    }
}

export function closeForgotPasswordModal() {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_FORGOT_PASSWORD_MODAL,
            isForgotPasswordModalOpen: false
        });
    }
}

export function openOrderModal() {

    return (dispatch, getState) => {

        dispatch({
            type: OPEN_ORDER_MODAL,
            isOrderModalOpen: true,
        });
    }
}

export function closeOrderModal() {

    return (dispatch, getState) => {

        dispatch({
            type: CLOSE_ORDER_MODAL,
            isOrderModalOpen: false
        });
    }
}

export function openFoodChoiceModal(values) {

    return (dispatch, getState) => {
        dispatch(initialize('FoodChoiceForm', values));
        dispatch({
            type: OPEN_FOOD_CHOICE_MODAL,
            isFoodChoiceModalOpen: true,
        });
    }
}

export function closeFoodChoiceModal() {

    return (dispatch, getState) => {

        dispatch({
            type: CLOSE_FOOD_CHOICE_MODAL,
            isFoodChoiceModalOpen: false
        });
    }
}

export function openOrganizationModal(values) {

    return (dispatch, getState) => {
        dispatch(initialize('OrganizationForm', values));
        dispatch({
            type: OPEN_ORGANIZATION_MODAL,
            isOrganizationModalOpen: true,
        });
    }
}

export function closeOrganizationModal() {

    return (dispatch, getState) => {

        dispatch({
            type: CLOSE_ORGANIZATION_MODAL,
            isOrganizationModalOpen: false
        });
    }
}
import React, { Component } from 'react';

import {
  Field,
  reduxForm,
  getFormValues,
  change,
  formValueSelector,
} from 'redux-form';
import cx from 'classnames';
import {
  Form,
  Col,
  Row,
  InputGroup,
  Container,
  FormControl,
  Accordion,
  Card,
  Button,
  Badge,
} from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash'


import Loader from '../../../../Common/Loader';
import ConfirmationPopup from '../../../../Common/ConfirmationPopup';
import AddModifierModal from '../AddModifierModal/AddModifierModal';
import ModifierCommonType from '../ModifierCommonType/ModifierCommonType';
import ImageUploadComponent from '../../../../ImageUploadComponent';
import DatePicker from '../../../../Common/DatePicker';
import { getAllFoodChoice } from '../../../../../actions/lib/graphql';

import validate from './validate';
import submit from './submit';
import messages from '../../../../../locale/messages';
import getExistingModifier from '../../../../../actions/shop/modifier/getExistingModifier';
import getBookedModifierGroup from '../../../../../actions/shop/modifier/getBookedModifierGroup';
import history from '../../../../../history';
import deleteMenu from '../../../../../actions/shop/menu/deleteMenu';
import {
  openModal,
  closeModal,
  openItemModal,
  editItemModal,
} from '../../../../../actions/siteadmin/modalActions';
import { api, itemImageUploadDir } from '../../../../../config';
import { getCurrencySymbol } from '../../../../../helpers/currencyConvertion';
import { convert } from '../../../../../helpers/currencyConvertion';
import {
  setLoaderStart,
  setLoaderComplete,
} from '../../../../../actions/loader/loader';

import EditIcon from '../../../../../../public/RestaurantIcon/edit.png';
import TrashIcon from '../../../../../../public/RestaurantIcon/dlty.png';
import downArrow from '../../../../../../public/RestaurantIcon/Dropdownwithcircle.svg';
import upArrow from '../../../../../../public/RestaurantIcon/Uparrowwithcircle.svg';
import DefaultImage from './defaultImage.svg';

import s from './AddItemForm.css';
import rs from '../../../../../components/restaurantCommon.css';

class AddItemForm extends React.Component {
  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapseID: [],
      modifierList: [],
    };
  }

  componentDidMount() {
    const { modifiers } = this.props;
    if (modifiers) {
      this.setState({ modifierList: modifiers });
    }
  }

  componentWillMount() {
    const { base, rates, price, profile, change } = this.props;
    if (price) {
      let convertedAmount = convert(
        base,
        rates,
        price,
        profile?.preferredCurrency,
        profile?.preferredCurrency,
      );
      convertedAmount = convertedAmount?.toString().includes('.')
        ? convertedAmount?.toFixed(2)
        : convertedAmount;
      change('price', convertedAmount);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { modifiers } = nextProps;
    if (modifiers) {
      this.setState({ modifierList: modifiers });
    }
  }

  handleDelete = async id => {
    const { change, closeModal } = this.props;
    const { modifierList } = this.state;
    let modifierData = modifierList;
    modifierData?.splice(id, 1);
    await closeModal('menuDeleteModal');
    change(`modifiers`, modifierData);
  };

  handleStatus = async (index1, index2, status) => {
    const { change } = this.props;
    await change(
      `modifiers[${index1}].modifierGroups[${index2}].isActive`,
      status,
    );
  };

  toggleCollapse = id => {
    const { collapseID } = this.state;
    const index = collapseID?.indexOf(id);
    if (index > -1) {
      collapseID?.splice(index, 1);
      this.setState({ collapseID });
    } else {
      collapseID?.push(id);
      this.setState({ collapseID });
    }
  };

  handleModifierItem = async (index, modifierGroupId) => {
    const { getBookedModifierGroup, openModal } = this.props;
    let { data } = await getBookedModifierGroup(modifierGroupId);
    if (data.length > 0) {
      openModal('menuDeleteModal', { id: index, checkBookedModifier: true });
    } else {
      openModal('menuDeleteModal', { id: index, checkBookedModifier: false });
    }
  };

  renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    labelClass,
    fieldClass,
    placeholder,
    maxlength,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group>
        <div>
          <label className={cx(rs.restaurantLabelText, rs.space1)}>
            {label}
          </label>
        </div>
        <Form.Control
          {...input}
          placeholder={placeholder}
          type={type}
          className={fieldClass}
          maxLength={maxlength}
        />
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </Form.Group>
    );
  };

  renderTextAreaField = ({
    input,
    label,
    type,
    meta: { touched, error },
    children,
    labelClass,
    fieldClass,
    placeholder,
    maxLength,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group>
        <div>
          <label className={cx(rs.restaurantLabelText, rs.space1)}>
            {label}
          </label>
        </div>
        <Form.Control
          as="textarea"
          rows="6"
          {...input}
          placeholder={placeholder}
          type={type}
          className={fieldClass}
          maxLength={maxLength}
        />
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </Form.Group>
    );
  };

  renderPrice = ({
    input,
    label,
    type,
    meta: { touched, error },
    labelClass,
    fieldClass,
    placeholder,
    prefix,
    prefixLabel,
  }) => {
    const { profile, currentLocale } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={'retaurantInputFormAddon'}>
        <Form.Group>
          <div>
            {prefix && (
              <label className={cx(rs.restaurantLabelText, rs.space1)}>
                {label}
              </label>
            )}
            {!prefix && (
              <label className={cx(rs.restaurantLabelText, rs.space1)}>
                {label}
              </label>
            )}
          </div>
          <InputGroup>
            {prefix && (
              <InputGroup.Append>
                <InputGroup.Text>
                  {getCurrencySymbol(profile?.preferredCurrency, currentLocale)}
                </InputGroup.Text>
              </InputGroup.Append>
            )}
            <Form.Control
              {...input}
              placeholder={placeholder}
              type={type}
              className={fieldClass}
            />
            {!prefix && (
              <InputGroup.Append>
                <InputGroup.Text>{prefixLabel}</InputGroup.Text>
              </InputGroup.Append>
            )}
          </InputGroup>
          {touched && error && (
            <span className={s.errorMessage}>{formatMessage(error)}</span>
          )}
        </Form.Group>
      </div>
    );
  };

  renderSelectField = ({
    input,
    label,
    type,
    meta: { touched, error },
    labelClass,
    fieldClass,
    placeholder,
    children,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group className="restauurant">
        <div>
          <label className={cx(rs.restaurantLabelText, rs.space1)}>
            {label}
          </label>
        </div>
        <Form.Control
          as="select"
          {...input}
          placeholder={placeholder}
          className={fieldClass}
        >
          {children}
        </Form.Control>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </Form.Group>
    );
  };

  renderDatePicker = ({ input, label, type, meta: { touched, error } }) => {
    const { formatMessage } = this.props.intl;
    const { touch, itemAvailableDate } = this.props;
    return (
      <Form.Group>
        <div>
          <label className={cx(rs.restaurantLabelText, rs.space1)}>
            {label}
          </label>
        </div>
        <div className='restaurantAddMenuDatePicker'>
          <DatePicker
            placeholder={label}
            formName={'AddItemForm'}
            fieldName={input?.name}
            initialDate={itemAvailableDate}
            handleDateChange={({ fieldName }) => touch(fieldName)}
          />
        </div>

        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </Form.Group>
    )
  }

  handleCancel = () => {
    const { menuId } = this.props;
    history.push('/restaurant/menu/' + menuId + '/item');
  };

  loaderStart = () => {
    const { setLoaderStart } = this.props;
    setLoaderStart('profileImageLoader');
  };
  loaderEnd = () => {
    const { setLoaderComplete } = this.props;
    setLoaderComplete('profileImageLoader');
  };

  onSelectFoodChoice = (selectedList) => {
    const { change } = this.props;
    change('foodChoices', selectedList)
  };

  onRemoveFoodChoice = (selectedList) => {
    const { change } = this.props;
    change('foodChoices', selectedList)
  }

  renderFoodChoices = ({ label, availableFoodChoice, meta: { touched, error } }) => {
    const { foodChoices, touch } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group onFocus={() => { touch('foodChoices', true) }}>
        <div>
          <label className={cx(rs.restaurantLabelText, rs.space1)}>
            {label}
          </label>
        </div>
        <div className='foodChoicesMultiSelect'>
          <Multiselect
            options={availableFoodChoice}
            selectedValues={foodChoices}
            onSelect={this.onSelectFoodChoice}
            onRemove={this.onRemoveFoodChoice}
            placeholder={formatMessage(messages.selectFoodChoice)}
            displayValue='itemName'
            showArrow
            customArrow={true}
          />
        </div>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </Form.Group>
    )
  }

  render() {
    const {
      error,
      handleSubmit,
      submitting,
      dispatch,
      loading,
      id,
      image,
      menuId,
      subMenuId,
      imageLoader,
      getAllFoodChoice: { loading: foodChoiceLoading, getAllFoodChoice }
    } = this.props;
    const { formatMessage } = this.props.intl;
    const {
      openModal,
      closeModal,
      menuDeleteModal,
      openItemModal,
      getExistingModifier,
      modifierGroupId,
      editItemModal,
      modalData,
      foodChoices
    } = this.props;
    const { collapseID, modifierList } = this.state;

    let availableFoodChoice = getAllFoodChoice?.results?.filter((item) => item?.isEnable)
    let availabilityItems = [
      { value: 'notRepeat', label: formatMessage(messages?.doesNotRepeat) },
      { value: 'daily', label: formatMessage(messages?.daily) },
      { value: 'weekly', label: formatMessage(messages?.weeklyLabel) }
    ]

    return (
      <div>
        <AddModifierModal from={'item'} />
        <ConfirmationPopup
          modalStatus={menuDeleteModal}
          checkBookedModifier={modalData?.checkBookedModifier}
          title={formatMessage(messages.deleteModifier)}
          body={formatMessage(messages.deleteConfirmation)}
          bodyModifier={formatMessage(messages.deleteConfirmationModifier)}
          closeModal={() => closeModal('menuDeleteModal')}
          popupButtonLeftName={formatMessage(messages.cancelButton)}
          popupButtonRightName={formatMessage(messages.deleteAction)}
          popupButtonLeftFunction={() => closeModal('menuDeleteModal')}
          popupButtonRightFunction={() => this.handleDelete(modalData.id)}
        />
        {foodChoiceLoading ? <Loader show={foodChoiceLoading} type={"page"} /> : <div class="mainContainer">
          <div className={cx(s.space5, s.responsiveNoPadding)}>
            <Container fluid>
              <h1 className={rs.restaurantTitleText}>
                <FormattedMessage {...messages.itemSettings} />
              </h1>
              <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12} className={rs.spaceTop1}>
                    <Form.Group className={s.formGroup}>
                      <div>
                        <Row>
                          <Col
                            lg={6}
                            md={12}
                            sm={12}
                            xs={12}
                            className="textAreaField"
                          >
                            <Field
                              name="itemName"
                              type="text"
                              placeholder={formatMessage(messages.foodName)}
                              component={this.renderField}
                              labelClass={s.labelText}
                              fieldClass={rs.formControlInputRestaurant}
                              label={formatMessage(messages.foodName)}
                              maxlength={100}
                            />
                            <Field
                              name="itemDescription"
                              type="text"
                              placeholder={formatMessage(
                                messages.foodDescription,
                              )}
                              component={this.renderTextAreaField}
                              labelClass={s.labelText}
                              fieldClass={rs.formControlInputRestaurant}
                              label={formatMessage(messages.foodDescription)}
                              maxLength={250}
                            />
                          </Col>
                          <Col lg={6} md={12} sm={12} xs={12}>
                            <Form.Group className={s.formGroup}>
                              <label>{formatMessage(messages.foodImage)}</label>
                              <br />
                              <div
                                className={cx(
                                  rs.profileImgSection,
                                  rs.profileImgWidth,
                                  s.displayGrid,
                                )}
                              >
                                <Loader show={imageLoader} type={'page'}>
                                  <div
                                    className={cx(
                                      rs.backgroundImg,
                                      s.backgroundTop,
                                    )}
                                    style={{
                                      backgroundImage: `url(${image ? api.apiEndpoint +
                                        itemImageUploadDir +
                                        image : DefaultImage})`,
                                    }}
                                  />
                                </Loader>
                                <div className={'retaurantDroupZone'}>
                                  <ImageUploadComponent
                                    className={cx(rs.profileButton)}
                                    subTextClass={s.subText}
                                    subText={formatMessage(
                                      messages.maximumUploadSizeLabel,
                                    )}
                                    defaultMessage={formatMessage(
                                      messages.chooseFile,
                                    )}
                                    inputContainer={
                                      '.dzInputContainerRestaurantImage'
                                    }
                                    inputContainerClass={
                                      'dzInputContainerRestaurantImage'
                                    }
                                    postUrl={api.apiEndpoint + '/uploadItemImage'}
                                    fieldName={'image'}
                                    loaderName={'itemImageLoader'}
                                    loader={imageLoader}
                                    formName={'AddItemForm'}
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={12} sm={12} xs={12}>
                            <Field
                              name="price"
                              type="text"
                              placeholder={formatMessage(messages.price)}
                              component={this.renderPrice}
                              labelClass={s.labelText}
                              fieldClass={rs.formControlInputRestaurant}
                              prefix={true}
                              label={formatMessage(messages.price)}
                            />
                          </Col>
                          <Col lg={6} md={12} sm={12} xs={12}>
                            <Field
                              name="tax"
                              type="text"
                              placeholder={formatMessage(messages.tax)}
                              component={this.renderPrice}
                              labelClass={s.labelText}
                              fieldClass={rs.formControlInputRestaurant}
                              prefixLabel={formatMessage(messages.percent)}
                              prefix={false}
                              label={formatMessage(messages.tax)}
                            />
                          </Col>
                          <Col lg={6} md={12} sm={12} xs={12}>
                            <Field
                              name="itemType"
                              component={this.renderSelectField}
                              fieldClass={rs.formControlInputRestaurant}
                              label={formatMessage(messages.itemType)}
                            >
                              <option value="">
                                {formatMessage(messages.selectType)}
                              </option>
                              <option value={1}>
                                {formatMessage(messages.itemType1)}
                              </option>
                              <option value={2}>
                                {formatMessage(messages.itemType2)}
                              </option>
                            </Field>
                          </Col>
                          <Col lg={6} md={12} sm={12} xs={12}>
                            <Field
                              name="isEnable"
                              component={this.renderSelectField}
                              label={formatMessage(messages.status)}
                              fieldClass={rs.formControlInputRestaurant}
                            >
                              <option value={true}>
                                {formatMessage(messages.active)}
                              </option>
                              <option value={false}>
                                {formatMessage(messages.inactive)}
                              </option>
                            </Field>
                          </Col>
                          <Col lg={6} md={12} sm={12} xs={12}>
                            <Field
                              name="itemQuantity"
                              type="text"
                              placeholder={formatMessage(messages.quantity)}
                              component={this.renderField}
                              fieldClass={rs.formControlInputRestaurant}
                              label={formatMessage(messages.quantity)}
                              maxlength={9}
                            />
                          </Col>
                          <Col lg={6} md={12} sm={12} xs={12}>
                            <Field
                              name="itemAvailableDate"
                              type="text"
                              component={this.renderDatePicker}
                              label={formatMessage(messages.date)}
                            />
                          </Col>
                          <Col lg={6} md={12} sm={12} xs={12}>
                            <Field
                              name="foodChoices"
                              component={this.renderFoodChoices}
                              availableFoodChoice={availableFoodChoice}
                              fieldClass={rs.formControlInputRestaurant}
                              label={formatMessage(messages.foodChoice)}
                              className={s.commonMultiSelect}
                            />
                          </Col>
                          <Col lg={6} md={12} sm={12} xs={12}>
                            <Field
                              name="itemAvailability"
                              component={this.renderSelectField}
                              fieldClass={rs.formControlInputRestaurant}
                              label={formatMessage(messages.availabilty)}
                            >
                              {availabilityItems.map((item) => {
                                return (<option value={item.value}>
                                  {item.label}
                                </option>)
                              })}

                            </Field>
                          </Col>
                        </Row>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className={s.borderCss}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <h1 className={cx(rs.restaurantTitleText, s.afterNone)}>
                      {formatMessage(messages.addModifierGroup)}
                    </h1>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className={cx(rs.searchInputRestaurant)}>
                      <ModifierCommonType />
                    </div>
                    <div
                      className={cx(
                        s.displayInlineBlock,
                        rs.searchTopBtn,
                        s.searchTopBtnMob,
                        'textAlignLeftRTL',
                      )}
                    >
                      <Button
                        className={cx(
                          rs.button,
                          rs.btnPrimary,
                          s.displayInlineBlock,
                        )}
                        onClick={() => openItemModal(menuId, subMenuId)}
                      >
                        <FormattedMessage {...messages.newModifierGroups} />
                      </Button>
                    </div>
                    {modifierList?.map((data, index1) => {
                      return data?.modifierGroups?.map((item, index2) => {
                        return (
                          <div className="modifierAcccordion">
                            <Accordion defaultActiveKey="0">
                              <Card>
                                <Card.Header>
                                  <div className={s.menuItemGrid}>
                                    <div
                                      className={cx(
                                        s.menuFirstSection,
                                        'menuFirstSectionRTL',
                                      )}
                                    >
                                      <div
                                        className={cx(
                                          rs.alignLeft,
                                          rs.tabSection,
                                          s.paddingLeft,
                                          'textAlignRightRTL',
                                        )}
                                      >
                                        <p
                                          className={cx(
                                            rs.idTitle,
                                            rs.textOverFlow,
                                            s.idTitleText,
                                          )}
                                        >
                                          {item?.modifierName}
                                        </p>
                                        {item?.modifierCommonId && (
                                          <Badge
                                            className={cx(s.badge, 'badgeRTL')}
                                          >
                                            {formatMessage(messages.common)}
                                          </Badge>
                                        )}
                                        {!item?.modifierCommonId && (
                                          <Badge
                                            className={cx(s.badge, 'badgeRTL')}
                                          >
                                            {formatMessage(messages.individual)}
                                          </Badge>
                                        )}
                                      </div>
                                      <div
                                        className={cx(
                                          'restaurantActiveSelect',
                                          s.inactiveColor,
                                        )}
                                      >
                                        {!item?.modifierCommonId && (
                                          <select
                                            value={item.isActive}
                                            onChange={e => {
                                              this.handleStatus(
                                                index1,
                                                index2,
                                                e.target.value,
                                              );
                                            }}
                                            className={cx(
                                              rs.activeSelect,
                                              rs.restaurantSelectInput,
                                            )}
                                          >
                                            <option value={'true'}>
                                              {formatMessage(messages.active)}
                                            </option>
                                            <option value={'false'}>
                                              {formatMessage(messages.inactive)}
                                            </option>
                                          </select>
                                        )}
                                        {item?.modifierCommonId &&
                                          item?.isActive === 'false' &&
                                          formatMessage(messages.inactive)}
                                      </div>
                                      <div>
                                        <Button
                                          className={cx(
                                            rs.linkColor,
                                            s.displayInlineBlock,
                                          )}
                                          onClick={() =>
                                            editItemModal(
                                              'ModifierGroupForm',
                                              index1,
                                              index2,
                                            )
                                          }
                                        >
                                          <img
                                            src={EditIcon}
                                            className={cx(
                                              s.iconsWidth,
                                              'iconsWidthRTL',
                                            )}
                                          />
                                          <span
                                            className={cx(
                                              s.vtrMiddle,
                                              s.editSection,
                                            )}
                                          >
                                            {formatMessage(messages.editItem)}
                                          </span>
                                        </Button>
                                      </div>
                                      <div>
                                        <Button
                                          className={cx(
                                            rs.deleteBtn,
                                            s.editBtn,
                                            s.IconLeft,
                                            rs.textOverFlow,
                                          )}
                                          onClick={() =>
                                            this.handleModifierItem(
                                              index1,
                                              item.id,
                                            )
                                          }
                                        >
                                          <img
                                            src={TrashIcon}
                                            className={cx(
                                              s.iconsWidth,
                                              'trashIconRTL',
                                              'iconsWidthRTL',
                                            )}
                                          />
                                          <span className={s.vtrMiddle}>
                                            {formatMessage(messages.deleteAction)}
                                          </span>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  <Accordion.Toggle
                                    variant="link"
                                    eventKey="1"
                                    className={'arrowBtn'}
                                    onClick={() => this.toggleCollapse(item.id)}
                                  >
                                    <img
                                      src={
                                        collapseID.includes(item.id)
                                          ? upArrow
                                          : downArrow
                                      }
                                      width={'30px'}
                                      height={'30px'}
                                    />
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                  <Card.Body>
                                    {item?.modifierItems?.length > 0 && (
                                      <span>
                                        {formatMessage(messages.item)}:{' '}
                                      </span>
                                    )}
                                    {item?.modifierItems
                                      ?.map(function (elem) {
                                        return elem?.modifierItemName;
                                      })
                                      .join(', ')}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          </div>
                        );
                      });
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={cx(rs.alignRightText, 'textAlignLeftRTL')}
                  >
                    <Form.Group className={cx(s.padding2, s.rightSide)}>
                      <div className={rs.secondryBtnSection}>
                        <a
                          href={'javascript:void(0)'}
                          onClick={this.handleCancel}
                          className={cx(
                            rs.button,
                            rs.btnPrimaryBorder,
                            rs.displayBlock,
                          )}
                        >
                          <FormattedMessage {...messages.cancel} />
                        </a>
                      </div>
                      <div className={rs.secondryBtnSection}>
                        <Loader
                          type={'button'}
                          label={
                            id
                              ? formatMessage(messages.update)
                              : formatMessage(messages.save)
                          }
                          show={loading}
                          buttonType={'submit'}
                          className={cx(
                            rs.button,
                            rs.btnPrimary,
                            rs.btnFullWidth,
                            'rtlBtnLoader',
                          )}
                          disabled={submitting || loading}
                          isSuffix={true}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
        </div>}
      </div>
    );
  }
}

AddItemForm = reduxForm({
  form: 'AddItemForm',
  onSubmit: submit,
  validate
})(AddItemForm);

const selector = formValueSelector('AddItemForm');

const mapState = state => ({
  loading: state?.loader?.CommonSettings,
  image: selector(state, 'image'),
  id: selector(state, 'id'),
  price: selector(state, 'price'),
  menuId: selector(state, 'menuId'),
  subMenuId: selector(state, 'subMenuId'),
  modifierGroupId: selector(state, 'modifierGroupId'),
  modifiers: selector(state, 'modifiers'),
  itemAvailableDate: selector(state, 'itemAvailableDate'),
  foodChoices: selector(state, 'foodChoices'),
  profile: state?.account?.data?.profile,
  currentLocale: state?.intl?.locale,
  base: state?.currency?.base,
  rates: state?.currency?.rates,
  menuDeleteModal: state?.modalStatus?.menuDeleteModal,
  modalData: state?.modalStatus?.data && state?.modalStatus?.data[0],
  imageLoader: state?.loader?.itemImageLoader,
});

const mapDispatch = {
  change,
  deleteMenu,
  openModal,
  closeModal,
  openItemModal,
  getExistingModifier,
  editItemModal,
  getBookedModifierGroup,
  setLoaderStart,
  setLoaderComplete,
};

export default injectIntl(compose(withStyles(s, rs), connect(mapState, mapDispatch), graphql(getAllFoodChoice, {
  name: 'getAllFoodChoice',
  options: {
    fetchPolicy: "network-only",
    ssr: false
  }
}))(AddItemForm));

import showToaster from '../../../helpers/showToaster';
import uploadShopDocument from '../../../actions/shop/documentUpload/uploadShopDocument'

async function submit(values, dispatch) {

    let documentError = false;

    values?.documentList?.map((item) => {
        item?.fileName ? documentError = false : documentError = true
    })

    let documentList = values?.documentList.length > 0
        ?
        JSON.stringify(values?.documentList) : JSON.stringify([]);

    if (documentError) {
        return showToaster({ messageId: 'uploadDocument', toasterType: 'error' })
    } else {
        await dispatch(
            uploadShopDocument(documentList)
        )
    }

}

export default submit;
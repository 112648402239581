import messages from '../../../../../locale/messages';

const validate = (values) => {
    const error = {};

    if (!values.itemName) {
        error.itemName = messages.required;
    } else if (values.itemName && values.itemName.toString().trim() === '') {
        error.itemName = messages.required;
    }

    if (!values.price) {
        error.price = messages.required;
    } else if (values.price && values.price.toString().trim() === '') {
        error.price = messages.required;
    } else if (values.price && Number(values.price) <= 0) {
        error.price = messages.required;
    } else if (values.price && (isNaN(values.price))) {
        error.price = messages.invalid;
    } else if (values.price) {
        let price = values.price.toString();
        if (!price.includes('.') && price.length > 6) {
            error.price = messages.priceLimitReached;
        }
    }

    if (values && values.tax) {
        if ((isNaN(values.tax) || (parseInt(values.tax, 10) > 99) || values.tax.toString().includes(' '))) {
            error.tax = messages.invalid;
        }
    }

    if (!values.itemType) {
        error.itemType = messages.required;
    }

    if (!values.image) {
        error.image = messages.required;
    }

    if (!values.itemQuantity || values?.itemQuantity.toString().trim() === '') {
        error.itemQuantity = messages.required;
    } else if (values.itemQuantity && (isNaN(values.itemQuantity) || !/^[0-9]\d*$/.test(values.itemQuantity)) || values.itemQuantity <= 0){
        error.itemQuantity = messages.invalid;
    }

    if (!values.itemAvailableDate || values.itemAvailableDate.toString().trim() === '') {
        error.itemAvailableDate = messages.required;
    }

    if (!values?.foodChoices || values?.foodChoices?.length <= 0) {
        error.foodChoices = messages.required;
    }

    return error;
}

export default validate;
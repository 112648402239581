import fetch from 'node-fetch';
import showToaster from '../../../../helpers/showToaster';
import history from '../../../../history';
import { updateContentPageDetails as mutation } from '../../../../actions/lib/graphql';

async function submit(values, dispatch) {
  if (values?.content == null || values?.content == '<p><br></p>' || values?.content == '<p> </p>') {
    showToaster({ messageId: 'addContent', toasterType: 'error' })
  } else {

    const response = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: mutation,
        variables: values
      }),
      credentials: 'include'
    });

    const { data } = await response.json();

    if (data?.updateContentPageDetails?.status == 200) {
      showToaster({ messageId: 'updatePage', toasterType: 'success' });
      history.push('/siteadmin/contentpage/manage');
    } else if (data?.updateContentPageDetails?.status == 400) {
      showToaster({ messageId: 'addedFailed', toasterType: 'error' });
    } else {
      showToaster({ messageId: 'updatePageFailed', toasterType: 'error' });
    }
  }
};

export default submit;
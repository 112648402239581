export const adminRoutes = [
    {
        path: '/siteadmin/login',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/login'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/dashboard'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/users',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/users'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/drivers',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/drivers'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/cuisine',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/category'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/bookings',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/booking'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/cuisine/add',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/addCategory'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/bookings/view/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/viewBooking'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/users/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/editUser'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/drivers/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/editDriver'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/cuisine/edit/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/editCategory'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/promo-code/list',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/promoCode/promoCodeList'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/promo-code/add',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/promoCode/addPromoCode'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/promo-code/edit/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/promoCode/editPromoCode'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/completed-bookings',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/completedBooking'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/failed-bookings',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/failedBooking'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/currency',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/currency'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/settings/site',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/siteSettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/change/admin',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/changeAdmin'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/notifications',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/manageNotifications'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/cancel-reasons',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/manageCancelReasons'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/reviews',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/reviews'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/cancel-reasons/add',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/addCancelReason'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/cancel-reasons/edit/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/editCancelReason'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/homepage-banner',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/homeSettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/homepage-howitworks',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/aboutSettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/homepage-cuisine',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/citySettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/homepage-eater',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/safetySettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/homepage-footersettings',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/footerSettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/staticpage/manage',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/staticPage'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/staticpage/edit/:pageId',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/editStaticPage'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/vehicle-type/list',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/deliveryVehicle/deliveryVehicle'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/vehicle-type/add',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/deliveryVehicle/addDeliveryVehicle'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/vehicle-type/edit/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/deliveryVehicle/editDeliveryVehicle'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/common-settings/:typeId',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/commonSettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/contentpage/manage',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/contentPage'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/contentpage/add',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/contentPage/addContentPage'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/contentpage/edit/:pageId',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/contentPage/editContentPage'));
            reject(error);
        }),
    },
    {
        path: '/page/:pageUrl',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./contentPagePreview'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/restaurant/list',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/manageShops/restaurantsList'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/restaurant/edit/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/manageShops/editRestaurant'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/restaurant-home/banner',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/shopHomepageSettings/bannerSettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/restaurant-home/info',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/shopHomepageSettings/infoSettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/restaurant-home/slider',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/shopHomepageSettings/sliderSettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/restaurant/document/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/manageShops/documentVerification'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/manage-service-fee',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/serviceFee'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/admin-earnings',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/adminEarnings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/admin-earnings/view/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/viewEarning'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/completed-bookings/view/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/viewCompletedBooking'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/failed-bookings/view/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/viewFailedBooking'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/reviews/view/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/viewReview'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/transaction-history/:pageType/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/viewBookingTransactionHistory'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/admin-roles',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/adminRoles'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/admin-users',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/adminUser'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/restaurant-home/contact',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/shopHomepageSettings/contactSettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/settings/config',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/configSettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/homepage-driverapp',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/driverSettings'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/payment-gateway',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/paymentGateway'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/paymentGateway'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/:from/message/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/viewChatting'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/food-choices',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/foodChoice'));
            reject(error);
        }),
    },
    {
        path: '/siteadmin/organizations',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./site-admin/organizations'));
            reject(error);
        }),
    },
];
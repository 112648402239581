import messages from '../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.itemName || values?.itemName?.toString().trim() == "") {
        errors.itemName = messages.required;
    } else if (values?.itemName?.length > 250) {
        errors.itemName = messages.textAreaError
    }

    return errors;
};

export default validate;
import { reset } from 'redux-form';
import fetch from 'node-fetch';
import showToaster from '../../../helpers/showToaster';

async function submit(values, dispatch) {
    const query = `
    mutation (
        $currentPassword: String,
        $newPassword: String,
        $confirmNewPassword: String    
    ) {
        changePassword (
            currentPassword: $currentPassword,
            newPassword: $newPassword,
            confirmNewPassword: $confirmNewPassword
        ){
            status
            errorMessage
        }
    }`;


    const response = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: query,
            variables: {
                currentPassword: values?.currentPassword,
                newPassword: values?.newPassword,
                confirmNewPassword: values?.confirmNewPassword
            }
        }),
        credentials: 'include',
    });

    const { data } = await response.json();

    if (data?.changePassword?.status === 200) {
        showToaster({ messageId: 'passwordUpdated', toasterType: 'success' })
        dispatch(reset('ChangePassword'));
    } else {
        showToaster({ messageId: 'passwordUpdateFailed', toasterType: 'error', requestContent: data?.changePassword?.errorMessage })
    }
}

export default submit;
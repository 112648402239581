import { initialize } from 'redux-form';
import {
    SHOP_OPERATION_HOURS_START,
    SHOP_OPERATION_HOURS_SUCCESS,
    SHOP_OPERATION_HOURS_ERROR
} from '../../constants';

function setOperationHours(data) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: SHOP_OPERATION_HOURS_START
            });

            dispatch({
                type: SHOP_OPERATION_HOURS_SUCCESS,
                payload: {
                    operationHoursDataLoad: data
                }
            });
            let operationHoursData = Object.assign({}, { OperationHours: data })
            dispatch(initialize('OperationHours', operationHoursData));

        } catch (error) {
            dispatch({
                type: SHOP_OPERATION_HOURS_ERROR
            });
        }
    }
}

export default setOperationHours;
import showToaster from '../../../helpers/showToaster';
import socketNotification from '../../../core/socket/socketNotification';
import { updateShopStatusMutation as mutation } from '../../lib/graphql';
import {
    SHOP_USER_UPDATE_SUCCESS,
    SHOP_USER_UPDATE_START,
    SHOP_USER_UPDATE_ERROR
} from '../../../constants/index';

export default function updateShopStatus(updateField, id, status, currentPage, userType) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: SHOP_USER_UPDATE_START,
            payload: {
                updateLoading: true
            }
        });

        try {
            let label, requestData;

            label = userType === 3 ? 'Restaurant' : (userType === 2 ? 'Driver' : 'User')

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    updateField,
                    status: status == '0' ? 0 : status
                }
            });

            if (data?.updateShopStatus?.status === "200") {
                dispatch({
                    type: SHOP_USER_UPDATE_SUCCESS,
                    payload: {
                        updateLoading: false
                    }
                });
                if (updateField === "isBan" && status == 1) {
                    requestData = { id };
                    socketNotification('webUserLogout', JSON.stringify(requestData));
                }

                showToaster({ messageId: 'statusUpdated', toasterType: 'success', requestContent: label })

                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: SHOP_USER_UPDATE_ERROR,
                    payload: {
                        updateLoading: false
                    }
                });

                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error' })

                return {
                    status: 400
                };
            }
        } catch (err) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err })

            dispatch({
                type: SHOP_USER_UPDATE_ERROR,
                payload: {
                    updateLoading: false
                }
            });
            return {
                status: 400
            };
        }
    }
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import { Form, Container } from 'react-bootstrap';
import Loader from '../../Common/Loader';
import messages from '../../../locale/messages';
import validate from './validate';
import updateFoodChoice from '../../../actions/siteadmin/FoodChoice/updateFoodChoice';
import s from './FoodChoiceForm.css';

class FoodChoiceForm extends Component {

    static propTypes = {
        prop: PropTypes
    }

    renderField = ({ input, label, type, meta: { touched, error }, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlSelect = ({ input, label, meta: { touched, error }, children, fieldClass }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control as="select" {...input} className={fieldClass} >
                    {children}
                </Form.Control>
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    handleFormSubmit = async (values) => {
        const { updateFoodChoice, paginationData } = this.props;
        values.isEnable = (values.isEnable === false || values.isEnable == "false") ? false : true
        await updateFoodChoice(
            values?.id,
            values?.itemName,
            values?.isEnable
        );
        paginationData && paginationData(1);
    }

    render() {
        const { error, handleSubmit, submitting, loading, id } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div>
                <Container className={s.containerWith}>
                    <Form className={s.fullWidth} onSubmit={handleSubmit(this.handleFormSubmit)}>
                        <div>
                            <Field
                                name="itemName"
                                type="text"
                                placeholder={formatMessage(messages.itemNameLabel)}
                                component={this.renderField}
                                label={formatMessage(messages.itemNameLabel)}
                                labelClass={s.labelText}
                                fieldClass={s.formControlInput}
                            />
                        </div>
                        {id && <div>
                            <Field name="isEnable"
                                label={formatMessage(messages.status)}
                                component={this.renderFormControlSelect}
                                fieldClass={cx(s.formControlSelect, s.formControlInput, "selectBoxPadding")}
                            >
                                <option value={true} >{formatMessage(messages.enable)}</option>
                                <option value={false} >{formatMessage(messages.disable)}</option>
                            </Field>
                        </div>}
                        <div className={s.textAlignEnd}>
                            <Form.Group className={s.formGroup}>
                                <Loader
                                    type={"button"}
                                    label={formatMessage(messages.submitButton)}
                                    show={loading}
                                    buttonType={'submit'}
                                    className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                                    disabled={submitting || loading}
                                />
                            </Form.Group>
                        </div>
                    </Form>
                </Container>
            </div>
        )
    }
}

FoodChoiceForm = reduxForm({
    form: 'FoodChoiceForm',
    destroyOnUnmount: true,
    validate
})(FoodChoiceForm);

const selector = formValueSelector('FoodChoiceForm');

const mapState = (state) => ({
    loading: state?.loader?.updateFoodLoading,
    id: selector(state, 'id')
})

const mapDispatch = {
    updateFoodChoice
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(FoodChoiceForm)));


import showToaster from '../../../helpers/showToaster';
import { closeSubMenuModal } from '../../siteadmin/modalActions';
import { loadAccount } from '../userAccount/userAccount';
import { getAllSubMenu, updateSubMenu, addSubMenuDetails } from '../../lib/graphql';

function addSubMenu(values) {

	return async (dispatch, getState, { client }) => {

		try {

			if (values?.id) {
				const { data } = await client.mutate({
					mutation: updateSubMenu,
					variables: values,
					refetchQueries: [{ query: getAllSubMenu, variables: { currentPage: 1, searchList: '', menuId: values?.menuId } }]
				});

				if (data?.updateSubMenu?.status == 200) {
					showToaster({ messageId: 'changesUpdated', toasterType: 'success' })
					dispatch(closeSubMenuModal());
					dispatch(loadAccount())

				} else {
					showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateSubMenu?.errorMessage })
				}

			} else {

				const { data } = await client.mutate({
					mutation: addSubMenuDetails,
					variables:
						values,
					refetchQueries: [{ query: getAllSubMenu, variables: { currentPage: 1, searchList: '', menuId: values.menuId } }]
				});

				if (data?.addSubMenu?.status == 200) {
					showToaster({ messageId: 'subMenuAdded', toasterType: 'success' })
					dispatch(closeSubMenuModal());
				} else {
					showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.addSubMenu?.errorMessage })
				}
			}

		} catch (error) {
			showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: error })
		}
	}
}

export default addSubMenu;
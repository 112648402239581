import showToaster from '../../../../helpers/showToaster';
import addMenu from '../../../../actions/shop/menu/addMenu';

async function submit(values, dispatch) {

    let isError;
    values?.menuAvailability?.map((item, index) => {

        item?.timeSlot?.length > 0 && item?.timeSlot?.map((item2, index) => {

            if (parseFloat(item2?.endTime) < parseFloat(item2?.startTime) || parseFloat(item2?.endTime) == parseFloat(item2?.startTime)) {
                isError = true;
            }
        });
    });

    if (isError === true) {
        showToaster({ messageId: 'endTimeMustHigher', toasterType: 'error' })
    } else {
        isError = false;
        await dispatch(addMenu(values));
    }

}

export default submit;

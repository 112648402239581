import gql from "graphql-tag";

export const updateFoodChoice = gql`
  mutation updateFoodChoice($id: Int, $itemName: String!, $isEnable: Boolean) {
    updateFoodChoice(id: $id, itemName: $itemName, isEnable: $isEnable) {
      status
      errorMessage
    }
  }
`;

export const updateFoodChoiceStatus = gql`
  mutation updateFoodChoiceStatus($id: Int!, $isEnable: Boolean!) {
    updateFoodChoiceStatus(id: $id, isEnable: $isEnable) {
      status
      errorMessage
    }
  }
`;

export const deleteFoodChoice = gql`
  mutation deleteFoodChoice($id: Int!) {
    deleteFoodChoice(id: $id) {
      status
      errorMessage
    }
  }
`;

export const getFoodChoices = gql`
  query getFoodChoices($searchList: String, $currentPage: Int) {
    getFoodChoices(searchList: $searchList, currentPage: $currentPage) {
      results {
        id
        itemName
        isEnable
      }
      count
      status
      errorMessage
    }
  }
`;

export const getAllFoodChoice = gql`
query getAllFoodChoice{
  getAllFoodChoice{
      results {
        id
        itemName
        isEnable
      }
      count
      status
      errorMessage
    }
  }
`;

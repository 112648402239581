import showToaster from '../../../helpers/showToaster';
import { getAllModifier as query, updateModifierStatus as mutation } from '../../lib/graphql';
import { getAllShopModifier } from './getAllShopModifier';
import {
	SHOP_MODIFIER_UPDATE_START,
	SHOP_MODIFIER_UPDATE_SUCCESS,
	SHOP_MODIFIER_UPDATE_ERROR
} from '../../../constants/index';

export default function updateModifierStatus(id, status, currentPage, searchList) {
	return async (dispatch, getState, { client }) => {

		dispatch({
			type: SHOP_MODIFIER_UPDATE_START,
			payload: {
				updateLoading: true
			}
		});

		try {
			const { data: { updateModifierStatus } } = await client.mutate({
				mutation,
				variables: {
					id,
					status: status == 'false' ? 0 : 1
				},
				refetchQueries: [{ query, variables: { currentPage, searchList } }]
			});
			
			if (updateModifierStatus?.status === 200) {

				dispatch({
					type: SHOP_MODIFIER_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'modifierStatusUpdate', toasterType: 'success' })

			} else {

				dispatch({
					type: SHOP_MODIFIER_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: updateModifierStatus?.errorMessage });

			}
			dispatch(getAllShopModifier());

		} catch (err) {

			dispatch({
				type: SHOP_MODIFIER_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
			showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: err });

		}
	}
};

import gql from "graphql-tag";

export const addPromoCodeMutation = gql`
mutation( 
  $title: String!, 
  $description: String!, 
  $code: String!, 
  $type: Int!,
  $promoValue: Float!, 
  $currency: String,
  $expiryDate: String
) {
  addPromoCode(
      title: $title,
      description: $description,
      code: $code,
      type: $type,
      promoValue: $promoValue,
      currency: $currency,
      expiryDate: $expiryDate
  ) {
      status
      errorMessage
  }
}`;

export const updatePromoCodeMutation = gql`
mutation( 
  $id: Int!,
  $title: String!, 
  $description: String!, 
  $code: String!, 
  $type: Int!,
  $promoValue: Float!, 
  $currency: String,
  $expiryDate: String,
  $isEnable: String
) {
  updatePromoCode(
      id: $id,
      title: $title,
      description: $description,
      code: $code,
      type: $type,
      promoValue: $promoValue,
      currency: $currency,
      expiryDate: $expiryDate,
      isEnable: $isEnable
  ) {
      status
      errorMessage
  }
}`;

export const deletePromoCodeMutation = gql`mutation($id: Int!) {
  deletePromoCode(
      id: $id
  ) {
      status
      errorMessage
  }
}`;

export const getPromoCodes = gql`query($currentPage: Int) {
    getPromoCodes(currentPage: $currentPage) {
        status
        errorMessage
        count
        data {
          id
          title
          description
          code
          type
          promoValue
          currency
          expiryDate
          isEnable
          createdAt
          updatedAt
        }
    }
}`;
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import messages from '../../../locale/messages';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './DocumentForm.css';
import rs from '../../restaurantCommon.css';
import {
    Col,
    Row,
    Form,
    Container
} from 'react-bootstrap';
import validate from './validate';
import submit from './submit';
import Loader from '../../Common/Loader';
import DocumentField from './DocumentField';

class DocumentForm extends React.Component {

    constructor(props) {
        super(props);
    }

    static defaultProps = {
        loading: false
    }

    componentWillMount() {
        const { array, initialValues } = this.props
        initialValues.documentList && initialValues.documentList.length > 0 ? '' : array.push('documentList', {})
    }

    loaderStart = () => {
        const { setLoaderStart } = this.props;
        setLoaderStart('profileImageLoader')
    }

    loaderEnd = () => {
        const { setLoaderComplete } = this.props;
        setLoaderComplete('profileImageLoader')
    }

    render() {
        const { loading, handleSubmit, submitting } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className={'mainContainer'}>
                <div>
                    <Container fluid className={s.container}>
                        <Row>
                            <Col md={12} lg={12} sm={12} xs={12}>
                                <>
                                    <h1 className={rs.restaurantTitleText}>
                                        <FormattedMessage {...messages.documentUpload} />
                                    </h1>
                                </>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Row>
                                        <Col lg={12} md={12} sm={12} xs={12} className={rs.noPadding}>
                                            <DocumentField />
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className={s.formGroup}>
                                                <div className={cx(rs.alignRightText, 'textAlignLeftRTL')}>
                                                    <Loader
                                                        type={"button"}
                                                        label={formatMessage(messages.update)}
                                                        show={loading}
                                                        buttonType={'submit'}
                                                        className={cx(rs.button, rs.btnPrimary, 'rtlBtnLoader')}
                                                        disabled={submitting || loading}
                                                        isSuffix
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

DocumentForm = reduxForm({
    form: 'DocumentForm',
    onSubmit: submit,
    validate,
    enableReinitialize: true
})(DocumentForm);

export default injectIntl(withStyles(s, rs)(DocumentForm));
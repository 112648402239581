import showToaster from '../../../helpers/showToaster';
import { deleteFoodChoice as mutation } from '../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import {
    UPDATE_FOOD_CHOICE_START,
    UPDATE_FOOD_CHOICE_SUCCESS,
    UPDATE_FOOD_CHOICE_ERROR
} from '../../../constants/index';

const deleteFoodChoice = (id) => {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: UPDATE_FOOD_CHOICE_START,
            });

            dispatch(setLoaderStart('deleteFooChoice'));
            const { data } = await client.mutate({
                mutation,
                variables:{
                    id
                }
            });

            dispatch({
                type: data?.deleteFoodChoice?.status != 200 ? UPDATE_FOOD_CHOICE_SUCCESS : UPDATE_FOOD_CHOICE_ERROR,
            });

            if (data?.deleteFoodChoice?.status == 200) {
                showToaster({ messageId: 'updatedFoodChoiceSuccess', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.deleteFoodChoice?.errorMessage })
            }
            dispatch(setLoaderComplete('deleteFooChoice'));

        } catch (error) {
            dispatch({
                type: UPDATE_FOOD_CHOICE_ERROR,
            });
            dispatch(setLoaderComplete('deleteFooChoice'));
        }
    }

}

export default deleteFoodChoice;

import axois from "axios";
import { api } from "../config";

export const removeImage = async (url, fileName) => {
    try {
        const response = await axois.post(`${api?.apiEndpoint}${url}`, { fileName }, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        const { status } = response;
        if (status) {
            return status;
        }
    } catch {
        console.log(error);
    }
}
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl, Button } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import cx from 'classnames';
import CustomPagination from '../../CustomPagination';
import FoodChoiceModal from '../FoodChoiceForm/FoodChoiceModal';
import { openFoodChoiceModal } from '../../../actions/modalActions';
import updateFoodChoiceStatus from '../../../actions/siteadmin/FoodChoice/updateFoodChoiceStatus';
import deleteFoodChoice from '../../../actions/siteadmin/FoodChoice/deleteFoodChoice';
import messages from '../../../locale/messages';
import TrashIcon from '../../../../public/Icons/bin.svg';
import EditIcon from '../../../../public/Icons/edit.png';
import s from './FoodChoiceList.css';

export class FoodChoiceList extends Component {

    static propTypes = {
        getFoodChoices: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            searchList: ''
        }
    }

    handleSearchClick = (searchList) => {
        const { refetch } = this.props
        let variables = {
            currentPage: 1,
            searchList: searchList
        }
        this.setState({ currentPage: 1 })
        refetch(variables)
    }

    handleSearchChange(e) {
        let self = this
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout)
        }
        self.setState({
            searchList: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.handleSearchClick(self.state.searchList)
            }, 450)
        })
    }

    handleDelete = async (id) => {
        const { refetch, deleteFoodChoice } = this.props;
        await deleteFoodChoice(id);
        this.setState({ currentPage: 1 })
        let variables = { currentPage: 1 };
        refetch(variables);
    }

    handleStatusChange = async (id, status) => {
        const { refetch, updateFoodChoiceStatus } = this.props;
        status = (!status || status == "false") ? false : true
        const { currentPage } = this.state;
        await updateFoodChoiceStatus(id, status);
        let variables = { currentPage };
        refetch(variables);
    }

    paginationData = (currentPage) => {
        const { refetch } = this.props;
        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
    }

    render() {
        const { getFoodChoices, openFoodChoiceModal, isDeleteLoading } = this.props;
        const { currentPage } = this.state;
        const { formatMessage } = this.props.intl;
        return (

            <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
                <FoodChoiceModal paginationData={this.paginationData} />
                <div className={cx(s.tabelExport, s.alignItemCenter)}>
                    <div className={s.searchInput}>
                        <FormControl type='text' placeholder='Search' onChange={(e) => this.handleSearchChange(e)} className={s.formControlInput} />
                    </div>
                    <div>
                        <Button
                            className={cx(s.addlinkBtn)}
                            onClick={openFoodChoiceModal}
                        >
                            <FormattedMessage {...messages.addNewLabel} />
                        </Button>
                    </div>
                </div>
                <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
                    <Table className="table">
                        <thead>
                            <tr>
                                <th scope="col"><FormattedMessage {...messages.id} /></th>
                                <th scope="col"><FormattedMessage {...messages.itemNameLabel} /></th>
                                <th scope="col"><FormattedMessage {...messages.status} /></th>
                                <th scope="col"><FormattedMessage {...messages.action} /></th>
                                <th scope="col"><FormattedMessage {...messages.deleteAction} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getFoodChoices?.results?.map((item, index) => {
                                    let isEnable = (!item?.isEnable || item?.isEnable == "false") ? false : true
                                    return (
                                        <tr key={index}>
                                            <td data-label={formatMessage(messages.id)}>{item.id}</td>
                                            <td data-label={formatMessage(messages.itemNameLabel)}>{item.itemName}</td>
                                            <td data-label={formatMessage(messages.status)}>
                                                <select value={isEnable} onChange={(e) => { this.handleStatusChange(item?.id, e.target?.value) }} className={s.selectInput}>
                                                    <option value={true}>{formatMessage(messages.enable)}</option>
                                                    <option value={false}>{formatMessage(messages.disable)}</option>
                                                </select>
                                            </td>
                                            <td data-label={formatMessage(messages.action)}><a href="javascript:void(0)" onClick={() => openFoodChoiceModal(item)} className={'editAlignIcon'}>
                                                <img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} />
                                                <span className={s.vtrMiddle}>
                                                    <FormattedMessage {...messages.editAction} />
                                                </span>
                                            </a></td>
                                            <td data-label={formatMessage(messages.deleteAction)}>
                                                <Button disabled={isDeleteLoading} onClick={() => this.handleDelete(item?.id)} className={s.iconBtn}>
                                                    <img src={TrashIcon} className={cx(s.editIcon, 'trashIconRTL', 'editIconRTL')} />
                                                    <span className={s.vtrMiddle}>
                                                        <FormattedMessage {...messages.deleteAction} />
                                                    </span>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                getFoodChoices?.results?.length == 0 && (
                                    <tr>
                                        <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
                {
                    getFoodChoices?.results?.length > 0 && <div className={cx(s.space5, s.spaceTop5)}>
                        <CustomPagination
                            total={getFoodChoices?.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={formatMessage(messages.foodChoice)}
                        />
                    </div>
                }
            </div>
        )
    }
}

const mapState = (state) => ({
    isDeleteLoading: state?.loader?.deleteFooChoice

});
const mapDispatch = {
    openFoodChoiceModal,
    updateFoodChoiceStatus,
    deleteFoodChoice
};

export default injectIntl(compose(
    withStyles(s),
    connect(mapState, mapDispatch)
)(FoodChoiceList));


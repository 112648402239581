import showToaster from '../../../helpers/showToaster';
import updatePreparationTime from '../../../actions/shop/preparationTime/updatePreparationTime'

async function submit(values, dispatch) {

    let isAlreadyExist;

    values?.preparationTime?.map((item, index, arr) => {


        if (parseFloat(item?.endTime) < parseFloat(item?.startTime)) {
            isAlreadyExist = true;
        } else if (parseFloat(item?.endTime) == parseFloat(item?.startTime)) {
            isAlreadyExist = true;
        }


        let checkError = arr?.filter((match) => {
            if (match?.operationDay == item?.operationDay) {
                if (parseFloat(match?.startTime) == parseFloat(item?.startTime)) {
                    return true;
                } else if (parseFloat(match?.endTime) == parseFloat(item?.endTime)) {
                    return true;
                } else if (parseFloat(item?.startTime) > parseFloat(match?.startTime) && parseFloat(item?.startTime) < parseFloat(match?.endTime)) {
                    return true;
                }
            } else {
                return false
            }
        });

        if (isAlreadyExist) {
            isAlreadyExist = true;
        } else {
            isAlreadyExist = checkError.length > 1 ? true : false
        }

    })

    let preparationTime = values?.preparationTime?.length > 0 ? JSON.stringify(values?.preparationTime) : JSON.stringify([]);

    if (isAlreadyExist === true) {
        showToaster({ messageId: 'checkTimeSlot', toasterType: 'error' })
    } else {
        isAlreadyExist = false;
        await dispatch(updatePreparationTime(values?.shopEstimatedTime, preparationTime));
    }

}

export default submit;
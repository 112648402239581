import messages from '../../../../locale/messages';
import { inputTextLimit } from '../../../../helpers/inputTextLimit';

const validate = values => {
    const errors = {};

    if (!values.infoTitle1) {
        errors.infoTitle1 = messages.required;
    } else if (values.infoTitle1.trim() == "") {
        errors.infoTitle1 = messages.required;
    } else if (values.infoTitle1.length < 2 ) {
        errors.infoTitle1 = messages.required
    } else if (values.infoTitle1.length > inputTextLimit) {
        errors.infoTitle1 = messages.textAreaError
    }

    if (!values.infoTitle2) {
        errors.infoTitle2 = messages.required;
    } else if (values.infoTitle2.trim() == "") {
        errors.infoTitle2 = messages.required;
    } else if (values.infoTitle2.length < 2 ) {
        errors.infoTitle2 = messages.required
    } else if (values.infoTitle2.length > inputTextLimit) {
        errors.infoTitle2 = messages.textAreaError
    }

    if (!values.infoTitle3) {
        errors.infoTitle3 = messages.required;
    } else if (values.infoTitle3.trim() == "") {
        errors.infoTitle3 = messages.required;
    } else if (values.infoTitle3.length < 2 ) {
        errors.infoTitle3 = messages.required
    } else if (values.infoTitle3.length > inputTextLimit) {
        errors.infoTitle3 = messages.textAreaError
    }

    if (!values.infoTitle4) {
        errors.infoTitle4 = messages.required;
    } else if (values.infoTitle4.trim() == "") {
        errors.infoTitle4 = messages.required;
    } else if (values.infoTitle4.length < 2 ) {
        errors.infoTitle4 = messages.required
    } else if (values.infoTitle4.length > inputTextLimit) {
        errors.infoTitle4 = messages.textAreaError
    }

    if (!values.infoContent4) {
        errors.infoContent4 = messages.required;
    } else if (values.infoContent4.trim() == "") {
        errors.infoContent4 = messages.required;
    } else if (values.infoContent4.length < 2 ) {
        errors.infoContent4 = messages.required
    }





    return errors;
};

export default validate;
import showToaster from '../../../helpers/showToaster';
import { deleteDeliveryVehicleMutation } from '../../lib/graphql';
import { removeImage } from '../../../helpers/removeImage';
import {
    DELETE_DELIVERY_VEHICLE_SUCCESS,
    DELETE_DELIVERY_VEHICLE_START,
    DELETE_DELIVERY_VEHICLE_ERROR
} from '../../../constants';

export default function deleteDeliveryVehicle(id, fileName) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: DELETE_DELIVERY_VEHICLE_START
        });

        try {

            const { data } = await client.mutate({
                mutation: deleteDeliveryVehicleMutation,
                variables: {
                    id
                }
            });

            if (data?.deleteDeliveryVehicle?.status === 200) {

                dispatch({
                    type: DELETE_DELIVERY_VEHICLE_SUCCESS
                });
                showToaster({ messageId: 'vehicleDeleted', toasterType: 'success' })

                let url = "/deleteDeliveryVehicleImage";

                if (fileName) {
                    removeImage(url, fileName)
                }
                return {
                    status: 200
                };
            } else {

                dispatch({
                    type: DELETE_DELIVERY_VEHICLE_ERROR
                });
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.deleteDeliveryVehicle?.errorMessage })
                return {
                    status: 400
                };
            }
        } catch (error) {

            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            dispatch({
                type: DELETE_DELIVERY_VEHICLE_ERROR
            });
            return {
                status: 400
            };
        }
    }
};

import showToaster from '../../../helpers/showToaster';
import updateOperationHours from '../../../actions/shop/updateOperationHours';

async function submit(values, dispatch) {
    let isError = false, operationHours;

    values?.OperationHours?.map((item, index) => {
        item.isOpen = item?.isOpen == false ? '0' : '1';
        if(item?.endTime && item?.startTime) { 
            if(Number(item?.startTime) < Number(item?.endTime)) {
                isError = false;
            } else if(Number(item?.startTime) > Number(item?.endTime) || Number(item?.startTime) == Number(item?.endTime)) {
                isError  = true;
            }
        }
    });

    operationHours = values?.OperationHours?.length > 0 ? JSON.stringify(values?.OperationHours) : JSON.stringify([]);
    
    if(!isError) {
        await dispatch(updateOperationHours(operationHours));
    } else {
        await showToaster({ messageId: 'endTimeMustHigher', toasterType: 'error' });
    }
}

export default submit;
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;\n  /* Extra small screen / phone */\n  --screen-sm-min: 768px;\n  /* Small screen / tablet */\n  --screen-md-min: 992px;\n  /* Medium screen / desktop */\n  --screen-lg-min: 1200px;\n  /* Large screen / wide desktop */\n\n  --btn-primary-bg: #3e0056;\n  --btn-boxShadow-bg: #3e0056;\n  --btn-bordercolor-bg: #3e0056;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #3e0056;\n  --btn-secondary-bg: #3e0056;\n  --title-color: #1a1b1c;\n  --content-color: #3a3a3a;\n  --chat-background-color: #F6F6F6;\n  --border-color: #CED4DA;\n  --common-tooltip-color: #151515;\n  --dayPickerBg: #5EBE00;\n  --thameColor:#5EBE00 ;\n}\n\n._10-No {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n._2vDPn {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 1170px;\n  max-width: var(--max-content-width);\n}\n", ""]);

// exports
exports.locals = {
	"root": "_10-No",
	"container": "_2vDPn"
};
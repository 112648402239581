import showToaster from '../../../helpers/showToaster';
import histroy from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { addCategoryMutation as mutation } from '../../lib/graphql';

export function addCategory(categoryName, isActive, categoryImage, description) {

  return async (dispatch, getState, { client }) => {
    try {
      dispatch(setLoaderStart('AddCategory'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          categoryName,
          isActive: (isActive === true || isActive === 'true') ? 1 : 0,
          categoryImage,
          description
        }
      });

      dispatch(setLoaderComplete('AddCategory'));

      if (data?.addCategory?.status === 200) {
        showToaster({ messageId: 'cuisineAdded', toasterType: 'success' })
        histroy.push('/siteadmin/cuisine');
      } else if (data?.addCategory?.status !== 200) {
        showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.addCategory?.errorMessage })
      } else {
        showToaster({ messageId: 'somethingWentWrong', toasterType: 'error' })
      }
    } catch (error) {
      showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: error })
    }
  }
};
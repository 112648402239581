import showToaster from '../../../helpers/showToaster';
import { itemAvailable as query } from '../../lib/graphql/restaurant';

export default function itemAvailable(id, currentPage, menuId) {
    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.query({
                query,
                fetchPolicy: 'network-only'
            });

            if (data?.itemAvailable?.status === 200) {
                return {
                    action: 'success'
                }
            } else {
                let errorMessage = data?.itemAvailable?.errorMessage;
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: errorMessage })
                return {
                    action: 'failed'
                }
            }
        } catch (err) {
            return {
                action: 'failed'
            }
        }
    }
};

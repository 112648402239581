import showToaster from '../../../helpers/showToaster';
import { verifyOtpCode as mutation } from '../../lib/graphql';
import { closeSmsVerificationModal } from './smsVerificationModaAction';

function checkOtp(otp) {
    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.mutate({
                mutation,
                variables: {
                    otp: otp
                }
            });

            if (data?.verifyOtpCode?.status === 200) {
                showToaster({ messageId: 'phoneNumberVerified', toasterType: 'success' })
                await dispatch(closeSmsVerificationModal());
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
        return true;
    }
};

export default checkOtp;
import gql from "graphql-tag";

export const updateModifierMutation = gql`mutation (
    $modifierName: String!,
    $modifierType: Int,
    $minModifierItems: Int,    
    $maxModifierItems: Int,    
    $isModifierRequired: Boolean,    
    $isModifierMultiple: Boolean,    
    $maxItemCount: Int,    
    $isActive: Boolean,    
    $id: Int,    
    $modifierItems: String
) {
    updateModifierGroup (
        modifierName: $modifierName,
        modifierType: $modifierType,
        minModifierItems: $minModifierItems,
        maxModifierItems: $maxModifierItems,
        isModifierRequired: $isModifierRequired,
        isModifierMultiple: $isModifierMultiple,
        maxItemCount: $maxItemCount,
        isActive: $isActive,
        id: $id,
        modifierItems: $modifierItems
    ){
        status
        errorMessage
    }
}`;

export const getAllModifier = gql`query($currentPage: Int, $searchList: String) {
    getAllModifier(currentPage: $currentPage, searchList: $searchList){
      count
      status
      currentPage
      errorMessage
      results{
       id
       modifierName
       modifierType
       isActive
       itemId
       clonedBy
       modifierItems{
              id
              modifierGroupId
              modifierItemName
              modifierItemPrice
            }
      }
    }
}`;

export const deleteModifier = gql`mutation deleteModifier($id: Int) {
    deleteModifier(id: $id) {
      status
      errorMessage
    }
}`;

export const getAllShopModifier = gql`query {
    getAllShopModifier{
      status
      errorMessage
      results{
       id
       modifierName
       modifierType
       minModifierItems
       maxModifierItems
       isModifierRequired
       isModifierMultiple
       maxItemCount
       isActive
       itemId
           clonedBy
       modifierItems{
         id
         modifierGroupId
         modifierItemName
         modifierItemPrice
       }
      }
    }
}`;

export const getCartModifierGroup = gql`query($modifierGroupId: Int) {
    getCartModifierGroup(modifierGroupId : $modifierGroupId){
      status
      errorMessage
      result{
       removeModifierGroupId
      }
    }
}`;

export const getCartModifierItem = gql`query($modifierItemId: Int) {
    getCartModifierItem(modifierItemId : $modifierItemId){
      status
      errorMessage
      result{
       removeModifierItemId
      }
    }
}`;

export const getModifier = gql`query getModifier($id: Int!){
    getModifier( id: $id){
      status
      errorMessage
      result{
       id
       modifierName
       modifierType
       minModifierItems
       maxModifierItems
       isModifierRequired
       isModifierMultiple
       maxItemCount
       isActive
       itemId
       clonedBy
       modifierItems{
         id
         modifierGroupId
         modifierItemName
         modifierItemPrice
       }
       modifierCommonId
      }
    }
}`;

export const updateModifierStatus = gql`mutation updateModifierStatus($id: Int, $status: Boolean) {
    updateModifierStatus(id: $id, status:$status) {
      status
      errorMessage
    }
}`;

import history from '../../../history';
import showToaster from '../../../helpers/showToaster';
import { removePayout as mutation } from '../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { getPayouts } from './getPayouts';
import {
  REMOVE_PAYOUT_START,
  REMOVE_PAYOUT_SUCCESS,
  REMOVE_PAYOUT_ERROR,
} from '../../../constants';

export function removePayout(id) {

  return async (dispatch, getState, { client }) => {
    try {
      dispatch({
        type: REMOVE_PAYOUT_START,
      });

      dispatch(setLoaderStart('payoutRemove'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id
        }
      });

      if (data?.removePayout.status == 200) {
        await dispatch({
          type: REMOVE_PAYOUT_SUCCESS
        });
        await showToaster({ messageId: 'payoutAccountRemoved', toasterType: 'success' });
        await dispatch(setLoaderComplete('payoutRemove'));
        await dispatch(getPayouts());
        history.push('/restaurant/payout');
      }
      await dispatch(setLoaderComplete('payoutRemove'));

    } catch (error) {
      await showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
      dispatch({
        type: REMOVE_PAYOUT_ERROR,
        payload: {
          error
        }
      });
      dispatch(setLoaderComplete('payoutRemove'));
      return false;
    };
    return true;
  };
};

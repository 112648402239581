import { getCartModifierGroup as query } from '../../lib/graphql';
import {
    SHOP_MODIFIER_ITEM_UPDATE_START,
    SHOP_MODIFIER_ITEM_UPDATE_SUCCESS,
    SHOP_MODIFIER_ITEM_UPDATE_ERROR
} from '../../../constants/index';

export default function getBookedModifierGroup(modifierGroupId) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: SHOP_MODIFIER_ITEM_UPDATE_START,
        });
        try {
            const { data: { getCartModifierGroup } } = await client.query({
                query,
                variables: {
                    modifierGroupId
                }
            });

            if (getCartModifierGroup?.status === 200) {

                dispatch({
                    type: SHOP_MODIFIER_ITEM_UPDATE_SUCCESS,
                });

                return {
                    data: getCartModifierGroup?.result?.removeModifierGroupId
                }
            }

        } catch (error) {
            
            dispatch({
                type: SHOP_MODIFIER_ITEM_UPDATE_ERROR,
            });
        }
    }
}
import showToaster from '../../../helpers/showToaster';
import { updateCategoryPrivilegeMutation as mutation } from '../../lib/graphql';
import {
    UPDATE_CATEGORY_PRIVILEGE_SUCCESS,
    UPDATE_CATEGORY_PRIVILEGE_START,
    UPDATE_CATEGORY_PRIVILEGE_ERROR
} from '../../../constants';

export default function updateCategoryPrivilege(id, isActive, privilegeType) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: UPDATE_CATEGORY_PRIVILEGE_START
        });

        try {

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    privilegeType,
                    isActive
                }
            });

            if (data?.updateCategoryPrivilege?.status === 200) {

                dispatch({
                    type: UPDATE_CATEGORY_PRIVILEGE_SUCCESS
                });
                showToaster({ messageId: 'cuisineUpdated', toasterType: 'success' })
                return {
                    status: 200
                };
            } else {

                dispatch({
                    type: UPDATE_CATEGORY_PRIVILEGE_ERROR
                });
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateCategoryPrivilege?.errorMessage })
                return {
                    status: 400
                };
            }
        } catch (err) {

            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err });
            dispatch({
                type: UPDATE_CATEGORY_PRIVILEGE_ERROR
            });
            return {
                status: 400
            };
        }
    }
};
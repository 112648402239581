import { updateStaticPage } from '../../../actions/siteadmin/updateStaticPage';

async function submit(values, dispatch) {
    if (values?.content == null || values?.content == '<p><br></p>' || values?.content == '<p> </p>') {
        showToaster({ messageId: 'addContent', toasterType: 'error' })
    } else {
        await dispatch(updateStaticPage(values));
    }
}

export default submit;
import showToaster from '../../helpers/showToaster';
import { uploadRcbookImageMutation as mutation } from '../lib/graphql';
import { removeImage } from '../../helpers/removeImage';
import {
  RCBOOK_IMAGE_UPLOAD_ERROR,
  RCBOOK_IMAGE_UPLOAD_START,
  RCBOOK_IMAGE_UPLOAD_SUCCESS
} from '../../constants/index'

const uploadRcbookImage = (userId, fileName, oldFile) => {
  return async (dispatch, getState, { client }) => {
    try {
      let url = "/deleteRcbookImage";

      dispatch({
        type: RCBOOK_IMAGE_UPLOAD_START
      });
      const { data } = await client.mutate({
        mutation,
        variables: {
          userId,
          vehicleRC: fileName
        }
      });

      if (oldFile !== undefined) {
        removeImage(url, oldFile)
      };

      if (data?.uploadRcbookImage?.status == "200") {
        dispatch({
          type: RCBOOK_IMAGE_UPLOAD_SUCCESS
        });
        showToaster({ messageId: 'rcBookImageUploaded', toasterType: 'success' });
      } else {
        dispatch({
          type: RCBOOK_IMAGE_UPLOAD_ERROR
        });
      }
    } catch (err) {
      dispatch({
        type: RCBOOK_IMAGE_UPLOAD_ERROR
      });
    }
  };
};

export default uploadRcbookImage;
import showToaster from '../../../helpers/showToaster';
import { updateDeliveryVehicleStatusMutation as mutation } from '../../lib/graphql';
import {
    UPDATE_DELIVERY_VEHICLE_STATUS_SUCCESS,
    UPDATE_DELIVERY_VEHICLE_STATUS_START,
    UPDATE_DELIVERY_VEHICLE_STATUS_ERROR
} from '../../../constants';

export default function updateDeliveryVehicleStatus(id, isActive) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: UPDATE_DELIVERY_VEHICLE_STATUS_START
        });

        try {

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    isActive: isActive == '1' ? 1 : 0
                }
            });

            if (data?.updateDeliveryVehicleStatus?.status === 200) {

                dispatch({
                    type: UPDATE_DELIVERY_VEHICLE_STATUS_SUCCESS
                });
                showToaster({ messageId: 'vehicleTypeStatus', toasterType: 'success' })
                return {
                    status: 200
                };
            } else {

                dispatch({
                    type: UPDATE_DELIVERY_VEHICLE_STATUS_ERROR
                });
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateDeliveryVehicleStatus?.errorMessage })
                return {
                    status: 400
                };
            }
        } catch (err) {

            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err })
            dispatch({
                type: UPDATE_DELIVERY_VEHICLE_STATUS_ERROR
            });
            return {
                status: 400
            };
        }
    }
};
import React from 'react'
import { Field, FieldArray, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
    Col,
    Form,
    Button
} from 'react-bootstrap';

import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import { api, restaurantDocumentUploadDir } from '../../../config';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import messages from '../../../locale/messages';

import NoLogo from '../../../../public/RestaurantIcon/documentUpload.svg';
import closeIcon from '../../../../public/RestaurantIcon/closeIconRes.png';

import s from './DocumentForm.css';
import rs from '../../restaurantCommon.css';
class DocumentField extends React.Component {

    renderDocument = ({ fields, imageLoader }) => {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                {fields?.map((document, index) => {
                    let documentName;
                    documentName = fields?.get(index)?.fileName;
                    return (
                        <div className={s.displayInlineBock}>
                            <div>
                                <Col lg={12} md={12} sm={12} xs={12} className={rs.space2}>
                                    <Form.Group className={s.formGroup}>
                                        <div className={cx(rs.profileImgSection, rs.profileImgWidth)}>
                                            <div className={'retaurantDroupZone'}>
                                                <ImageUploadComponent
                                                    className={cx(rs.profileButton)}
                                                    subTextClass={s.subText}
                                                    subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                    defaultMessage={formatMessage(messages.chooseFile)}
                                                    inputContainer={'.dzInputContainerRestaurantDocument'}
                                                    inputContainerClass={'dzInputContainerRestaurantDocument'}
                                                    postUrl={api.apiEndpoint + '/uploadRestaurantDocument'}
                                                    imageSrc={documentName ? api.apiEndpoint + restaurantDocumentUploadDir + documentName : NoLogo}
                                                    fieldName={`${document}.fileName`}
                                                    loaderName={`DropZoneLoader${index}`}
                                                    loader={imageLoader[`DropZoneLoader${index}`]}
                                                    label={formatMessage(messages.documentImage)}
                                                    formName={'DocumentForm'}
                                                />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className={rs.space1}>
                                    <Form.Group className={s.formGroup}>
                                        <div>
                                            <Field
                                                name={`${document}.fileType`}
                                                type="text"
                                                placeholder={formatMessage(messages.documentName)}
                                                component={this.renderField}
                                                fieldClass={rs.formControlInputRestaurant}
                                                label={formatMessage(messages.documentName)}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                {index != 0 && <div className={cx(s.removeSection, 'removeSectionRTL')}>
                                    <Button
                                        variant="primary"
                                        onClick={() => fields.remove(index)}
                                        className={s.removeBtnSection}
                                    >
                                        <img src={closeIcon} />
                                    </Button>
                                </div>}
                                {fields.length - 1 == index && <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.space2, rs.alignRightText, 'textAlignLeftRTL')}>
                                    <div>
                                        <Button
                                            variant="primary"
                                            className={cx(rs.button, rs.btnPrimaryBorder)}
                                            onClick={() => fields.push({ fileType: '', fileName: '' })}
                                        >
                                            {formatMessage(messages.add)}
                                        </Button>
                                    </div>
                                </Col>}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    };


    render() {
        const { imageLoader } = this.props;
        return <>
            <FieldArray
                name="documentList"
                rerenderOnEveryChange
                imageLoader={imageLoader}
                component={this.renderDocument}
            />
        </>
    }
}
const selector = formValueSelector('DocumentForm');

const mapState = (state) => ({
    documentList: selector(state, 'documentList'),
    imageLoader: state.loader
});

const mapDispatch = {
    change,
    setLoaderStart,
    setLoaderComplete
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(DocumentField)));
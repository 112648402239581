import showToaster from '../../../helpers/showToaster';
import socketNotification from '../../../core/socket/socketNotification';
import { getAllAdminUsers as query, createAdminUserMutation, deleteAdminUserMutation, getAdminUserQuery, getPrivilegesQuery } from '../../lib/graphql';
import { closeAdminUserModal } from '../modalActions';
import { setRuntimeVariable } from '../../runtime';
import {
    CREATE_ADMIN_USER_START,
    CREATE_ADMIN_USER_SUCCESS,
    CREATE_ADMIN_USER_ERROR,
    DELETE_ADMIN_USER_START,
    DELETE_ADMIN_USER_SUCCESS,
    DELETE_ADMIN_USER_ERROR,
    GET_ADMIN_USER_START,
    GET_ADMIN_USER_SUCCESS,
    GET_ADMIN_USER_ERROR
} from '../../../constants';

const createAdminUser = (id, email, password, roleId) => {
    return async (dispatch, getState, { client }) => {

        await dispatch({
            type: CREATE_ADMIN_USER_START,
            payload: {
                createAdminUserLoading: true
            }
        });

        try {
            const { data } = await client.mutate({
                mutation: createAdminUserMutation,
                variables: {
                    id,
                    email,
                    password,
                    roleId
                },
                refetchQueries: [{ query }]
            });

            if (data?.createAdminUser?.status === 200) {
                await dispatch({
                    type: CREATE_ADMIN_USER_SUCCESS,
                    payload: {
                        createAdminUserLoading: false
                    }
                });
                dispatch(closeAdminUserModal());
                showToaster({ messageId: 'adminUserUpdate', toasterType: 'success', requestContent: id ? 'updated' : 'added' })

                return {
                    status: 200
                };
            } else {
                showToaster({ messageId: 'emailAlreadyExist', toasterType: 'error', requestContent: data?.createAdminUser?.errorMessage })
                await dispatch({
                    type: CREATE_ADMIN_USER_ERROR,
                    payload: {
                        createAdminUserLoading: false,
                        error: data?.createAdminUser?.errorMessage
                    }
                });
                return {
                    status: 400
                };
            }
        } catch (error) {
            await dispatch({
                type: CREATE_ADMIN_USER_ERROR,
                payload: {
                    createAdminUserLoading: false,
                    error
                }
            });
            return {
                status: 400
            };
        }
    }
}

const deleteAdminUser = (id) => {
    return async (dispatch, getState, { client }) => {
        await dispatch({
            type: DELETE_ADMIN_USER_START,
            payload: {
                deleteAdminUserLoading: true
            }
        });

        try {
            const { data } = await client.mutate({
                mutation: deleteAdminUserMutation,
                variables: {
                    id
                },
                refetchQueries: [{ query }]
            });

            if (data?.deleteAdminUser?.status === 200) {
                await dispatch({
                    type: DELETE_ADMIN_USER_SUCCESS,
                    payload: {
                        deleteAdminUserLoading: false
                    }
                });
                dispatch(closeAdminUserModal());
                showToaster({ messageId: 'adminUserDeleted', toasterType: 'success' })

                let requestData = { id };
                socketNotification('webAdminUserLogout', JSON.stringify(requestData));

            } else {
                showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: data?.deleteAdminUser?.errorMessage })

                await dispatch({
                    type: DELETE_ADMIN_USER_ERROR,
                    payload: {
                        deleteAdminUserLoading: false,
                        error: data?.deleteAdminUser?.errorMessage
                    }
                });
            }
        } catch (error) {
            await dispatch({
                type: DELETE_ADMIN_USER_ERROR,
                payload: {
                    deleteAdminUserLoading: false,
                    error
                }
            });
        }
    }
}

const getAdminUser = () => {
    return async (dispatch, getState, { client }) => {
        let adminPrivileges, privileges, defaultPrivileges;

        const privilegesData = await client.query({
            query: getPrivilegesQuery
        });

        privileges = privilegesData?.data?.getPrivileges?.results;

        defaultPrivileges = privileges?.length > 0 && privileges?.map((item) => item?.id);

        try {
            await dispatch({
                type: GET_ADMIN_USER_START,
                payload: {
                    getAdminUserLoading: true
                }
            });

            const { data } = await client.query({
                query: getAdminUserQuery
            });

            if (data?.getAdminUser?.id) {
                dispatch(setRuntimeVariable({
                    name: 'isSuperAdmin',
                    value: data?.getAdminUser?.isSuperAdmin
                }));

                adminPrivileges = {
                    id: data?.getAdminUser?.id,
                    email: data?.getAdminUser?.email,
                    isSuperAdmin: data?.getAdminUser?.isSuperAdmin,
                    roleId: data?.getAdminUser?.roleId,
                    privileges: (data?.getAdminUser?.adminRole?.privileges) || []
                };

                if (adminPrivileges?.isSuperAdmin) {
                    adminPrivileges['privileges'] = defaultPrivileges;
                }

                await dispatch({
                    type: GET_ADMIN_USER_SUCCESS,
                    payload: {
                        getAdminUserLoading: false,
                        adminPrivileges
                    }
                });

                return adminPrivileges;
            } else {
                await dispatch({
                    type: GET_ADMIN_USER_SUCCESS,
                    payload: {
                        getAdminUserLoading: false,
                        error: data?.getAdminUser?.errorMessage
                    }
                });
                return false;
            }
        } catch (error) {
            await dispatch({
                type: GET_ADMIN_USER_ERROR,
                payload: {
                    getAdminUserLoading: false,
                    error
                }
            });
            return false;
        }
    }
};

export { createAdminUser, deleteAdminUser, getAdminUser }
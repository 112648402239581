import showToaster from '../../../helpers/showToaster';
import { deleteOrganization as mutation } from '../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import {
    UPDATE_ORGANIZATION_START,
    UPDATE_ORGANIZATION_SUCCESS,
    UPDATE_ORGANIZATION_ERROR
} from '../../../constants/index';

const deleteOrganization = (id) => {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: UPDATE_ORGANIZATION_START,
            });
            
            dispatch(setLoaderStart('deleteOrganize'));

            const { data } = await client.mutate({
                mutation,
                variables:{
                    id
                }
            });

            dispatch({
                type: data?.deleteOrganization?.status != 200 ? UPDATE_ORGANIZATION_SUCCESS : UPDATE_ORGANIZATION_ERROR,
            });

            if (data?.deleteOrganization?.status == 200) {
                showToaster({ messageId: 'updatedOrganizationSuccess', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.deleteOrganization?.errorMessage })
            }
            dispatch(setLoaderComplete('deleteOrganize'));

        } catch (error) {
            dispatch({
                type: UPDATE_ORGANIZATION_ERROR,
            });
            dispatch(setLoaderComplete('deleteOrganize'));

        }
    }

}

export default deleteOrganization;

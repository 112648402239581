import showToaster from '../../../helpers/showToaster';
import { getAllModifier as query, deleteModifier as mutation } from '../../lib/graphql';
import { getAllShopModifier } from './getAllShopModifier';
import {
	SHOP_MODIFIER_UPDATE_START,
	SHOP_MODIFIER_UPDATE_SUCCESS,
	SHOP_MODIFIER_UPDATE_ERROR
} from '../../../constants/index';

export default function deleteModifier(id, currentPage, searchList) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: SHOP_MODIFIER_UPDATE_START,
			payload: {
				updateLoading: true
			}
		});

		try {

			const { data: { deleteModifier } } = await client.mutate({
				mutation,
				variables: {
					id
				},
				refetchQueries: [{ query, variables: { currentPage, searchList } }]
			});

			if (deleteModifier?.status === 200) {

				dispatch({
					type: SHOP_MODIFIER_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'modifierDeleted', toasterType: 'success' })
			} else {

				dispatch({
					type: SHOP_MODIFIER_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: deleteModifier?.errorMessage })
			}
			dispatch(getAllShopModifier());

		} catch (err) {

			showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: err })
			dispatch({
				type: SHOP_MODIFIER_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};

import showToaster from '../../../helpers/showToaster';
import { updateFoodChoiceStatus as mutation } from '../../lib/graphql';
import {
    UPDATE_FOOD_CHOICE_START,
    UPDATE_FOOD_CHOICE_SUCCESS,
    UPDATE_FOOD_CHOICE_ERROR
} from '../../../constants/index';

const updateFoodChoiceStatus = (id, isEnable) => {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: UPDATE_FOOD_CHOICE_START,
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    isEnable
                }
            });

            dispatch({
                type: data?.updateFoodChoiceStatus?.status != 200 ? UPDATE_FOOD_CHOICE_SUCCESS : UPDATE_FOOD_CHOICE_ERROR,
            });

            if (data?.updateFoodChoiceStatus?.status == 200) {
                showToaster({ messageId: 'updatedFoodChoiceSuccess', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateFoodChoiceStatus?.errorMessage })
            }

        } catch (error) {
            dispatch({
                type: UPDATE_FOOD_CHOICE_ERROR,
            });
        }
    }

}

export default updateFoodChoiceStatus;

import React from 'react';
import Button from 'react-bootstrap/Button';
class Logout extends React.Component {
  static defaultProps = {
      actionUrl: '/logout'
  };

  render() {
    const { containerClass, formClass, buttonClass, actionUrl, children, onClick } = this.props;

    return (
      <div className={containerClass}>
        <form action={actionUrl} method="post" className={formClass}>
          <Button type='sumbit' onClick={onClick} variant="link" className={buttonClass}>
            {children}
          </Button>
        </form>
      </div>
    );
  }

}

export default Logout;

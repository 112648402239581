import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import {
    Form,
    Col,
    Row,
    FormControl,
    FormGroup,
    Container
} from 'react-bootstrap';

import ImageUploadComponent from '../../../ImageUploadComponent/ImageUploadComponent';
import Loader from '../../../Common/Loader/Loader';

import messages from '../../../../locale/messages';
import validate from './validate';
import { api, partnerHomepageUploadDir } from '../../../../config';
import submit from './submit';

import s from './InfoForm.css';
export class InfoForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }


    render() {
        const { handleSubmit, infoImage1, infoImage2, infoImage3, loading, submitting, infoImage1Loader, infoImage2Loader, infoImage3Loader } = this.props;
        const { formatMessage } = this.props.intl;
        return (

            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col xl={10} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <h1 className={s.titleTextAdmin}>{formatMessage(messages.infoSection)}</h1>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="infoTitle4" type="text" component={this.renderField} label={formatMessage(messages.title1Label)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="infoContent4" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.description1Label)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                    <ImageUploadComponent
                                                            className={cx(s.btnSecondary, s.profileNoPadding)}
                                                            subTextClass={s.subText}
                                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                            defaultMessage={formatMessage(messages.chooseFile)}
                                                            loaderName={'infoImage1Loader'}
                                                            postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                                            loader={infoImage1Loader}
                                                            fieldName={'infoImage1'}
                                                            formName={'InfoForm'}
                                                            imageSrc={api.apiEndpoint + partnerHomepageUploadDir+ 'medium_' + infoImage1}
                                                            inputContainer={'.dzInputContainerRestaurantImage'}
                                                            inputContainerClass={'dzInputContainerRestaurantImage'}
                                                            label={formatMessage(messages.image1Label)}
                                                            isDefaultImg={infoImage1 ? false : true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <Field name="infoTitle1" type="text" component={this.renderField} label={formatMessage(messages.imageTitle1Label)} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                    <ImageUploadComponent
                                                            className={cx(s.btnSecondary, s.profileNoPadding)}
                                                            subTextClass={s.subText}
                                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                            defaultMessage={formatMessage(messages.chooseFile)}
                                                            loaderName={'infoImage2Loader'}
                                                            postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                                            loader={infoImage2Loader}
                                                            fieldName={'infoImage2'}
                                                            formName={'InfoForm'}
                                                            imageSrc={api.apiEndpoint + partnerHomepageUploadDir+ 'medium_' + infoImage2}
                                                            inputContainer={'.dzInputContainerRestaurantImage'}
                                                            inputContainerClass={'dzInputContainerRestaurantImage'}
                                                            label={formatMessage(messages.image2Label)}
                                                            isDefaultImg={infoImage2 ? false : true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="infoTitle2" type="text" component={this.renderField} label={formatMessage(messages.imageTitle2Label)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                    <ImageUploadComponent
                                                            className={cx(s.btnSecondary, s.profileNoPadding)}
                                                            subTextClass={s.subText}
                                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                            defaultMessage={formatMessage(messages.chooseFile)}
                                                            loaderName={'infoImage3Loader'}
                                                            postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                                            loader={infoImage3Loader}
                                                            fieldName={'infoImage3'}
                                                            formName={'InfoForm'}
                                                            imageSrc={api.apiEndpoint + partnerHomepageUploadDir+ 'medium_' + infoImage3}
                                                            inputContainer={'.dzInputContainerRestaurantImage'}
                                                            inputContainerClass={'dzInputContainerRestaurantImage'}
                                                            label={formatMessage(messages.image3Label)}
                                                            isDefaultImg={infoImage3 ? false : true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="infoTitle3" type="text" component={this.renderField} label={formatMessage(messages.imageTitle3Label)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                                <Form.Group className={s.noMargin}>
                                                    <div className={s.displayInlineBlock}>
                                                        <Loader
                                                            type={"button"}
                                                            label={formatMessage(messages.submitButton)}
                                                            show={loading}
                                                            buttonType={'submit'}
                                                            className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                                                            disabled={submitting || loading}
                                                            isSuffix={true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

InfoForm = reduxForm({
    form: 'InfoForm',
    onSubmit: submit,
    validate
})(InfoForm);

const selector = formValueSelector('InfoForm')

const mapState = (state) => ({
    infoImage1: selector(state, 'infoImage1'),
    infoImage2: selector(state, 'infoImage2'),
    infoImage3: selector(state, 'infoImage3'),
    loading: state.loader.UpdatePartnerHomepage,
    infoImage1Loader: state.loader.infoImage1Loader,
    infoImage2Loader: state.loader.infoImage2Loader,
    infoImage3Loader: state.loader.infoImage3Loader
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(InfoForm)));
import showToaster from '../../../helpers/showToaster';
import { getPayouts as query } from '../../lib/graphql';
import {
    GET_PAYOUT_START,
    GET_PAYOUT_SUCCESS,
    GET_PAYOUT_ERROR,
} from '../../../constants';

export function getPayouts(currentAccountId, country) {

    return async (dispatch, getState, { client }) => {

        await dispatch({
            type: GET_PAYOUT_START,
            payload: {
                getPayoutLoading: true
            }
        });

        try {

            const { data } = await client.query({
                query,
                variables: {
                    currentAccountId,
                    country
                },
                fetchPolicy: 'network-only'
            });

            if (data?.getPayouts?.length >= 0) {

                await dispatch({
                    type: GET_PAYOUT_SUCCESS,
                    payload: {
                        payoutData: data?.getPayouts,
                        getPayoutLoading: false
                    }
                });

            } else {

                await dispatch({
                    type: GET_PAYOUT_ERROR,
                    payload: {
                        error: showToaster({ messageId: 'noRecord', toasterType: 'error' }),
                        getPayoutLoading: false
                    }
                });
            }

        } catch (error) {

            await dispatch({
                type: GET_PAYOUT_ERROR,
                payload: {
                    error,
                    getPayoutLoading: false
                }
            });
            return false;
        }

        return true;
    };
};

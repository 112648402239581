import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
// style
import s from './EditRestaurant.css';
// graphql
import getShop from './getShop.graphql';
import getCuisineCategories from './getCuisineCategories.graphql';
import getCommonSettingQuery from './getCommonSettingQuery.graphql';
// components
import EditRestaurantForm from '../../../../components/SiteAdmin/ManageShop/EditRestaurantForm';
import Loader from '../../../../components/Common/Loader';

export class EditRestaurant extends Component {

    static defaultProps = {
        data: {
            loading: true
        },
        settingData: {
            loading: false
        }
    };

    render() {
        const { data: { loading, getShop }, getCuisine: { getActiveCategories } } = this.props;
        const { settingData: { getAllCommonSettingsData, loading: settingLoading } } = this.props;
        let initialValues = {};

        let phoneStatus = getShop && getShop.userVerifiedInfo && getShop.userVerifiedInfo.isPhoneVerified;

        if (!loading && getShop) {
            initialValues = {
                id: getShop.id,
                email: getShop.email,
                phoneDialCode: getShop.phoneDialCode,
                phoneCountryCode: getShop.phoneCountryCode,
                phoneNumber: getShop.phoneNumber,
                profileId: getShop.profile && getShop.profile.profileId,
                shopName: getShop.profile && getShop.profile.shopName,
                firstName: getShop.profile && getShop.profile.firstName,
                lastName: getShop.profile && getShop.profile.lastName,
                country: getShop.profile && getShop.profile.country,
                address: getShop.profile && getShop.profile.address,
                zipcode: getShop.profile && getShop.profile.zipcode,
                picture: getShop.profile && getShop.profile.picture,
                description: getShop.profile && getShop.profile.description,
                priceRange: getShop.profile && getShop.profile.priceRangeId,
                categoryType: getShop && getShop.shopCategory.map((item) => item.categoryId),
                preferredCurrency: getShop.profile && getShop.profile.preferredCurrency,
                shopEstimatedTime: getShop.profile && getShop.profile.shopEstimatedTime,
                dietaryType: getShop.profile && getShop.profile.dietaryType,
                isActive: getShop.isActive,
                userStatus: getShop.userStatus,
                isBan: getShop.isBan,
                isTakeAway: getShop.profile && getShop.profile.isTakeAway,
                isDoorDelivery: getShop.profile && getShop.profile.isDoorDelivery,
                businessId: getShop?.profile?.businessId,
                healthMinistryId: getShop?.profile?.healthMinistryId,
            }
        };

        return (
            <Loader type={"page"} show={loading}>
                <div className={s.root}>
                    <div className={s.container}>
                        {
                            !loading && initialValues && <EditRestaurantForm
                                initialValues={initialValues}
                                getCuisine={getActiveCategories}
                                getDietary={!settingLoading && getAllCommonSettingsData && getAllCommonSettingsData.results && getAllCommonSettingsData.results.length > 0 && getAllCommonSettingsData.results[0]}
                                phoneStatus={phoneStatus}
                                getShop={getShop}
                            />
                        }
                    </div>
                </div>
            </Loader>
        );
    }
}

export default compose(withStyles(s),
    graphql(getShop, {
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only',
            ssr: false
        })
    }),
    graphql(getCommonSettingQuery, {
        name: 'settingData',
        options: (props) => ({
            variables: {
                typeId: 1
            },
            fetchPolicy: 'network-only',
            ssr: false
        })
    }),
    graphql(getCuisineCategories, {
        name: 'getCuisine',
        options: {
            fetchPolicy: 'network-only',
            ssr: false
        }
    })
)(EditRestaurant)
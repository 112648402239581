import updateSiteSettings from '../../../actions/siteadmin/updateSiteSettings'
import showToaster from '../../../helpers/showToaster';

async function submit(values, dispatch) {

    if(!values.ogImage){
       return showToaster({messageId:'invalidOgImg', toasterType: 'error'})
    }

    await dispatch(
        updateSiteSettings(
            values.siteName,
            values.siteTitle,
            values.metaDescription,
            values.facebookLink,
            values.twitterLink,
            values.instagramLink,
            values.logoHeight,
            values.logoWidth,
            values.metaKeyword,
            values.homeLogo,
            values.youtubeLink,
            values.currency,
            values.distanceUnits,
            values.appForceUpdate,
            values.eaterAndroidVersion,
            values.eaterIosVersion,
            values.driverAndroidVersion,
            values.driverIosVersion,
            values.preferredDelivery,
            values.favicon,
            values.contactNumber,
            values.contactEmail,
            values.contactSkype,
            (Number(values.notificationInterval)).toString(),
            values.requestTimeTone,
            values.requestToneFile,
            values.orderAcceptInterval,
            values.ogImage
        )
    )
    // await dispatch(siteSettings())
}

export default submit;
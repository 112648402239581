import showToaster from '../../helpers/showToaster';
import { uploadLicenceFrontImageMutation as mutation } from '../lib/graphql';
import { removeImage } from '../../helpers/removeImage';
import {
  LICENCE_IMAGE_UPLOAD_ERROR,
  LICENCE_IMAGE_UPLOAD_START,
  LICENCE_IMAGE_UPLOAD_SUCCESS
} from '../../constants/index'

const uploadLicenceFrontImage = (id, licenceFront, oldImageFront) => {
  return async (dispatch, getState, { client }) => {
    try {
      let url = "/deleteLicenceImage";

      dispatch({
        type: LICENCE_IMAGE_UPLOAD_START
      })
      const { data } = await client.mutate({
        mutation,
        variables: {
          userId: id,
          licenceFront
        }
      });

      if (oldImageFront !== undefined) {
        removeImage(url, oldImageFront)
      };

      if (data?.uploadLicenceImage?.status == "200") {
        dispatch({
          type: LICENCE_IMAGE_UPLOAD_SUCCESS
        });
        showToaster({ messageId: 'licenseImageUploaded', toasterType: 'success' });
      } else {
        dispatch({
          type: LICENCE_IMAGE_UPLOAD_ERROR
        });
      }

    } catch (err) {
      dispatch({
        type: LICENCE_IMAGE_UPLOAD_ERROR
      });
    }
  };
};

export default uploadLicenceFrontImage;
import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';

import ViewChatDetails from '../../../components/SiteAdmin/ViewChatDetails';
import Loader from '../../../components/Common/Loader/Loader';
import NotFound from '../../not-found/NotFound';

import getAllThreadItemsQuery from './getAllThreadItems.graphql';
import getMoreThreads from './getMoreThreads.graphql';

import s from './ViewChatting.css';

class ViewChatting extends Component {

    loadMore = () => {
        const { threadItems: { getAllThreadItems, fetchMore }, bookingId } = this.props;
        fetchMore({
            query: getMoreThreads,
            variables: {
                bookingId,
                offset: getAllThreadItems.threadItems.length,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) { return previousResult; }
                return {
                    getAllThreadItems: {
                        ...previousResult.getAllThreadItems,
                        threadItems: [...previousResult.getAllThreadItems.threadItems, ...fetchMoreResult.getMoreThreads.threadItems],
                    },
                };
            },
        });
    }

    render() {
        const { threadItems, from, threadItems: { loading, getAllThreadItems } } = this.props;

        return (
            <Loader type={"page"} show={loading}>
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.paddingRoutesSection}>
                            {
                                !loading && getAllThreadItems.threadItems.length > 0 && ['completed-bookings', 'failed-bookings', 'bookings'].includes(from) ? <ViewChatDetails threadItems={threadItems} from={from} loadMore={this.loadMore} /> : !loading && <NotFound title='Page Not Found' />
                            }
                        </div>
                    </div>
                </div>
            </Loader>
        )
    }
}

export default compose(
    injectIntl,
    withStyles(s),
    graphql(getAllThreadItemsQuery, {
        name: 'threadItems',
        options: (props) => ({
            variables: {
                offset: 0,
                bookingId: props.bookingId
            },
            fetchPolicy: 'network-only',
            ssr: false
        })
    })
)(ViewChatting);
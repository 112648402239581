import showToaster from '../../../helpers/showToaster';
import { updateOrganization as mutation, getOrganizations as query } from '../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { closeOrganizationModal } from '../../modalActions';
import {
    UPDATE_ORGANIZATION_START,
    UPDATE_ORGANIZATION_SUCCESS,
    UPDATE_ORGANIZATION_ERROR
} from '../../../constants/index';

const updateOrganization = (id, itemName, isEnable) => {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch(setLoaderStart('updateOrganizationLoading'));
            dispatch({
                type: UPDATE_ORGANIZATION_START,
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    itemName,
                    isEnable
                },
                refetchQueries: [{ query, variables: { currentPage: 1, searchList: '' } }]
            });

            dispatch({
                type: data?.updateOrganization?.status != 200 ? UPDATE_ORGANIZATION_SUCCESS : UPDATE_ORGANIZATION_ERROR,
            });
            dispatch(closeOrganizationModal());
            dispatch(setLoaderComplete('updateOrganizationLoading'));
            if (data?.updateOrganization?.status == 200) {
                showToaster({ messageId: id ? 'updatedOrganizationSuccess' : 'addedOrganizationSuccess', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateOrganization?.errorMessage })
            }

        } catch (error) {
            dispatch({
                type: UPDATE_ORGANIZATION_ERROR,
            });
            dispatch(setLoaderComplete('updateOrganizationLoading'));
        }
    }

}

export default updateOrganization;

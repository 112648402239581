import showToaster from '../../../../helpers/showToaster';
import { addCategory } from '../../../../actions/siteadmin/Category/addCategory';

async function submit(values, dispatch) {
    if (!values?.categoryImage) {
        showToaster({ messageId: 'cuisineImageRequired', toasterType: 'error' });
        return;
    }

    await dispatch(
        addCategory(
            values?.categoryName,
            values?.isActive,
            values?.categoryImage,
            values?.description
        )
    )
}

export default submit;
import showToaster from '../../../helpers/showToaster';
import { updateConfigSettings as mutation } from '../../lib/graphql';
import { siteSettings } from '../siteSettings';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import {
	UPDATE_CONFIG_SETTINGS_START,
	UPDATE_CONFIG_SETTINGS_SUCCESS,
	UPDATE_CONFIG_SETTINGS_ERROR,
} from '../../../constants';

export function updateConfigSettings(values) {
	return async (dispatch, getState, { client }) => {

		dispatch({
			type: UPDATE_CONFIG_SETTINGS_START,
		});
		dispatch(setLoaderStart('configSettings'));

		try {
			const { data } = await client.mutate({
				mutation,
				variables: values,
			})
			dispatch(setLoaderComplete('configSettings'));

			if (data?.updateConfigSettings?.status == 200) {
				dispatch({ type: UPDATE_CONFIG_SETTINGS_SUCCESS });
				showToaster({ messageId: 'configUpdated', toasterType: 'success' })
				await dispatch(siteSettings());
			}
			else {
				dispatch({ type: UPDATE_CONFIG_SETTINGS_ERROR });
				showToaster({ messageId: 'somethingWentWrong', toasterType: 'success', requestContent: data?.updateConfigSettings?.errorMessage })
			}
		}
		catch (error) {

			dispatch({ type: UPDATE_CONFIG_SETTINGS_ERROR });
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
		}
	}
};

import showToaster from '../../helpers/showToaster';
import { getAllCancelReason as query, removeCancelReason as mutation } from '../lib/graphql';
import {
    CANCEL_REASON_DELETE_SUCCESS,
    CANCEL_REASON_DELETE_START,
    CANCEL_REASON_DELETE_ERROR
} from '../../constants/index';

const removeCancelReason = (id, currentPage) => {
    return async (dispatch, getState, { client }) => {
        try {
            dispatch({
                type: CANCEL_REASON_DELETE_START
            });
            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                },
                refetchQueries: [{ query, variables: { currentPage, searchList: '' } }]
            });

            if (data?.removeCancelReason?.status == "200") {
                dispatch({
                    type: CANCEL_REASON_DELETE_SUCCESS
                });
                showToaster({ messageId: 'reasonDeleted', toasterType: 'success' });
            } else {
                dispatch({
                    type: CANCEL_REASON_DELETE_ERROR
                });
                showToaster({ messageId: 'reasonDeletionFailed', toasterType: 'error' });
            }
        } catch (err) {
            dispatch({
                type: CANCEL_REASON_DELETE_ERROR
            });
            showToaster({ messageId: 'reasonDeletionFailed', toasterType: 'error' });
        }
    };
};

export default removeCancelReason;

import messages from '../../../locale/messages';
import { inputTextLimit } from '../../../helpers/inputTextLimit';

const validate = values => {
    const errors = {}

    if (!values.message) {
        errors.message = messages.messageRequired;
    } else if (values.message && values.message.toString().trim() === '') {
        errors.message = messages.messageRequired;
    } else if (values.message && values.message.length > inputTextLimit) {
        errors.message = messages.textAreaError;
    }

    return errors
}

export default validate;
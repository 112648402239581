import moment from 'moment';
import showToaster from '../../../../../helpers/showToaster';
import addItem from '../../../../../actions/shop/menu/addItem';

async function submit(values, dispatch) {
     let modifiers, tax, variables, foodChoices, itemAvailableDate;
     values.itemAvailability = values?.itemAvailability ? values?.itemAvailability : "notRepeat";
     values.isEnable = (values?.isEnable == false || values?.isEnable == "false") ? false : true;
     foodChoices = values?.foodChoices?.length > 0 ? values?.foodChoices?.map((item) => Number(item?.id)) : []
     modifiers = JSON.stringify(values.modifiers);
     itemAvailableDate = values?.itemAvailableDate && moment(values?.itemAvailableDate).startOf('days')
     tax = values?.tax;
     if (!values?.tax || (values?.tax && values?.tax?.toString().trim() === '')) tax = null;
     variables = Object.assign({}, values, {
          modifiers
     }, {tax}, { foodChoices, itemAvailableDate });

     if (!values.image) showToaster({ messageId: 'uploadImage', toasterType: 'error' })

     else await dispatch(addItem(variables));
}

export default submit;
import fetch from 'node-fetch';
import showToaster from '../../../../helpers/showToaster';
import history from '../../../../history';
import { addContentPageDetails as mutation } from '../../../../actions/lib/graphql';

async function submit(values, dispatch) {
  if (values?.content == null || values?.content == '<p><br></p>' || values?.content == '<p> </p>') {
    showToaster({ messageId: 'addContent', toasterType: 'error' })
  } else {
    const response = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: mutation,
        variables: values
      }),
      credentials: 'include'
    });

    const { data } = await response.json();

    if (data?.addContentPageDetails?.status == 200) {
      showToaster({ messageId: 'changesAdded', toasterType: 'success' });
      history.push('/siteadmin/contentpage/manage')
    } else if (data?.addContentPageDetails?.status == 400) {
      showToaster({ messageId: 'existUrl', toasterType: 'error' });
    } else {
      showToaster({ messageId: 'pageDetailsFailed', toasterType: 'error' });
    }
  }
};

export default submit;
import gql from "graphql-tag";

export const uploadShopDocument = gql`
mutation ($documentList: String) {
    uploadShopDocument(documentList:$documentList) {
        status
        errorMessage                     
    }
}`;

export const sendForgotPasswordLink = gql`
mutation sendForgotPasswordLink($email: String!) {
    sendForgotPasswordLink (email: $email) {
        status
        errorMessage
    }
}`;

export const updateForgotPassword = gql`
mutation updateForgotPassword($email: String!, $token: String!, $newPassword: String!) {
    updateForgotPassword (email:$email, token: $token, newPassword: $newPassword) {
        status
        errorMessage
    }
}`;

export const checkShopUser = `query ($email: String!){
    checkShopUser (email: $email){
        status
        errorMessage
    }
}`;

export const shopUserLogin = `query (
    $email: String!,
    $password: String!,
    $deviceId: String 
) {
    shopUserLogin (
        email: $email,
        password: $password,
        deviceId: $deviceId
    ) {
        status
        errorMessage
        result {
            id
            email
        }
    }
}`;

export const shopUserLogout = gql`
query {
    shopUserLogout {
        status
    }
}`;

export const updateMenu = gql`
mutation ($operationHours: String, $menuName: String, $id: Int, $isEnable: Boolean){
    updateMenu(operationHours: $operationHours, menuName: $menuName, id: $id, isEnable: $isEnable){
        status
        errorMessage
    }
}`;

export const addMenuDetails = gql`mutation ($operationHours: String, $menuName: String, $isEnable: Boolean){
    addMenu(operationHours: $operationHours, menuName: $menuName, isEnable: $isEnable){
        status
        errorMessage
    }
}`;

export const getAllSubMenu = gql`
query($currentPage: Int, $searchList: String, $menuId: Int){
    getAllSubMenu(currentPage: $currentPage, searchList: $searchList, menuId: $menuId){
      status
      errorMessage
      menuName
      count
      results {
        id
        shopId
        menuId
        name
        isEnable
        item {
            id
            subMenuId
            menuId
            itemName
            itemDescription
            price
            tax
            image
            itemType
            isEnable
        }
      }
    }  
}`;

export const updateSubMenu = gql`mutation ($id: Int, $menuId: Int, $name: String, $isEnable: String){
    updateSubMenu(id: $id, menuId: $menuId, name: $name, isEnable: $isEnable){
        status
        errorMessage
    }
}`;

export const addSubMenuDetails = gql`mutation ($menuId: Int, $name: String, $isEnable: String){
    addSubMenu(menuId: $menuId, name: $name, isEnable: $isEnable){
        status
        errorMessage
    }
}`;

export const deleteItem = gql`mutation deleteItem($id: Int) {
    deleteItem(id: $id) {
      status
      errorMessage
    }
}`;

export const getAllMenu = gql`query($currentPage: Int, $searchList: String) {
	getAllMenu(currentPage: $currentPage, searchList: $searchList){
	  count
	  status
	  errorMessage
	  results{
	   id
	   shopId
	   menuName
	   isEnable
	   menuAvailability{
		menuId
		operationDay
		isOpen
		isWholeDay
	   }
	  }
	}
  }`;

export const deleteMenu = gql`mutation deleteMenu($id: Int) {
    deleteMenu(id: $id) {
      status
      errorMessage
    }
}`;

export const deleteSubMenu = gql`mutation deleteSubMenu($id: Int) {
    deleteSubMenu(id: $id) {
      status
      errorMessage
    }
}`;

export const getSubMenu = gql`query getSubMenu($id: Int!){
	getSubMenu( id: $id){
	   id
	   menuId
	   name
	   isEnable
	}
}`;

export const itemAvailable = gql`
{
    itemAvailable {
        status
        errorMessage
    }
}`;

export const updateItemStatus = gql`mutation updateItemStatus($id: Int, $status: Boolean) {
    updateItemStatus(id: $id, status:$status) {
      status
      errorMessage
    }
}`;

export const updateMenuStatusData = gql`mutation updateMenuStatus($id: Int, $status: Boolean) {
    updateMenuStatus(id: $id, status:$status) {
      status
      errorMessage
    }
}`;

export const updateSubMenuStatus = gql`mutation updateSubMenuStatus($id: Int, $status: Boolean) {
    updateSubMenuStatus(id: $id, status:$status) {
      status
      errorMessage
    }
}`;

export const createShopUser = gql`
mutation (
  $shopName: String!
  $firstName: String!,
  $lastName: String!,
  $email: String!,
  $password: String!,
  $phoneDialCode: String!,
  $phoneNumber: String!,
  $phoneCountryCode: String!,
  $address: String!,
  $lat: Float!,
  $lng: Float!,
  $city: String,
  $state:String,
  $zipcode: String,
  $country: String,
  $categoryType: Int!,
  $businessId: String,
  $healthMinistryId: String,
  $deviceId: String
) {
  createShopUser (
      shopName: $shopName,
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      password: $password,
      phoneDialCode: $phoneDialCode,
      phoneNumber: $phoneNumber,
      phoneCountryCode: $phoneCountryCode,
      address: $address,
      lat: $lat,
      lng: $lng,
      city: $city,
      state: $state,
      zipcode: $zipcode,
      country: $country,
      categoryType: $categoryType,
      businessId: $businessId,
      healthMinistryId: $healthMinistryId,
      deviceId: $deviceId
) {
      status
      errorMessage
      result {
          id
          email
    }
}
}`;

export const updatePreparationTime = gql`mutation (
  $shopEstimatedTime: Int,
  $preparationTime: String
) {
    updatePreparationTime (
      shopEstimatedTime: $shopEstimatedTime,
      preparationTime: $preparationTime
    ) {
        status
        errorMessage
    }
}`;

export const verifyOtpCode = gql`mutation verifyOtpCode($otp: Int!) {
        verifyOtpCode(otp: $otp){
            status
            errorMessage
        }
    }
`;

export const updatePhoneNumber = gql`mutation updatePhoneNumber($phoneNumber: String!, $phoneDialCode: String!) {
        updatePhoneNumber(phoneNumber: $phoneNumber, phoneDialCode: $phoneDialCode) {
            status
            errorMessage
        }
    }
`;

export const shopUserActivityChange = gql`mutation ($isActive: Boolean) {
    shopUserActivityChange (isActive: $isActive) {
        status
        errorMessage
    }
  }
`;

export const getOperationHours = gql`query getOperationHours {
    getOperationHours{
        results{
            id
            shopId
            operationDay,
            isOpen,
            isAllHours
            startTime,
            endTime
        }
    }
}`;

export const updatePartnerOperatingHours = gql`mutation ($operationHours: String){
    updatePartnerOperatingHours(operationHours: $operationHours){
        status
        errorMessage
    }
}`;

export const deleteShopMutation = gql`
mutation deleteShop($id: String!) {
    deleteShop(id: $id) {
      status
      errorMessage
    }
}`;

export const updateShopMutation = gql`
mutation (
    $id: ID,
    $shopName: String
    $firstName: String,
    $lastName: String,
    $email: String,
    $phoneDialCode: String,
    $phoneNumber: String,
    $phoneCountryCode: String,
    $address: String,
    $lat: Float,
    $lng: Float,
    $city: String,
    $state:String,
    $zipcode: String,
    $country: String,
    $categoryType: [Int],
    $description: String,
    $priceRangeId: Int,
    $picture: String,
    $preferredCurrency: String,
    $dietaryType: Int,
    $shopEstimatedTime: Int,
    $password: String,
    $userStatus: String,
    $isBan: Boolean,
    $isTakeAway: Boolean,
    $isDoorDelivery: Boolean,
    $businessId: String,
    $healthMinistryId: String,
) {
    updateShop (
        id: $id,
        shopName: $shopName,
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        phoneDialCode: $phoneDialCode,
        phoneNumber: $phoneNumber,
        phoneCountryCode: $phoneCountryCode,
        address: $address,
        lat: $lat,
        lng: $lng,
        city: $city,
        state: $state,
        zipcode: $zipcode,
        country: $country,
        categoryType: $categoryType,
        description: $description,
        priceRangeId: $priceRangeId,
        picture: $picture,
        preferredCurrency: $preferredCurrency,
        dietaryType: $dietaryType,
        shopEstimatedTime: $shopEstimatedTime,
        password: $password,
        userStatus: $userStatus,
        isBan: $isBan,
        isTakeAway: $isTakeAway,
        isDoorDelivery: $isDoorDelivery,
        businessId: $businessId,
        healthMinistryId: $healthMinistryId,
    ) {
        status
        errorMessage   
    }
}`;

export const updateShopStatusMutation = gql`
mutation updateShopStatus($id: ID, $updateField: String, $status: String) {
    updateShopStatus(id: $id, updateField:$updateField, status:$status) {
      status
      errorMessage
    }
  }
`;

export const uploadDocumentMutation = gql`
mutation (
  $documentList: String
) {
    uploadDocument (
        documentList:$documentList
    ) {
        status
        errorMessage                     
    }
}`;

export const updateShopProfileMutation = gql`
mutation (
  $shopName: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $phoneDialCode: String,
  $phoneNumber: String,
  $phoneCountryCode: String,
  $address: String,
  $lat: Float,
  $lng: Float,
  $city: String,
  $state:String,
  $zipcode: String,
  $country: String,
  $categoryType: [Int],
  $description: String,
  $priceRangeId: Int,
  $picture: String,
  $preferredCurrency: String,
  $dietaryType: Int,
  $shopEstimatedTime: Int,
  $isTakeAway: Boolean,
  $businessId: String,
  $healthMinistryId: String,
  $isDoorDelivery: Boolean
) {
  updateShopProfile (
      shopName: $shopName,
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      phoneDialCode: $phoneDialCode,
      phoneNumber: $phoneNumber,
      phoneCountryCode: $phoneCountryCode,
      address: $address,
      lat: $lat,
      lng: $lng,
      city: $city,
      state: $state,
      zipcode: $zipcode,
      country: $country,
      categoryType: $categoryType,
      description: $description,
      priceRangeId: $priceRangeId,
      picture: $picture,
      preferredCurrency: $preferredCurrency,
      dietaryType: $dietaryType,
      shopEstimatedTime: $shopEstimatedTime,
      isTakeAway: $isTakeAway,
      isDoorDelivery: $isDoorDelivery,
      businessId: $businessId,
      healthMinistryId: $healthMinistryId,
  ) {
      status
      errorMessage                     
  }
}`;

export const updateItemMutation = gql`
  mutation(
    $id: Int
    $menuId: Int
    $subMenuId: Int
    $itemName: String
    $itemDescription: String
    $price: Float
    $tax: Float
    $itemType: Int
    $image: String
    $isEnable: String
    $modifiers: String
    $itemQuantity: Int
    $itemAvailableDate: String
    $itemAvailability: String
    $foodChoices: [Int]
  ) {
    updateItem(
      id: $id
      menuId: $menuId
      subMenuId: $subMenuId
      itemName: $itemName
      itemDescription: $itemDescription
      price: $price
      tax: $tax
      itemType: $itemType
      image: $image
      isEnable: $isEnable
      modifiers: $modifiers
      itemQuantity: $itemQuantity
      itemAvailableDate: $itemAvailableDate
      itemAvailability: $itemAvailability
      foodChoices: $foodChoices
    ) {
      status
      errorMessage
    }
  }
`;

export const getItemQuery = gql`query getItem($id: Int!){
	getItem( id: $id){
	   id
	   menuId
	   itemName
	   itemDescription
	   price
	   tax
	   image
	   itemType
	   isEnable
	   subMenuId
       itemQuantity
       itemAvailableDate
       itemAvailability
       foodChoices{
       id
       itemName
       }
	   modifiers{
		   id
		   itemId
		   modifierGroupId
		   modifierGroups{
				id
				modifierName
				modifierType
				minModifierItems
				maxModifierItems
				isModifierRequired
				isModifierMultiple
				maxItemCount
				isActive
				itemId
				clonedBy
				modifierCommonId
				modifierItems{
					id
					modifierGroupId
					modifierItemName
					modifierItemPrice
				}
			}
		}
	}
  }`;
import showToaster from '../../../helpers/showToaster';
import histroy from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { updateCategoryMutation as mutation } from '../../lib/graphql';

export function editCategory(id, categoryName, isActive, description) {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch(setLoaderStart('EditCategory'));

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          categoryName,
          description,
          isActive: (isActive === true || isActive === 'true') ? 1 : 0
        }
      });

      dispatch(setLoaderComplete('EditCategory'));

      if (data?.updateCategory?.status === 200) {
        showToaster({ messageId: 'cuisineUpdated', toasterType: 'success' })
        histroy.push('/siteadmin/cuisine');
      } else if (data?.updateCategory?.status !== 200) {
        showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateCategory?.errorMessage })
      } else {
        showToaster({ messageId: 'somethingWentWrong', toasterType: 'error' })
      }

    } catch (error) {
      showToaster({ messageId: 'somethingWentWrong', toasterType: 'error' })
    }
  }
};
import showToaster from '../../../../helpers/showToaster';
import { addPayout } from '../../../../actions/shop/payout/addPayout';

async function submit(values, dispatch) {
	
	if(!values.isEnable){
		showToaster({ messageId: 'featureNotAvailable', toasterType: 'warning' })
		return;
	}

	dispatch(
		addPayout(
			values?.email,
			values?.address1,
			values?.address2,
			values?.city,
			values?.state,
			values?.country,
			values?.zipcode,
			values?.currency,
			values?.firstname,
			values?.lastname,
			values?.accountNumber,
			values?.routingNumber,
			values?.ssn4Digits,
			values?.businessType,
			values?.bankCode,
			values?.branchCode,
			values?.bankName,
			values?.branchName,
			values?.accountOwnerName,
			values?.transitNumber,
			values?.institutionNumber,
			values?.bsbNumber,
			values?.sortCode,
			values?.accountToken,
			values?.personToken
		)
	);

};

export default submit;

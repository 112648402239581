import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SideMenu.css';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { FormattedMessage } from 'react-intl';
import { HiOutlineChevronUp, HiOutlineChevronDown } from 'react-icons/hi'
import cx from 'classnames';
import { connect } from 'react-redux';

import HeaderModal from '../../HeaderModal/HeaderModal';
import Link from '../../Link';

import { formatLocale } from '../../../helpers/formatLocale';
import { validatePrivilege } from '../../../helpers/adminPrivileges';
import history from '../../../history';
import { openHeaderModal } from '../../../actions/siteadmin/modalActions';
import { adminLogout } from '../../../actions/siteadmin/logout';
import messages from '../../../locale/messages';

import DashboardIcon from '../../../../public/Icons/dashboard.svg';
import SiteSettingIcon from '../../../../public/Icons/siteSettings.svg';
import DriversIcon from '../../../../public/Icons/driver.svg';
import CategoriesIcon from '../../../../public/Icons/category.svg';
import BookingsIcon from '../../../../public/Icons/booking.svg';
import PromoCodeIcon from '../../../../public/Icons/promo-code.svg';
import CompletedBooking from '../../../../public/Icons/completedbookings.svg';
import ChangePasswordIcon from '../../../../public/Icons/changePassword.svg';
import CancalBookingIcon from '../../../../public/Icons/cancelledBookings.svg';
import ManageCurrencyIcon from '../../../../public/Icons/CurrencyManage.svg';
import NotificationIcon from '../../../../public/Icons/sendNotifications.svg';
import LogOutIcon from '../../../../public/Icons/logout.svg';
import MainSiteIcon from '../../../../public/Icons/mainSite.svg';
import HomeSettingIcon from '../../../../public/Icons/homePageSettings.svg';
import RightArrowIcon from '../../../../public/Icons/right-arrow.png';
import DietaryIcon from '../../../../public/Icons/dietary.svg';
import serviceFee from '../../../../public/Icons/serviceFee.svg';
import AdminEarningIcon from '../../../../public/Icons/adminEarnings.svg';
import CancelReason from '../../../../public/Icons/cancelReasonAdmin.svg';
import ContentPageManagementIcon from '../../../../public/Icons/contentManagementAdmin.svg';
import EaterIcon from '../../../../public/Icons/eater.svg';
import ManageRestaurantsIcon from '../../../../public/Icons/manageRestaurant.svg';
import ManageStaticContentIcon from '../../../../public/Icons/mangeStaticContent.svg';
import ReviewManage from '../../../../public/Icons/reviewsAdmin.svg';
import ViceleType from '../../../../public/Icons/vehicleType.svg';
import languageIcon from '../../../../public/Icons/Language-white.svg';
import adminIcon from '../../../../public/Icons/Manageadmins.svg';
import managePaymentGatewayIcon from '../../../../public/Icons/managePaymentGatewayIcon.svg';
import siteConfigIcon from '../../../../public/Icons/siteConfiguration.svg';
import foodChoicesIcon from '../../../../public/Icons/foodChoices.svg';
import organization from '../../../../public/Icons/organization.svg';



class SideMenu extends React.Component {

  static defaultProps = {
    isSuperAdmin: false,
    privileges: []
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: 0,
      location: '',
      homepageSettings: false,
      restaurantHomepageSettings: false,
      profileMenu: false
    }
    this.openMenu = this.openMenu.bind(this);
    this.openClose = this.openClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let restaurantSettingsArray = ['/siteadmin/restaurant-home/banner', '/siteadmin/restaurant-home/info', '/siteadmin/restaurant-home/slider']

    this.setState({
      location: history.location.pathname
    })

    if (history && history.location && restaurantSettingsArray.includes(history.location.pathname)) {
      this.setState({
        profileMenu: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    if (prevState.location !== location) {
      this.setState({
        location
      })
    }
  }

  async openMenu() {
    this.setState({
      isOpen: 1,
    })
  }

  async openClose() {
    this.setState({
      isOpen: 0,
    })
  }

  handleChange(e) {
    const { openHeaderModal } = this.props;
    openHeaderModal('languageModal');
  }

  render() {
    const { adminLogout, currentLocale, isSuperAdmin, privileges } = this.props;
    const { location, profileMenu } = this.state;

    let restaurantSettingsArray = ['/siteadmin/restaurant-home/banner', '/siteadmin/restaurant-home/info', '/siteadmin/restaurant-home/slider']
    let homeSettingsArray = ['/siteadmin/homepage-banner', '/siteadmin/homepage-howitworks', '/siteadmin/homepage-eater', '/siteadmin/homepage-cuisine', '/siteadmin/homepage-footersettings'];
    let adminManagementArray = ['/siteadmin/admin-users', '/siteadmin/admin-roles'];

    return (
      <div className={s.sideMenuBg}>
        <div className={s.siteAdminHeader}>
          <span><FormattedMessage {...messages.siteAdmin} /></span>
        </div>
        <div className={cx(s.sideMenuList, 'sideMenu', 'sideMenuScroll')}>
          <div>
            <Link to={''} onClick={() => adminLogout()} className={cx(s.logoutIconMobile, 'd-block d-lg-none', 'logoutIconMobileRTL')}>
              <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                <img src={LogOutIcon} className={cx(s.sideMenuIcon)} />
              </span>
            </Link>
          </div>
          <Navbar expand="lg">
            <div onClick={() => this.openMenu()}>
              <div className="nav-container">
                <div className={cx("button  d-block d-lg-none")} tabIndex="0">
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </div>
              </div>
            </div>
            <div className={cx({ [s.menuOpen]: this.state.isOpen == 1 }, s.mobileMenu)}>
              <div className={cx({ [s.menuClose]: this.state.isOpen == 0 }, s.rightMenuClose, 'd-block d-lg-none')}>
                <div className={cx(s.closeColor, 'closeColorRTL')} onClick={() => this.openClose()} >
                  ×
                </div>
              </div>
              <Nav className="mr-auto">
                <HeaderModal />
                <Link onClick={(e) => this.handleChange(e)}
                  className={cx(s.sideNavitem, s.spaceTop2, s.displayTable, 'd-block d-lg-none', 'sideNavitemRTL')} >
                  <span className={cx(s.languageIcon, s.displayTableCell)}>
                    <img src={languageIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell)}>
                    {formatLocale(currentLocale)}
                  </span>
                </Link>
                <Link to={'/siteadmin'} className={cx(s.sideNavitem, 'sideNavitemRTL', s.displayTable, { [s.activeMenu]: location == '/siteadmin' })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={DashboardIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageDashboard} />
                  </span>
                </Link>

                {validatePrivilege(1, privileges) && <Link to={'/siteadmin/settings/site'} className={cx(s.sideNavitem, 'sideNavitemRTL', s.displayTable, { [s.activeMenu]: location === '/siteadmin/settings/site' })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={SiteSettingIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.siteSettings} />
                  </span>
                </Link>}

                {validatePrivilege(1, privileges) && <Link to={'/siteadmin/settings/config'} className={cx(s.sideNavitem, 'sideNavitemRTL', s.displayTable, { [s.activeMenu]: location === '/siteadmin/settings/config' })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={siteConfigIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageSiteConfig} />
                  </span>
                </Link>}

                {validatePrivilege(2, privileges) && <div>
                  <div id='Homepage' className={cx(
                    { [s.activeMenu]: restaurantSettingsArray.includes(location) })
                  }>
                    <Button
                      variant="link"
                      className={cx(s.button, s.noBorderBtn, s.activeMenu, s.btnWidth, s.sideNavitem, s.btnNoPadding, 'sideNavitemRTL', 'textAlignRightRTL')}
                      onClick={() => {
                        this.setState({
                          restuarantHomepageSettings: !this.state.restuarantHomepageSettings
                        })
                      }
                      }
                    >
                      <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                        <img src={HomeSettingIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                      </span>
                      <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                        <FormattedMessage {...messages.restuarantHomepageSettings} />
                      </span>
                      {
                        this.state.restuarantHomepageSettings &&
                        <div className={cx(s.displayInlineBlock, s.arrowCss)}>
                          <HiOutlineChevronUp className={s.navigationIcon} />
                        </div>
                      }

                      {
                        !this.state.restuarantHomepageSettings && <div className={cx(s.displayInlineBlock, s.arrowCss)}><HiOutlineChevronDown className={s.navigationIcon} /></div>
                      }

                    </Button>
                  </div>
                  <Collapse in={this.state.restuarantHomepageSettings}>
                    <div>
                      <Link to={'/siteadmin/restaurant-home/banner'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/restaurant-home/banner') })} onClick={() => this.openClose()}>
                        <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, 'rightArrowRTL')}>
                          <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                        </span>
                        <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                          <FormattedMessage {...messages.bannerSection} />
                        </span>
                      </Link>

                      <Link to={'/siteadmin/restaurant-home/info'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/restaurant-home/info') })} onClick={() => this.openClose()}>
                        <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, 'rightArrowRTL')}>
                          <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                        </span>
                        <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                          <FormattedMessage {...messages.infoSection} />
                        </span>
                      </Link>
                      <Link to={'/siteadmin/restaurant-home/contact'} className={cx(s.sideNavitem, s.sideNavitemCollapsePadding, { [s.activeMenu]: location.startsWith('/siteadmin/restaurant-home/contact') })} onClick={() => this.openClose()}>
                        <span className={cx(s.displayInlineBlock, s.vtrTop, s.sideNavitemCollapse, s.iconWidth, 'rightArrowRTL')}>
                          <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                        </span>
                        <span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
                          <FormattedMessage {...messages.sliderContact} />
                        </span>
                      </Link>

                      <Link to={'/siteadmin/restaurant-home/slider'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/restaurant-home/slider') })} onClick={() => this.openClose()}>
                        <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, 'rightArrowRTL')}>
                          <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                        </span>
                        <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                          <FormattedMessage {...messages.sliderSection} />
                        </span>
                      </Link>
                    </div>
                  </Collapse>
                </div>
                }
                {validatePrivilege(3, privileges) &&
                  <div>
                    <div id='Homepage' className={cx({ [s.activeMenu]: homeSettingsArray.includes(location) })}>
                      <Button
                        variant="link"
                        className={cx(s.button, s.noPadding, s.noBorderBtn, s.activeMenu, s.btnWidth, s.sideNavitem, s.btnNoPadding, 'textAlignRightRTL')}
                        onClick={() => {
                          this.setState({
                            homepageSettings: !this.state.homepageSettings
                          })
                        }
                        }
                      >
                        <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                          <img src={HomeSettingIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                        </span>
                        <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                          <FormattedMessage {...messages.HomeSiteSettings} />
                        </span>
                        {
                          this.state.homepageSettings &&
                          <div className={cx(s.displayInlineBlock, s.arrowCss)}>
                            <HiOutlineChevronUp className={s.navigationIcon} />
                          </div>
                        }

                        {
                          !this.state.homepageSettings && <div className={cx(s.displayInlineBlock, s.arrowCss)}><HiOutlineChevronDown className={s.navigationIcon} /></div>
                        }
                      </Button>
                    </div>
                    <Collapse in={this.state.homepageSettings}>
                      <div>
                        <Link to={'/siteadmin/homepage-banner'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/homepage-banner') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.homeSectionSettings} />
                          </span>
                        </Link>
                        <Link to={'/siteadmin/homepage-howitworks'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/homepage-howitworks') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.howItWorks} />
                          </span>
                        </Link>
                        <Link to={'/siteadmin/homepage-eater'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/homepage-eater') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.safetySectionSettings} />
                          </span>
                        </Link>
                        <Link to={'/siteadmin/homepage-driverapp'} className={cx(s.sideNavitem, s.sideNavitemCollapsePadding, { [s.activeMenu]: location.startsWith('/siteadmin/homepage-driverapp') })} onClick={() => this.openClose()}>
                          <span className={cx(s.displayInlineBlock, s.vtrTop, s.sideNavitemCollapse, s.iconWidth, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.displayInlineBlock, s.vtrMiddle, s.sideNavitemCollapseText)}>
                            <FormattedMessage {...messages.signupSectionSettings} />
                          </span>
                        </Link>
                        <Link to={'/siteadmin/homepage-cuisine'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/homepage-cuisine') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.categories} />
                          </span>
                        </Link>
                        <Link to={'/siteadmin/homepage-footersettings'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/homepage-footersettings') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.footerSectionSettings} />
                          </span>
                        </Link>
                      </div>
                    </Collapse>
                  </div>
                }
                {
                  isSuperAdmin && <div>
                    <div className={cx(adminManagementArray.includes(location) ? [s.active] : '')}>
                      <Button
                        bsStyle="link"
                        className={cx(s.button, s.noPadding, s.noBorderBtn, s.activeMenu, s.btnWidth, s.sideNavitem, s.btnNoPadding, 'textAlignRightRTL')}
                        onClick={() => this.setState({
                          subAdmin: !this.state.subAdmin
                        })}>
                        <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                          <img src={adminIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                        </span>
                        <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                          <FormattedMessage {...messages.manageAdmin} />
                        </span>
                        {
                          this.state.subAdmin &&
                          <div className={cx(s.displayInlineBlock, s.arrowCss)}>
                            <HiOutlineChevronUp className={s.navigationIcon} />
                          </div>
                        }

                        {
                          !this.state.subAdmin && <div className={cx(s.displayInlineBlock, s.arrowCss)}><HiOutlineChevronDown className={s.navigationIcon} /></div>
                        }
                      </Button>
                    </div>
                    <Collapse in={this.state.subAdmin} className={s.subMenu}>
                      <div>
                        <Link to={'/siteadmin/admin-users'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/admin-users') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.manageAdminUsers} />
                          </span>
                        </Link>
                        <Link to={'/siteadmin/admin-roles'} className={cx(s.sideNavitem, s.displayTable, s.sideNavitemCollapsePadding, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/admin-roles') })} onClick={() => this.openClose()}>
                          <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell, 'rightArrowRTL')}>
                            <img src={RightArrowIcon} className={cx(s.sideMenuIcon, s.sideNavitemCollapseIcon)} />
                          </span>
                          <span className={cx(s.vtrMiddle, s.sideNavitemCollapseText, s.displayTableCell, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.manageAdminRoles} />
                          </span>
                        </Link>
                      </div>
                    </Collapse>
                  </div>
                }
                {validatePrivilege(4, privileges) && <Link to={'/siteadmin/users'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/users') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={EaterIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.ridersMenu} />
                  </span>
                </Link>}
                {validatePrivilege(5, privileges) && <Link to={'/siteadmin/drivers'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/drivers') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={DriversIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.driverMenu} />
                  </span>
                </Link>}
                {validatePrivilege(6, privileges) && <Link to={'/siteadmin/restaurant/list'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/restaurant/list') || location.startsWith('/siteadmin/restaurant/edit') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={ManageRestaurantsIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageRestaurants} />
                  </span>
                </Link>}
                {validatePrivilege(7, privileges) && <Link to={'/siteadmin/manage-service-fee'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/manage-service-fee') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={serviceFee} className={s.sideMenuIcon} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageServiceFee} />
                  </span>
                </Link>}
                {validatePrivilege(8, privileges) && <Link to={'/siteadmin/cuisine'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/cuisine') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={CategoriesIcon} className={s.sideMenuIcon} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.categoryMenu} />
                  </span>
                </Link>}
                {validatePrivilege(23, privileges) && <Link to={'/siteadmin/food-choices'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/food-choices') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={foodChoicesIcon} className={s.sideMenuIcon} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageFoodChoices} />
                  </span>
                </Link>}
                {validatePrivilege(24, privileges) && <Link to={'/siteadmin/organizations'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/organizations') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={organization} className={s.sideMenuIcon} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.organization} />
                  </span>
                </Link>}
                {validatePrivilege(22, privileges) && <Link to={'/siteadmin/payment-gateway'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/payment-gateway') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={managePaymentGatewayIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.managePaymentGateWay} />
                  </span>
                </Link>}
                {validatePrivilege(9, privileges) && <Link to={'/siteadmin/vehicle-type/list'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/vehicle-type') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={ViceleType} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.vehicleTypes} />
                  </span>
                </Link>}
                {validatePrivilege(10, privileges) && <Link to={'/siteadmin/common-settings/1'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/common-settings/1') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={DietaryIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.dietaryType} />
                  </span>
                </Link>}
                {validatePrivilege(11, privileges) && <Link to={'/siteadmin/admin-earnings'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/admin-earnings') || location.startsWith('/siteadmin/view-earning') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={AdminEarningIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.earningsMenu} />
                  </span>
                </Link>}
                {validatePrivilege(12, privileges) && <Link to={'/siteadmin/bookings'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/bookings') || location.startsWith('/siteadmin/view-booking') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={BookingsIcon} className={s.sideMenuIcon} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.bookingsMenu} />
                  </span>
                </Link>}
                {validatePrivilege(13, privileges) && <Link to={'/siteadmin/completed-bookings'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/completed-bookings') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={CompletedBooking} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageCompletedTrips} />
                  </span>
                </Link>}
                {validatePrivilege(14, privileges) && <Link to={'/siteadmin/failed-bookings'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/failed-bookings') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={CancalBookingIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageCancelledTrips} />
                  </span>
                </Link>}
                {validatePrivilege(15, privileges) && <Link to={'/siteadmin/reviews'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/reviews') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={ReviewManage} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.ratings} />
                  </span>
                </Link>}
                {validatePrivilege(16, privileges) && <Link to={'/siteadmin/promo-code/list'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/promo-code') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={PromoCodeIcon} className={s.sideMenuIcon} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.managePromoCode} />
                  </span>
                </Link>}
                {isSuperAdmin && <Link to={'/siteadmin/change/admin'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location == '/siteadmin/change/admin' })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={ChangePasswordIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.changePassword} />
                  </span>
                </Link>}
                {validatePrivilege(17, privileges) && <Link to={'/siteadmin/notifications'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location == '/siteadmin/notifications' })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={NotificationIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageNotifications} />
                  </span>
                </Link>}
                {validatePrivilege(18, privileges) && <Link to={'/siteadmin/currency'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location == '/siteadmin/currency' })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={ManageCurrencyIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageCurrency} />
                  </span>
                </Link>}
                {validatePrivilege(19, privileges) && <Link to={'/siteadmin/cancel-reasons'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/cancel-reasons') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={CancelReason} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.manageCancelReason} />
                  </span>
                </Link>}
                {validatePrivilege(20, privileges) && <Link to={'/siteadmin/staticpage/manage'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/staticpage/manage') || location.startsWith('/siteadmin/staticpage/edit/') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={ManageStaticContentIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.staticpageManagement} />
                  </span>
                </Link>}
                {validatePrivilege(21, privileges) && <Link to={'/siteadmin/contentpage/manage'} className={cx(s.sideNavitem, s.displayTable, 'sideNavitemRTL', { [s.activeMenu]: location.startsWith('/siteadmin/contentpage/manage') || location.startsWith('/siteadmin/contentpage/edit/') || location.startsWith('/siteadmin/contentpage/add') })} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={ContentPageManagementIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.contentPageManagement} />
                  </span>
                </Link>}
                <Link to={'/'} className={cx(s.sideNavitem, s.logOutSection)} onClick={() => this.openClose()}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={MainSiteIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.goToMainSite} />
                  </span>
                </Link>
                <Link to={''} onClick={() => adminLogout()} className={cx(s.sideNavitem, s.logOutSection)}>
                  <span className={cx(s.vtrTop, s.iconWidth, s.displayTableCell)}>
                    <img src={LogOutIcon} className={cx(s.sideMenuIcon, s.noFilter)} />
                  </span>
                  <span className={cx(s.vtrMiddle, s.displayTableCell, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.logout} />
                  </span>
                </Link>
              </Nav>
            </div>
          </Navbar>
        </div >
      </div >
    );
  }
}

const mapState = state => ({
  currentLocale: state.intl.locale,
  isSuperAdmin: state.runtime.isSuperAdmin,
  privileges: state.account.privileges && state.account.privileges.privileges && state.account.privileges.privileges,
});

const mapDispatch = {
  adminLogout,
  openHeaderModal
};

export default withStyles(s)(connect(mapState, mapDispatch)(SideMenu));
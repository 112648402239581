import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import submit from './submit';
import validate from './validate';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

import {
  Form,
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AdminLoginForm.css';
import { connect } from 'react-redux';

import Loader from '../../Common/Loader';

//Images
import adminLoginImage from '../../../../public/SiteImages/adminLoginPage.svg'
import AdminLogo from '../../../../public/SiteImages/logo.png';
import { maxUploadSize, api } from '../../../config';

class AdminLoginForm extends Component {

  static defaultProps = {
    loading: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true
    }
  }

  componentDidMount() {
    const isBrowser = typeof window !== 'undefined';
    isBrowser && this.setState({
      isDisabled: false
    });
  }


  renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </Form.Group>
    )
  }

  render() {
    const { error, handleSubmit, submitting, dispatch, loading, siteSettingsData } = this.props;
    const { formatMessage } = this.props.intl;
    const apiEndpoint = api && api.apiEndpoint;

    let adminLogo = siteSettingsData && siteSettingsData.homeLogo ? `${apiEndpoint}/images/logo/${siteSettingsData.homeLogo}` :  AdminLogo;

    return (
      <div>
          <div className={s.loginMainBg}>
            <div className={s.loginBg} style={{ backgroundImage: `url(${adminLoginImage})` }} />
            <div className={s.formSection}>
              <div className={s.formInner}>
                <div className={s.loginTitleScetion}>
                  <img src={adminLogo} />
                  <p className={s.loginTitle}><FormattedMessage {...messages.welcomeAdminLabel} /></p>
                </div>
                <div className={'loginInput'}>
                  <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                    {error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    <Field
                      name="email"
                      type="text"
                      component={this.renderField}
                      label={formatMessage(messages.email)}
                      placeholder={formatMessage(messages.email)}
                      labelClass={s.labelText}
                      fieldClass={cx(s.formControlInput)}
                    />
                    <Field
                      name="password"
                      type="password"
                      component={this.renderField}
                      label={formatMessage(messages.password)}
                      placeholder={formatMessage(messages.password)}
                      labelClass={s.labelText}
                      fieldClass={cx(s.formControlInput)}
                    />
                    <Form.Group className={s.loginButton}>
                      <Loader
                        type={"button"}
                        label={formatMessage(messages.login)}
                        show={loading}
                        buttonType={'submit'}
                        className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                        disabled={submitting || loading || this.state.isDisabled}
                        isSuffix={true}
                      />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

AdminLoginForm = reduxForm({
  form: 'AdminLoginForm', // a unique name for this form
  validate,
  onSubmit: submit
})(AdminLoginForm);

const mapState = (state) => ({
  loading: state.loader.AdminLogin,
  siteSettingsData: state.siteSettings.data
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AdminLoginForm)));
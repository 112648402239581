import showToaster from '../../../helpers/showToaster';
import { getAllSubMenu as query, updateSubMenuStatus as mutation } from '../../lib/graphql';
import { loadAccount } from '../userAccount/userAccount';

export default function updateSubMenuStatus(id, status, currentPage, menuId) {
  return async (dispatch, getState, { client }) => {
    try {

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          status: status == 'false' ? 0 : 1
        },
        refetchQueries: [{ query, variables: { currentPage, menuId } }]
      });

      if (data?.updateSubMenuStatus?.status === 200) {
        showToaster({ messageId: 'subMenuUpdated', toasterType: 'success' });
        dispatch(loadAccount())
      } else {
        showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateSubMenuStatus?.errorMessage })
      }
    } catch (err) {
      showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: err })
    }
  }
};

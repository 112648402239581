import showToaster from '../../../helpers/showToaster';
import { shopSignup } from '../../../actions/shop/signup/shopSignup';

async function submit(values, dispatch) {
    let addressError, phoneNumberError;

    values?.address ? addressError = false : addressError = true;
    values?.phoneNumber ? phoneNumberError = false : phoneNumberError = true;

    if (addressError) {
        return showToaster({ messageId: 'provideAddress', toasterType: 'error' })
    } else if(phoneNumberError){
        return showToaster({ messageId: 'providePhoneNumber', toasterType: 'error' })
    } else {
        await dispatch(shopSignup(values));
    }

};

export default submit;

import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './RestaurantBanner.css';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
    Row, Container, Col
} from 'react-bootstrap';

//Images
import BannerImage from '../../../../../public/SiteImages/banner-with-black.png';
import SignupForm from '../../SignupForm/SignupForm';

//Locale 
import messages from '../../../../locale/messages';
import { api, partnerHomepageUploadDir } from '../../../../config'

class RestaurantBanner extends React.Component {
    constructor(props) {
        super(props);
        this.scrollTop = this.scrollTop.bind(this);
    }

    scrollTop() {
        window.scrollTo({
            top: screen.height,
            behavior: 'smooth'
        })
    }

    render() {
        const { getCuisine, homepage } = this.props

        return (
            <div className={cx(s.layout4Container)}>
                <div className={cx(s.bannerBackgroundImage)}
                    style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir + homepage.bannerImage1})` }} />
                <Container className={s.bannerLayoutContainer}>
                    <Row>
                        <div className={s.bannerDisplayGrid}>
                            <div className={s.searchFormContainer}>
                                <div className={s.searchFormWrap}>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <h1 className='homeBannerTextEllipsis'>
                                            {homepage.bannerTitle1} <span className={cx(s.subTitle, 'homeBannerTextEllipsis')}>{homepage.bannerTitle2}</span>
                                        </h1>
                                        <p>
                                            {homepage.bannerContent1}
                                        </p>
                                    </Col>
                                </div>
                            </div>
                            <div className={s.formSection}>
                                <SignupForm getCuisine={getCuisine} />
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapState = (state) => ({});

const mapDispatch = {};

export default withStyles(s)(connect(mapState, mapDispatch)(RestaurantBanner));
import showToaster from '../../helpers/showToaster';
import { updateHomePageAbout as mutation } from '../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import {
    HOMEPAGE_SETTINGS_UPLOAD_START,
    HOMEPAGE_SETTINGS_UPLOAD_ERROR,
    HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index'

const updateHomepageSettingsAbout = (values) => {
    return async (dispatch, getState, { client }) => {
        try {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_START
            });
            dispatch(setLoaderStart('AboutSettingsForm'));
            const { data } = await client.mutate({
                mutation,
                variables: {
                    aboutGridImage1: values?.aboutGridImage1,
                    aboutGridImage2: values?.aboutGridImage2,
                    aboutGridTitle1: values?.aboutGridTitle1,
                    aboutGridTitle2: values?.aboutGridTitle2,
                    aboutGridTitle3: values?.aboutGridTitle3,
                    aboutGridTitle4: values?.aboutGridTitle4,
                    aboutGridTitle5: values?.aboutGridTitle5,
                    aboutGridTitle6: values?.aboutGridTitle6,
                    aboutGridContent1: values?.aboutGridContent1,
                    aboutGridContent2: values?.aboutGridContent2,
                    aboutGridContent3: values?.aboutGridContent3,
                    aboutGridContent4: values?.aboutGridContent4,
                    aboutGridContent5: values?.aboutGridContent5,
                    aboutGridContent6: values?.aboutGridContent6,
                    aboutGridImage3: values?.aboutGridImage3,
                }
            });
            dispatch(setLoaderComplete('AboutSettingsForm'));

            if (data?.updateHomePageAbout?.status == 200) {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
                });
                showToaster({ messageId: 'homepageSettingsApplied', toasterType: 'success' });
            } else {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
                });
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error' });
            }
        } catch (error) {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
            });
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
        }
    };
};

export default updateHomepageSettingsAbout;

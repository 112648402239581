import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormControl
} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AdminUserManagement.css';
import bt from '../../../components/commonStyle.css';

// Redux Actions
import { openAdminUserModal } from '../../../actions/siteadmin/modalActions';
import { deleteAdminUser } from '../../../actions/siteadmin/AdminUser/manageAdminUser';

// Components
import AdminUserModal from '../AdminUserModal';
import Link from '../../Link';
import CustomPagination from '../../CustomPagination/CustomPagination';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
import debounce from '../../../helpers/debounce';
// Images
import EditIcon from '../../../../public/Icons/edit.png';
import TrashIcon from '../../../../public/Icons/bin.svg';

class AdminUserManagement extends Component {

  static defaultProps = {
    data: [],
    roles: []
  };

  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      searchList: ''
    };
    this.paginationData = this.paginationData.bind(this);
    this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this));
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleKeywordSearch(searchList) {
    const { adminUsers: { refetch } } = this.props;
    let variables = {
      currentPage: 1,
      searchList,
    };
    this.setState({ searchList, currentPage: 1 });
    refetch(variables);
  }


  paginationData(currentPage) {
    const { adminUsers: { refetch } } = this.props;
    const { searchList } = this.state;
    let variables = { currentPage, searchList };
    this.setState({ currentPage });
    refetch(variables);
  };

  async handleDelete(id) {
    const { adminUsers: { loading, refetch }, deleteAdminUser } = this.props;
    const { searchList, currentPage } = this.state;
    let variables = { currentPage, searchList };
    const response = await deleteAdminUser(id);
    this.setState({ currentPage });
    refetch(variables);
  }

  render() {
    const { openAdminUserModal, deleteAdminUser, roles } = this.props;
    const { adminUsers, adminUsers: { getAllAdminUsers } } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage, searchList } = this.state;
    let paginationLabel = formatMessage(messages.users);

    return (

      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <AdminUserModal roles={roles} paginationData={this.paginationData} />
        <div className={s.container}>
          <div className={cx(s.alignItemCenter, s.tabelExport)}>
            <div className={s.searchInput}>
              <FormControl type='text' placeholder={formatMessage(messages.search)}
                onChange={(event) => this.handleKeywordSearch(event.target && event.target.value)}
                className={s.formControlInput} />
            </div>
            <Button
              className={cx(s.addlinkBtn)}
              onClick={() => openAdminUserModal('add')}>
              <FormattedMessage {...messages.addNewLabel} />
            </Button>
          </div>
          <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
            <Table className="table">
              <thead>
                <tr>
                  <th scope="col"><FormattedMessage {...messages.sNoLabel} /></th>
                  <th scope="col"><FormattedMessage {...messages.emailLabel} /></th>
                  <th scope="col"><FormattedMessage {...messages.adminRoleLabel} /></th>
                  <th scope="col"><FormattedMessage {...messages.edit} /></th>
                  <th scope="col"><FormattedMessage {...messages.delete} /></th>
                </tr>
              </thead>
              <tbody>
                {
                  adminUsers && getAllAdminUsers && getAllAdminUsers.count > 0 && getAllAdminUsers.results && getAllAdminUsers.results.map((value, key) => {
                    return (
                      <tr key={key}>
                        <td data-label={formatMessage(messages.sNoLabel)}>{key + 1}</td>
                        <td data-label={formatMessage(messages.emailLabel)}>{value.email}</td>
                        <td data-label={formatMessage(messages.adminRoleLabel)}>{value.adminRole && value.adminRole.name} </td>
                        <td data-label={formatMessage(messages.edit)}>
                          <Link noLink onClick={() => openAdminUserModal('edit', value)} className={s.cursorPointer}>
                            <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                            <span className={s.vtrMiddle}>
                              <FormattedMessage {...messages.editAction} />
                            </span>
                          </Link>
                        </td>
                        <td data-label={formatMessage(messages.delete)}>
                          <Link noLink onClick={() => this.handleDelete(value.id)} className={s.cursorPointer}>
                            <img src={TrashIcon} className={cx(s.editIcon, 'trashIconRTL', 'editIconRTL')} />
                            <span className={s.vtrMiddle}>
                              <FormattedMessage {...messages.deleteAction} />
                            </span>
                          </Link>
                        </td>
                      </tr>
                    )
                  })
                }
                {
                  adminUsers && getAllAdminUsers && getAllAdminUsers.count === 0 && <tr>
                    <td colSpan={50} className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                }
              </tbody>
            </Table>
          </div>
          {
            adminUsers && getAllAdminUsers && getAllAdminUsers.count > 0 && <div className={cx(s.space5, s.spaceTop5)}>
              <CustomPagination
                total={getAllAdminUsers.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={paginationLabel}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({});

const mapDispatch = {
  openAdminUserModal,
  deleteAdminUser
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(AdminUserManagement)));
import showToaster from '../../../helpers/showToaster';
import { deleteCategoryMutation as mutation } from '../../lib/graphql';
import {
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DELETE_START,
    CATEGORY_DELETE_ERROR
} from '../../../constants';

export default function deleteCategory(id) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: CATEGORY_DELETE_START
        });

        try {

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                }
            });

            if (data?.deleteCategory?.status === 200) {

                dispatch({
                    type: CATEGORY_DELETE_SUCCESS
                });
                showToaster({ messageId: 'cuisineDeleted', toasterType: 'success' })
                return {
                    status: 200
                };
            } else {

                dispatch({
                    type: CATEGORY_DELETE_ERROR
                });
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.deleteCategory?.errorMessage })
                return {
                    status: 400
                };
            }
        } catch (err) {

            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err });
            dispatch({
                type: CATEGORY_DELETE_ERROR
            });
            return {
                status: 400
            };
        }
    }
};
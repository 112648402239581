import showToaster from '../../../helpers/showToaster';
import socketNotification from '../../../core/socket/socketNotification';
import { deleteShopMutation as mutation } from '../../lib/graphql';
import {
    SHOP_USER_DELETE_SUCCESS,
    SHOP_USER_DELETE_START,
    SHOP_USER_DELETE_ERROR
} from '../../../constants/index';

export default function deleteShop(id) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: SHOP_USER_DELETE_START,
            payload: {
                deleteLoading: true
            }
        });

        try {
            let requestData;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                }
            });

            if (data?.deleteShop?.status === "200") {
                dispatch({
                    type: SHOP_USER_DELETE_SUCCESS,
                    payload: {
                        deleteLoading: false
                    }
                });
                requestData = { id };
                showToaster({ messageId: 'restaurantDeleted', toasterType: 'success' })
                socketNotification('webUserLogout', JSON.stringify(requestData));
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: SHOP_USER_DELETE_ERROR,
                    payload: {
                        deleteLoading: false
                    }
                });

                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.deleteShop?.errorMessage })
                return {
                    status: 400
                }
            }
        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            dispatch({
                type: SHOP_USER_DELETE_ERROR,
                payload: {
                    deleteLoading: false
                }
            });
            return {
                status: 400
            }
        }

    }
};
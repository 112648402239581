import showToaster from '../../../helpers/showToaster';
import { sendForgotPasswordLink as mutation } from '../../lib/graphql';
import { closeForgotPasswordModal } from '../../modalActions';

export function sendForgotPasswordLink(email) {

    return async (dispatch, getState, { client }) => {
        let errorMessage;

        dispatch(closeForgotPasswordModal());

        try {
            const { data } = await client.mutate({
                mutation,
                variables: { email }
            });

            if (data?.sendForgotPasswordLink) {
                if (data?.sendForgotPasswordLink?.status === 200) {
                    showToaster({ messageId: 'sentResetLink', toasterType: 'success' })
                    return true;
                } else {
                    errorMessage = data?.sendForgotPasswordLink?.errorMessage;
                    showToaster({ messageId: 'sendResetLinkFailed', toasterType: 'error', requestContent: errorMessage })
                    return false;
                }
            } else {
                showToaster({ messageId: 'sendResetLinkFailed', toasterType: 'error' })
                return false;
            }
        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            return false;
        }
    };
};
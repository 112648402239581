import gql from "graphql-tag";

export const addCategoryMutation = gql`
mutation addCategory(
    $categoryName: String,
    $isActive: Boolean,
    $categoryImage: String,
    $description: String
) {
    addCategory(
        categoryName: $categoryName,
        isActive: $isActive,
        categoryImage: $categoryImage,
        description: $description
    ) {
        status
        errorMessage
    }
}`;

export const deleteCategoryMutation = gql`
mutation deleteCategory($id: Int) {
    deleteCategory(id: $id) {
      status
      errorMessage
    }
  }
`;

export const updateCategoryMutation = gql`
      mutation updateCategory(
        $id: Int, 
        $categoryName: String,
        $description: String,
        $isActive: Boolean
      ) {
        updateCategory(
          id: $id,
          categoryName: $categoryName,
          description: $description,
          isActive: $isActive
        ){
          status
          errorMessage
        }
      }
    `;

export const updateCategoryImageMutation = gql`
mutation updateCategoryImage($id: Int, 
     $fileName: String
     ) {
    updateCategoryImage(
      id: $id
      fileName: $fileName
    ){
      status
      errorMessage
    }
  }
`;    

export const updateCategoryPrivilegeMutation = gql`
mutation($id: Int!, $isActive: Boolean, $privilegeType: String!) {
    updateCategoryPrivilege(id: $id, isActive: $isActive, privilegeType: $privilegeType) {
        status
        errorMessage
    }
}
`;

export const updateCategoryStatusMutation = gql`
mutation updateCategoryStatus($id: Int, $isActive: Boolean) {
    updateCategoryStatus(id: $id, isActive: $isActive) {
      status
      errorMessage
    }
}
`;
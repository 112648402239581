import showToaster from '../../../helpers/showToaster';
import { closeDeclineReasonModal } from '../../siteadmin/modalActions';
import { getPendingOrders } from './getPendingOrders';
import { updateOrderStatusData as mutation, getOrders as query } from '../../lib/graphql';

export default function updateOrderStatus(id, bookingStatus, currentPage, reason, isOrderDetailPage, orderModal) {
    return async (dispatch, getState, { client }) => {
        try {
            let refetchQueries = [];

            if (isOrderDetailPage) {
                refetchQueries = [{
                    query,
                    variables: { id }
                }];
            }

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    bookingStatus,
                    notes: reason,
                    orderModal
                },
                refetchQueries
            });

            if (orderModal) {
                await dispatch(getPendingOrders());
            }

            if (data?.updateOrderStatus?.status === 200) {
                showToaster({ messageId: 'orderStatusUpdate', toasterType: 'success' })
                dispatch(closeDeclineReasonModal());
            } else {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateOrderStatus?.errorMessage })
            }

        } catch (error) {
            showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: error })
        }
    }
};
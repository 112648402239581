import { editDriver } from '../../../actions/siteadmin/editDriver';

async function submit(values, dispatch) {
    let organization, otherOrganization;
    organization =  Number(values?.organization) > 0 ? Number(values?.organization) : 0;
    otherOrganization =  Number(values?.organization) == 0 ? values.otherOrganization : null;
    await dispatch(
        editDriver(
            values.id,
            values.firstName,
            values.lastName,
            values.email,
            values.password ? values.password : undefined,
            values.phoneDialCode,
            values.phoneNumber,
            values.vehicleName,
            values.vehicleNumber,
            values.vehicleType,
            values.userStatus,
            values.isBan,
            values.phoneCountryCode,
            organization,
            otherOrganization
        )
    )
}

export default submit;
import gql from "graphql-tag";

export const getPayouts = gql`query getPayouts($currentAccountId: String, $country:String) {
    getPayouts(currentAccountId: $currentAccountId, country: $country) {
      id
      methodId
      paymentMethod{
        id
        name
      }
      userId
      payEmail
      address1
      address2
      city
      state
      country
      zipcode
      currency
      default
      createdAt
      status
      last4Digits
      isVerified
      firstName
      lastName
    }
}`;

export const removePayout = gql`mutation removePayout($id: Int! ){
    removePayout(id: $id) {
        status
    }
}`;

export const setDefaultPayout = gql`mutation setDefaultPayout($id: Int!){
    setDefaultPayout(id: $id) {
        status
    }
}`;

export const getPaymentMethods = gql`query getPaymentMethods {
    getPaymentMethods {
      id
      name
      processedIn
      fees
      currency
      details
      isEnable
      paymentType
      createdAt
      updatedAt
      status
    }
}`;

export const updatePayoutMutation = gql`
mutation updatePayout ($id: Int!, $requestStatus: Boolean!, $fieldName: String){
  updatePayout(id: $id, requestStatus: $requestStatus, fieldName: $fieldName){
      status
      errorMessage
    }
}`;

export const updatePaymentMutation = gql`mutation updatePayment ($id: Int, $status: Boolean){
  updatePayment(id: $id, status: $status){
      status
      errorMessage
    }
}`;

export const getPaymentMethodsQuery = gql`query {
	getAllPaymentMethods {
		results {
			id
			paymentName
			isEnable
			paymentType
		}
		status
		errorMessage
	}
}`;

import showToaster from '../../../helpers/showToaster';
import { loadAccount } from '../userAccount/userAccount';
import { getAllSubMenu as query, deleteItem as mutation } from '../../lib/graphql';
import {
	SHOP_MENU_UPDATE_START,
	SHOP_MENU_UPDATE_SUCCESS,
	SHOP_MENU_UPDATE_ERROR
} from '../../../constants/index';

export default function deleteItem(id, currentPage, menuId) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: SHOP_MENU_UPDATE_START,
			payload: {
				updateLoading: true
			}
		});

		try {
			let errorMessage;

			const { data } = await client.mutate({
				mutation,
				variables: {
					id
				},
				refetchQueries: [{ query, variables: { currentPage, searchList: '', menuId } }]
			});

			if (data?.deleteItem?.status === 200) {
				dispatch({
					type: SHOP_MENU_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'itemDeleted', toasterType: 'success' })
				dispatch(loadAccount())
			} else {
				dispatch({
					type: SHOP_MENU_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				errorMessage = data?.deleteItem?.errorMessage;
				showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: errorMessage })
			}
		} catch (err) {
			showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: err })
			dispatch({
				type: SHOP_MENU_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};

import showToaster from '../../../helpers/showToaster';
import { updateFoodChoice as mutation, getFoodChoices as query } from '../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { closeFoodChoiceModal } from '../../modalActions';
import {
    UPDATE_FOOD_CHOICE_START,
    UPDATE_FOOD_CHOICE_SUCCESS,
    UPDATE_FOOD_CHOICE_ERROR
} from '../../../constants/index';

const updateFoodChoice = (id, itemName, isEnable) => {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch(setLoaderStart('updateFoodLoading'));
            dispatch({
                type: UPDATE_FOOD_CHOICE_START,
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    itemName,
                    isEnable
                },
                refetchQueries: [{ query, variables: { currentPage: 1, searchList: '' } }]
            });

            dispatch({
                type: data?.updateFoodChoice?.status != 200 ? UPDATE_FOOD_CHOICE_SUCCESS : UPDATE_FOOD_CHOICE_ERROR,
            });
            dispatch(closeFoodChoiceModal());
            dispatch(setLoaderComplete('updateFoodLoading'));
            if (data?.updateFoodChoice?.status == 200) {
                showToaster({ messageId: id ? 'updatedFoodChoiceSuccess' : 'addedFoodChoiceSuccess', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateFoodChoice?.errorMessage })
            }

        } catch (error) {
            dispatch({
                type: UPDATE_FOOD_CHOICE_ERROR,
            });
            dispatch(setLoaderComplete('updateFoodLoading'));
        }
    }

}

export default updateFoodChoice;

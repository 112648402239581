import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';

import { api } from '../../../config';

import s from './HomeItem.css';

export class HomeItem extends Component {
    render() {
        const { categoryName, categoryImage } = this.props;

        return (
           
            <div>
                <div className={cx(s.sliderBox, s.tabPaddingTop)}>
                    <div className={s.sliderBg} />
                        <div>
                            <img
                                src={api.apiEndpoint + "/images/category/" + categoryImage}
                            />
                        </div>
                    <div className={s.sliderBottom}>
                        <label>{categoryName}</label>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(HomeItem)) 

import showToaster from '../../../helpers/showToaster';
import { getCurrency, getCurrencyRatesQuery, updateCurrencyMutation, setBaseCurrencyMutation, allowPaymentCurrencyMutation } from '../../lib/graphql';
import {
  CHANGE_CURRENCY_STATUS_START,
  CHANGE_CURRENCY_STATUS_SUCCESS,
  CHANGE_CURRENCY_STATUS_ERROR,
  SET_BASE_CURRENCY_START,
  SET_BASE_CURRENCY_SUCCESS,
  SET_BASE_CURRENCY_ERROR,
  CURRENCY_RATES_FETCH_SUCCESS
} from '../../../constants';

const updateCurrencyStatus = (id, isEnable, symbol, currentPage) => {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: CHANGE_CURRENCY_STATUS_START,
    });

    try {
      const { data } = await client.mutate({
        mutation: updateCurrencyMutation,
        variables: { id, isEnable, symbol },
        refetchQueries: [{ query: getCurrency, variables: { currentPage, searchList: '' } }]
      });

      if (data?.updateCurrency?.status === 200) {

        dispatch({
          type: CHANGE_CURRENCY_STATUS_SUCCESS,
        });
        showToaster({ messageId: 'currencyStatusUpdated', toasterType: 'success' })

      } else if (data?.updateCurrency?.status !== 200) {

        dispatch({
          type: CHANGE_CURRENCY_STATUS_ERROR,
          payload: {
            error: data?.updateCurrency?.errorMessage
          }
        });
        showToaster({ messageId: 'failedMessage', toasterType: 'error', requestContent: data?.updateCurrency?.errorMessage })
        return false;
      }
    } catch (error) {

      dispatch({
        type: CHANGE_CURRENCY_STATUS_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
      return false;
    }
    return true;
  };
}

const setBaseCurrency = (id) => {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: SET_BASE_CURRENCY_START,
    });

    try {
      let currencyRates, base;

      const { data } = await client.mutate({
        mutation: setBaseCurrencyMutation,
        variables: { id },
        refetchQueries: [{ query: getCurrency, variables: { currentPage: '1', searchList: '' } }]
      });

      if (data.setBaseCurrency.status == 200) {
        dispatch({
          type: SET_BASE_CURRENCY_SUCCESS,
        });

        const currency = await client.query({ query: getCurrencyRatesQuery, fetchPolicy: 'network-only' })

        if (currency?.data?.getCurrencyRates) {

          base = currency?.data?.getCurrencyRates.base;

          if (currency?.data?.getCurrencyRates?.rates != null) {
            currencyRates = JSON.parse(currency?.data?.getCurrencyRates?.rates);
          }

          showToaster({ messageId: 'baseCurrencySet', toasterType: 'success' })

          dispatch({
            type: CURRENCY_RATES_FETCH_SUCCESS,
            payload: {
              base,
              to: "",
              rates: currencyRates
            }
          })

        }
      }
    } catch (error) {

      dispatch({
        type: SET_BASE_CURRENCY_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'failedSetBaseCurrency', toasterType: 'error' })
      return false;
    }
    return true;
  };
}

const allowPaymentCurrency = (id, isPayment) => {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: SET_BASE_CURRENCY_START,
    });

    try {
      const { data } = await client.mutate({
        mutation: allowPaymentCurrencyMutation,
        variables: { id, isPayment },
        refetchQueries: [{ query: getCurrency }]
      });

      if (data?.allowPaymentCurrency?.status === 200) {

        dispatch({
          type: SET_BASE_CURRENCY_SUCCESS,
        });
        showToaster({ messageId: 'paymentCurrencySuccess', toasterType: 'success' })
      }

    } catch (error) {

      dispatch({
        type: SET_BASE_CURRENCY_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'failedSetPaymentCurrency', toasterType: 'error' })
      return false;
    }
    return true;
  };
}

export { updateCurrencyStatus, setBaseCurrency, allowPaymentCurrency };
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './OrganizationForm.css';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import OrganizationForm from './OrganizationForm';
import { closeOrganizationModal } from '../../../actions/modalActions';
import messages from '../../../locale/messages';

class OrganizationModal extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        const { modalStatus, closeOrganizationModal, paginationData } = this.props;
        return (
            <div>
                <Modal show={modalStatus} animation={false} onHide={closeOrganizationModal} >
                    <div>
                        <Modal.Header closeButton>
                            <Modal.Title><FormattedMessage {...messages.organization} /></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <OrganizationForm paginationData={paginationData} />
                        </Modal.Body>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapState = state => ({
    modalStatus: state?.modalStatus?.isOrganizationModalOpen,
})

const mapDispatch = {
    closeOrganizationModal
};

export default withStyles(s)(connect(mapState, mapDispatch)(OrganizationModal))
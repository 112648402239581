import showToaster from '../../../helpers/showToaster';
import { loadAccount } from '../userAccount/userAccount';
import { getAllSubMenu as query, deleteSubMenu as mutation } from '../../lib/graphql';

export default function deleteSubMenu(id, currentPage, menuId) {
    return async (dispatch, getState, { client }) => {

        try {
            let errorMessage;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                },
                refetchQueries: [{ query, variables: { currentPage, searchList: '', menuId } }]
            });

            if (data?.deleteSubMenu?.status === 200) {
                showToaster({ messageId: 'subMenuDeleted', toasterType: 'success' })
                dispatch(loadAccount())
            } else {
                errorMessage = data?.deleteSubMenu?.errorMessage;
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: errorMessage })
            }
        } catch (err) {
            showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: err })
        }
    }
};

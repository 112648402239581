import {
    SITE_SETTINGS_ERROR,
    SITE_SETTINGS_START,
    SITE_SETTINGS_SUCCESS
} from '../../constants';
import { getSiteSettings as query } from '../lib/graphql';

export function siteSettings() {
    return async (dispatch, getState, { client }) => {
        try {
            let settingsData = {};
            dispatch({
                type: SITE_SETTINGS_START
            });
            const type = "site_settings";
            const { data } = await client.query({
                query,
                variables: { type },
                fetchPolicy: 'network-only'
            });

            if (data?.getSiteSettings) {
                data?.getSiteSettings?.map((item, key) => {
                    settingsData[item.name] = item.value;
                });
                dispatch({
                    type: SITE_SETTINGS_SUCCESS,
                    data: settingsData
                });
            } else {
                dispatch({
                    type: SITE_SETTINGS_ERROR
                });
            }
        } catch (error) {
            dispatch({
                type: SITE_SETTINGS_ERROR,
            });
        }
    };
};

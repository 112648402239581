const formatURL = (data) => {
    try {
        let convertData = null;

        if (data) {
            convertData = data;
            convertData = convertData.toLowerCase();
            convertData = convertData.replace(new RegExp(', ', 'g'), '--');
            convertData = convertData.replace(new RegExp(' ', 'g'), '-');
            convertData = convertData.replace(new RegExp('/', 'g'), '-');
            convertData = convertData.replace(new RegExp('#', 'g'), '-');
            convertData = convertData.replace(new RegExp('%', 'g'), '-');
            convertData = convertData.replace(/\?/g, '-');
            convertData = convertData.replace(/\\/g, "-")
        }

        return convertData;
    } catch (error) {
        return false;
    }
}

const resetURL = (data) => {
    try {
        let convertData = null;

        if (data) {
            convertData = data;
            convertData = convertData.replace(new RegExp('--', 'g'), ', ');
            convertData = convertData.replace(new RegExp('-', 'g'), ' ');
            convertData = convertUpperCase(convertData);
            if (convertData.indexOf(', ') >= 0) {
                convertData = convertCountryCode(convertData);
            }
        }

        return convertData;

    } catch (error) {
        return false;
    }
}

export { formatURL, resetURL };

exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2lQkt {\n  width: 100%;\n}\n\n._1d7LT {\n  background-color: #f1f1f1;\n  border: 1px solid #ced4da;\n  padding: 0px 24px;\n  width: 52%;\n  text-align: left;\n  color: #484848 !important;\n  font-weight: 500;\n}\n\n.cVn32 {\n  text-align: right !important;\n  display: block;\n  text-decoration: none !important;\n  line-height: 34px;\n}\n\n._2SHzr {\n  width: 100%\n}\n\n.cVn32 img {\n  width: 100%;\n  max-width: 32px;\n  min-width: 32px;\n}\n\n._1_gF9 {\n  font-size: 15px;\n  color: #11b325;\n  vertical-align: middle;\n  text-align: center;\n  display: inline-block;\n  margin-left: 6px;\n  line-height: 15px;\n  font-weight: 500;\n  padding-top: 2px;\n}\n\n._2QFAT {\n  width: 100%;\n  max-width: 22px;\n}\n\n._2lQkt tr td {\n  padding: 0px 10px;\n  color: #484848;\n  font-size: 14px;\n}\n\n._2ZoqA {\n  border: 1px solid #5EBE00;\n  border-radius: 2px;\n  padding: 12px 20px !important;\n  margin: 0px;\n  text-align: center;\n}\n\n.BXbBx {\n  width: 100%;\n}\n\n._2YgFL {\n  padding: 0px !important;\n}\n\n._3Ne9v,\n._3Ne9v:hover,\n._3Ne9v:focus {\n  padding: 0 !important;\n  background-color: transparent !important;\n  border: 1px solid transparent !important;\n  -webkit-box-shadow: none !important;\n          box-shadow: none !important;\n}\n\n@media screen and (max-width: 1200px) {\n  ._2lQkt tr {\n    display: block;\n    width: 100%;\n    position: relative;\n  }\n\n  ._1d7LT {\n    display: block;\n    padding: 12px 24px;\n    width: 100%;\n    margin-bottom: 12px;\n  }\n\n  ._2lQkt tr td {\n    width: 100%;\n  }\n\n  ._2lQkt tr td {\n    padding: 0px 10px 0px 0px;\n  }\n\n  ._2LgVa {\n    padding: 12px 15px !important;\n  }\n}", ""]);

// exports
exports.locals = {
	"tableBox": "_2lQkt",
	"tableBoxHeader": "_1d7LT",
	"removeBtnSection": "cVn32",
	"loaderSection": "_2SHzr",
	"confirmedIcon": "_1_gF9",
	"tickIcon": "_2QFAT",
	"confirmBtn": "_2ZoqA",
	"verifyWidth": "BXbBx",
	"deleteIconPadding": "_2YgFL",
	"removePhoneNoIconButton": "_3Ne9v",
	"btnPadding": "_2LgVa"
};
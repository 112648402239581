import showToaster from '../../../helpers/showToaster';
import { updateOrganizationStatus as mutation } from '../../lib/graphql';
import {
    UPDATE_ORGANIZATION_START,
    UPDATE_ORGANIZATION_SUCCESS,
    UPDATE_ORGANIZATION_ERROR
} from '../../../constants/index';

const updateOrganizationStatus = (id, isEnable) => {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: UPDATE_ORGANIZATION_START,
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    isEnable
                }
            });

            dispatch({
                type: data?.updateOrganizationStatus?.status != 200 ? UPDATE_ORGANIZATION_SUCCESS : UPDATE_ORGANIZATION_ERROR,
            });

            if (data?.updateOrganizationStatus?.status == 200) {
                showToaster({ messageId: 'updatedOrganizationSuccess', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateOrganizationStatus?.errorMessage })
            }

        } catch (error) {
            dispatch({
                type: UPDATE_ORGANIZATION_ERROR,
            });
        }
    }

}

export default updateOrganizationStatus;

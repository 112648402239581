import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import ManageOrganizations from './ManageOrganizations';
import messages from '../../../locale/messages';
import { restrictUrls } from '../../../helpers/adminPrivileges';
function action({ store, intl }) {
    const title = intl.formatMessage(messages.manageOrganization);
    //From Redux Store
    let isAdminAuthenticated = store.getState()?.runtime?.isAdminAuthenticated;
    let adminPrivileges = store.getState()?.account?.privileges?.privileges;
    let privileges = store.getState()?.commonSettings?.privileges;
    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }
    if (!restrictUrls('/siteadmin/organizations', adminPrivileges, privileges)) {
        return { redirect: '/siteadmin' };
    }
    return {
        title,
        component: (
            <AdminLayout>
                <ManageOrganizations title={title}/>
            </AdminLayout>
        ),
    }
}
export default action;
import gql from "graphql-tag";

export const updateOrganization = gql`
  mutation updateOrganization($id: Int, $itemName: String!, $isEnable: Boolean) {
    updateOrganization(id: $id, itemName: $itemName, isEnable: $isEnable) {
      status
      errorMessage
    }
  }
`;

export const updateOrganizationStatus = gql`
  mutation updateOrganizationStatus($id: Int!, $isEnable: Boolean!) {
    updateOrganizationStatus(id: $id, isEnable: $isEnable) {
      status
      errorMessage
    }
  }
`;

export const deleteOrganization = gql`
  mutation deleteOrganization($id: Int!) {
    deleteOrganization(id: $id) {
      status
      errorMessage
    }
  }
`;

export const getOrganizations = gql`
  query getOrganizations($searchList: String, $currentPage: Int) {
    getOrganizations(searchList: $searchList, currentPage: $currentPage) {
      results {
        id
        itemName
        isEnable
      }
      count
      status
      errorMessage
    }
  }
`;

export const getActiveOrganizations = gql`
query getActiveOrganizations{
    getActiveOrganizations{
      results {
        id
        itemName
        isEnable
      }
      count
      status
      errorMessage
    }
  }
`;

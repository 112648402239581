import showToaster from '../../../helpers/showToaster';
import { updateShopMutation as mutation } from '../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import {
    SHOP_UPDATE_SUCCESS,
    SHOP_UPDATE_START,
    SHOP_UPDATE_ERROR
} from '../../../constants/index';

export default function updateShop(values) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: SHOP_UPDATE_START
        });

        try {
            dispatch(setLoaderStart('UpdateRestaurant'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id: values?.id,
                    shopName: values?.shopName,
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    email: values?.email,
                    phoneDialCode: values?.phoneDialCode,
                    phoneNumber: values?.phoneNumber,
                    phoneCountryCode: values?.phoneCountryCode,
                    address: values?.address,
                    lat: values?.lat,
                    lng: values?.lng,
                    city: values?.city,
                    state: values?.state,
                    zipcode: values?.zipcode,
                    country: values?.country,
                    categoryType: values?.categoryType,
                    description: values?.description,
                    priceRangeId: values?.priceRange,
                    picture: values?.picture,
                    preferredCurrency: values?.preferredCurrency,
                    dietaryType: values?.dietaryType,
                    shopEstimatedTime: values?.shopEstimatedTime,
                    password: values?.password,
                    userStatus: values?.userStatus,
                    isBan: values?.isBan == '0' ? false : true,
                    isTakeAway: values?.isTakeAway,
                    isDoorDelivery: values?.isDoorDelivery,
                    businessId: values?.businessId,
                    healthMinistryId: values?.healthMinistryId,
                }
            });

            if (data?.updateShop?.status == "200") {

                dispatch({
                    type: SHOP_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('UpdateRestaurant'));
                showToaster({ messageId: 'restaurantDetailsUpdated', toasterType: 'success' })
            } else {

                dispatch({
                    type: SHOP_UPDATE_ERROR
                });
                await dispatch(setLoaderComplete('UpdateRestaurant'));
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateShop?.errorMessage })
            }
        } catch (error) {

            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            dispatch({
                type: SHOP_UPDATE_ERROR
            });
        }
    }
};
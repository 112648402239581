import showToaster from '../../helpers/showToaster';
import { updateFavIconMutation as mutation } from '../lib/graphql';
import {
	UPDATE_SITE_SETTINGS_START,
	UPDATE_SITE_SETTINGS_ERROR,
	UPDATE_SITE_SETTINGS_SUCCESS
} from '../../constants/index';

const updateFavIcon = (favicon) => {
	return async (dispatch, getState, { client }) => {
		try {
			dispatch({
				type: UPDATE_SITE_SETTINGS_START
			});
			const { data } = await client.mutate({
				mutation,
				variables: {
					favicon
				}
			});

			if (data?.updateFavIcon?.status == 200) {
				dispatch({
					type: UPDATE_SITE_SETTINGS_SUCCESS
				});
				showToaster({ messageId: 'siteSettingChangesApplied', toasterType: 'success' });
			} else {
				dispatch({
					type: UPDATE_SITE_SETTINGS_ERROR
				});
				showToaster({ messageId: 'somethingWentWrong', toasterType: 'error' });
			}
		} catch (error) {
			dispatch({
				type: UPDATE_SITE_SETTINGS_ERROR
			});
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
		}
	};
};

export default updateFavIcon;

import showToaster from '../../helpers/showToaster';
import setOperationHours from './setOperationHours';
import { getOperationHours as query, updatePartnerOperatingHours as mutation } from '../lib/graphql';

function updateOperationHours(values) {

    return async (dispatch, getState, { client }) => {
        try {
            let operationHours;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    operationHours: values
                },
                refetchQueries: [{ query }]
            });

            if (data?.updatePartnerOperatingHours?.status == 200) {
                operationHours = JSON.parse(values);
                dispatch(setOperationHours(operationHours));
                showToaster({ messageId: 'hoursUpdate', toasterType: 'success' })
            } else {
                showToaster({ messageId: 'unableToUpdateOperatingHours', toasterType: 'error', requestContent: data?.updatePartnerOperatingHours?.errorMessage })
            }
        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
        }
    }
};

export default updateOperationHours;
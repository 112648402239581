import gql from "graphql-tag";

export const addMutation = gql`
mutation addDeliveryVehicle(
  $vehicleName: String,
  $vehicleImage: String,
  $description: String,
  $isActive: Boolean
) {
  addDeliveryVehicle(
      vehicleName: $vehicleName,
      vehicleImage: $vehicleImage,
      description: $description,
      isActive: $isActive
  ) {
      status
      errorMessage
  }
}`;

export const updateMutation = gql`
mutation(
  $id: Int, 
  $vehicleName: String,
  $description: String,
  $isActive: Boolean
) {
  updateDeliveryVehicle(
      id: $id,
      vehicleName: $vehicleName,
      description: $description,
      isActive: $isActive
  ){
      status
      errorMessage
  }
}`;

export const deleteDeliveryVehicleMutation = gql`
mutation($id: Int) {
    deleteDeliveryVehicle(id: $id) {
        status
        errorMessage
    }
}`;

export const updateDeliveryVehicleImageMutation = gql`
mutation($id: Int, $fileName: String) {
  updateDeliveryVehicleImage(id: $id, fileName: $fileName) {
      status
  }
}`;

export const updateDeliveryVehicleStatusMutation = gql`
mutation($id: Int, $isActive: Boolean) {
    updateDeliveryVehicleStatus(id: $id, isActive: $isActive) {
        status
        errorMessage
    }
}`;
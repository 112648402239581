import { getCartModifierItem as query } from '../../lib/graphql';
import {
    SHOP_MODIFIER_ITEM_UPDATE_START,
    SHOP_MODIFIER_ITEM_UPDATE_SUCCESS,
    SHOP_MODIFIER_ITEM_UPDATE_ERROR
} from '../../../constants/index';

export default function getBookedModifierItem(modifierItemId) {
    return async (dispatch, getState, { client }) => {
        
        dispatch({
            type: SHOP_MODIFIER_ITEM_UPDATE_START,
        });
        try {
            const { data: { getCartModifierItem } } = await client.query({
                query,
                variables: {
                    modifierItemId
                }
            });
            if (getCartModifierItem?.status === 200) {

                dispatch({
                    type: SHOP_MODIFIER_ITEM_UPDATE_SUCCESS,
                });

                return {
                    data: getCartModifierItem?.result?.removeModifierItemId
                }
            }
        } catch (error) {

            dispatch({
                type: SHOP_MODIFIER_ITEM_UPDATE_ERROR,
            });
        }
    }
}
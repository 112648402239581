import showToaster from '../../helpers/showToaster';
import { uploadInsuranceImageMutation as mutation } from '../lib/graphql';
import { removeImage } from '../../helpers/removeImage';
import {
  INSURANCE_IMAGE_UPLOAD_ERROR,
  INSURANCE_IMAGE_UPLOAD_START,
  INSURANCE_IMAGE_UPLOAD_SUCCESS
} from '../../constants/index';

const uploadInsuranceImage = (userId, fileName, oldFile) => {
  return async (dispatch, getState, { client }) => {
    try {
      let url = "/deleteInsuranceImage";

      dispatch({
        type: INSURANCE_IMAGE_UPLOAD_START
      });
      const { data } = await client.mutate({
        mutation,
        variables: {
          userId,
          vehicleInsurance: fileName
        }
      });

      if (oldFile !== undefined) {
        removeImage(url, oldFile)
      };

      if (data?.uploadInsuranceImage?.status == "200") {
        dispatch({
          type: INSURANCE_IMAGE_UPLOAD_SUCCESS
        });
        showToaster({ messageId: 'insuranceImageUploaded', toasterType: 'success' });
      } else {
        dispatch({
          type: INSURANCE_IMAGE_UPLOAD_ERROR
        });
      }
    } catch (err) {
      dispatch({
        type: INSURANCE_IMAGE_UPLOAD_ERROR
      });
    }
  };
};

export default uploadInsuranceImage;
import showToaster from '../../../helpers/showToaster';
import history from '../../../history';
import { getAllSubMenu, updateItemMutation } from '../../lib/graphql';

function addItem(values) {
	return async (dispatch, getState, { client }) => {
		try {
			if (values.id) {
				const { data: { updateItem } } = await client.mutate({
					mutation: updateItemMutation,
					variables: values,
					refetchQueries: [{ query: getAllSubMenu, variables: { currentPage: 1, searchList: '', menuId: values?.menuId } }]
				});

				if (updateItem?.status == 200) {
					showToaster({ messageId: 'changesUpdated', toasterType: 'success' });
					history.push('/restaurant/menu/' + values?.menuId + '/item');
				} else {
					showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: updateItem?.errorMessage });
				}
			} else {
				const { data: { updateItem } } = await client.mutate({
					mutation: updateItemMutation,
					variables: values,
					refetchQueries: [{ query: getAllSubMenu, variables: { currentPage: 1, menuId: values?.menuId } }]
				});

				if (updateItem?.status == 200) {
					showToaster({ messageId: 'itemAdded', toasterType: 'success' });
					history.push('/restaurant/menu/' + values?.menuId + '/item');
				} else {
					showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: updateItem?.errorMessage });
				}
			}

		} catch (error) {
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
		}
	}
};

export default addItem;
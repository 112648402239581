import React from 'react';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import HomePartnersCommon from './HomePartnersCommon';

import { api } from '../../../config';

import s from './HomePartners.css';

class HomePartners extends React.Component {

    render() {

        const { data } = this.props;
        let homeSettings = {};

        data?.homePageData?.map((item) => {
            homeSettings[item.name] = item.value
            });

        return (
            <div className={s.root}>
                <Container className={s.bannerLayoutContainer}>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className={s.positionRelative}>
                                <div className={cx(s.textCenter, s.headerMargin)}>
                                    <h1 className={s.headerText}>
                                        {homeSettings.aboutGridTitle5}
                                    </h1>
                                    <p className={s.headerSubText}>
                                    {homeSettings.aboutGridContent5}
                                    </p>
                                </div>
                                <div className={s.orderDisplayGrid}>
                                    <HomePartnersCommon
                                        title={homeSettings.aboutGridTitle1}
                                        Image={api.apiEndpoint + "/images/homepage/" + homeSettings?.aboutGridImage1}
                                        Url={'/'}
                                    />
                                    <HomePartnersCommon
                                        title={homeSettings.aboutGridTitle2}
                                        Image={api.apiEndpoint + "/images/homepage/" + homeSettings?.aboutGridImage2}
                                        Url={'/'}
                                    />
                                    <HomePartnersCommon
                                        title={homeSettings.aboutGridTitle3}
                                        Image={api.apiEndpoint + "/images/homepage/" + homeSettings?.aboutGridImage3}
                                        Url={'/'}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        );
    }
}

export default injectIntl(withStyles(s)(HomePartners));
import showToaster from '../../helpers/showToaster';
import history from '../../history';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';
import { updateRider as mutation } from '../lib/graphql';

export function editRider(id, firstName, lastName, email, password, phoneDialCode, phoneNumber, userStatus, isBan, phoneCountryCode) {
  return async (dispatch, getState, { client }) => {

    dispatch(setLoaderStart('EditRider'));

    try {
      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          firstName,
          lastName,
          email,
          password,
          phoneDialCode,
          phoneNumber,
          userStatus,
          isBan,
          phoneCountryCode
        }
      });

      dispatch(setLoaderComplete('EditRider'));

      if (data?.updateRider?.status === 200) {
        history.push('/siteadmin/users')
        showToaster({ messageId: 'eaterInfoUpdated', toasterType: 'success' })
      } else {
        showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateRider?.errorMessage })
      }

    } catch (error) {
      showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: error })
    }
  }
};

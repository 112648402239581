import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { closeCommonSettingsModal } from '../modalActions';
import { addCommonSettingsMutation, updateCommonSettingStatusMutation, deleteCommonSettingMutation } from '../../lib/graphql';

export default function addCommonSettings(values) {
    return async (dispatch, getState, { client }) => {

        dispatch(setLoaderStart('CommonSettings'));

        try {

            const { data } = await client.mutate({
                mutation: addCommonSettingsMutation,
                variables: {
                    itemName: values?.itemName,
                    typeId: values?.typeId,
                    id: values?.id
                }
            });

            if (data?.addCommonSettings?.status === 200) {
                dispatch(closeCommonSettingsModal('CommonSettings'));
                if (values?.id) {
                    showToaster({ messageId: 'settingUpdated', toasterType: 'success' })
                } else {
                    showToaster({ messageId: 'settingCreated', toasterType: 'success' })
                }
                dispatch(setLoaderComplete('CommonSettings'))
                return {
                    status: 200
                };
            } else {
                dispatch(setLoaderComplete('CommonSettings'));
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.addCommonSettings?.errorMessage });
                return {
                    status: 400
                };
            }
        } catch (error) {
            dispatch(setLoaderComplete('CommonSettings'));
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            return {
                status: 400
            };
        }
    }
};


export function updateCommonSettingStatus(id, typeId, isEnable) {
    return async (dispatch, getState, { client }) => {

        try {
            const { data } = await client.mutate({
                mutation: updateCommonSettingStatusMutation,
                variables: {
                    id,
                    typeId,
                    isEnable
                }
            });

            if (data?.updateCommonSettingStatus?.status === 200) {
                showToaster({ messageId: 'settingStatusUpdated', toasterType: 'success' })
                return {
                    status: 200
                };
            } else {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateCommonSettingStatus?.errorMessage })
                return {
                    status: 400
                };
            }
        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            return {
                status: 400
            };
        }
    }
};

export function deleteCommonSetting(id, typeId) {
    return async (dispatch, getState, { client }) => {

        try {
            const { data } = await client.mutate({
                mutation: deleteCommonSettingMutation,
                variables: {
                    id,
                    typeId
                }
            });

            if (data?.deleteCommonSetting?.status === 200) {
                showToaster({ messageId: 'settingUpdated', toasterType: 'success' })
                return {
                    status: 200
                };
            } else {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.deleteCommonSetting?.errorMessage })
                return {
                    status: 400
                };
            }
        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
            return {
                status: 400
            };
        }
    }
};

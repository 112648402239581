import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Loader from '../../../components/Common/Loader/Loader';
import OrganizationList from '../../../components/SiteAdmin/OrganizationList/OrganizationList';
import { getOrganizations } from '../../../actions/lib/graphql';
import messages from '../../../locale/messages';
import s from './ManageOrganizations.css';

export class ManageOrganizations extends Component {

  static propTypes = {
    getOrganizations: PropTypes.object
  }

  static defaultProps = {
    loading: true
  }

  render() {
    const { title, getOrganizations: { loading, getOrganizations, refetch } } = this.props;

    return (
      <div>
        <Loader type={"page"} show={loading}>
          <div className={s.root}>
            <div className={s.container}
            >
              <div className={s.heading}><FormattedMessage {...messages.manageOrganization} /></div>
              <div className={s.paddingRoutesSection}>
                <OrganizationList getOrganizations={getOrganizations} refetch={refetch} />
              </div>
            </div>
          </div>
        </Loader>
      </div>
    )
  }
}

export default compose(withStyles(s),
  graphql(getOrganizations, {
    name: 'getOrganizations',
    options: {
      variables: {
        currentPage: 1,
        searchList: ''
      },
      fetchPolicy: 'network-only'
    }
  }))(ManageOrganizations)

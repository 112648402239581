import gql from "graphql-tag";

export const addCommonSettingsMutation = gql`
mutation addCommonSettings($id: Int, $itemName: String!, $typeId: Int!) {
    addCommonSettings(id: $id, itemName: $itemName, typeId: $typeId) {
        status
        errorMessage
    }
}`;

export const updateCommonSettingStatusMutation = gql`
mutation updateCommonSettingStatus($id: Int!, $typeId: Int!, $isEnable: Boolean) {
    updateCommonSettingStatus(id: $id, typeId: $typeId, isEnable: $isEnable) {
        status
        errorMessage
    }
}`;

export const deleteCommonSettingMutation = gql`
mutation ($id: Int!, $typeId: Int!){
    deleteCommonSetting(id: $id, typeId: $typeId) {
    status
    errorMessage
    }
}`;

export const updateConfigSettings = gql`mutation updateConfigSettings(
    $smtpHost: String,
    $smtpPort: String,
    $smptEmail: String,
    $smtpSender: String,
    $smtpSenderEmail: String,
    $smtpPassWord: String,
    $twilioAccountSid: String,
    $twilioAuthToken: String,
    $twilioPhone: String,
    $subtractTime: String,
    $maxDistance: String,
    $maxUploadSize: String,
    $stripePublishableKey: String,
    $fcmPushNotificationKey: String,
    $fcmApiKey: String,
    $fcmAuthDomain: String,
    $fcmProjectId: String,
    $fcmStorageBucket: String,
    $fcmMessagingSenderId: String,
    $fcmAppId: String,
    $fcmMeasurementId: String,
    $fcmVapidKey: String,
    $enableFirebase: String
   
) {
    updateConfigSettings(
        smtpHost: $smtpHost,
        smtpPort: $smtpPort,
        smptEmail: $smptEmail,
        smtpSender: $smtpSender,
        smtpSenderEmail: $smtpSenderEmail,
        smtpPassWord: $smtpPassWord,
        twilioAccountSid: $twilioAccountSid,
        twilioAuthToken: $twilioAuthToken,
        twilioPhone: $twilioPhone,
        maxUploadSize: $maxUploadSize,
        stripePublishableKey: $stripePublishableKey
        subtractTime: $subtractTime,
        maxDistance: $maxDistance,
        fcmPushNotificationKey: $fcmPushNotificationKey,
        fcmApiKey: $fcmApiKey,
        fcmAuthDomain : $fcmAuthDomain,
        fcmProjectId : $fcmProjectId,
        fcmStorageBucket: $fcmStorageBucket,
        fcmMessagingSenderId: $fcmMessagingSenderId,
        fcmAppId: $fcmAppId,
        fcmMeasurementId: $fcmMeasurementId,
        fcmVapidKey:  $fcmVapidKey,
        enableFirebase: $enableFirebase
    ){
        status
        errorMessage
    }
}`;

export const getCurrency = gql`query getCurrency($currentPage: Int, $searchList: String){
    getCurrency(currentPage: $currentPage, searchList:$searchList){
      count
      currencyList{
          id
          symbol
          isEnable
          isPayment
          isBaseCurrency
      }
    }
  }`;

export const getCurrencyRatesQuery = gql`{
    getCurrencyRates {
        base
        rates
    }
  }`;

export const updateCurrencyMutation = gql`mutation updateCurrency ($id: Int, $isEnable: Boolean, $symbol: String){
  updateCurrency(id: $id, isEnable: $isEnable, symbol: $symbol){
        status
        errorMessage
    }
}`;

export const setBaseCurrencyMutation = gql`mutation setBaseCurrency($id: Int){
  setBaseCurrency(id: $id){
        status
    }
}`;

export const allowPaymentCurrencyMutation = gql`mutation allowPaymentCurrency($id: Int,$isPayment: Int){
  allowPaymentCurrency(id: $id,isPayment: $isPayment){
        status
    }
}`;

export const updatePartnerHomepageMutation = gql`
mutation updatePartnerHomepage (
    $bannerTitle1: String,
    $bannerTitle2: String,
    $bannerContent1: String,
    $bannerImage1: String,
    $sliderTitle1: String,
    $sliderContent1: String,
    $sliderImage1: String,
    $sliderTitle2: String,
    $sliderContent2: String,
    $sliderImage2: String,
    $sliderTitle3: String,
    $sliderContent3: String,
    $sliderImage3: String,
    $sliderTitle4: String,
    $sliderContent4: String,
    $sliderTitle5: String,
    $infoTitle1: String,
    $infoImage1: String,
    $infoTitle2: String,
    $infoImage2: String,
    $infoTitle3: String,
    $infoImage3: String,
    $infoTitle4: String,
    $infoContent4: String,
    $buttonName: String,
    $buttonLink: String,
    $sliderImage5: String
) {
  updatePartnerHomepage (
    bannerTitle1: $bannerTitle1,
    bannerTitle2: $bannerTitle2,
    bannerContent1: $bannerContent1,
    bannerImage1: $bannerImage1,
    sliderTitle1: $sliderTitle1,
    sliderContent1: $sliderContent1,
    sliderImage1: $sliderImage1,
    sliderTitle2: $sliderTitle2,
    sliderContent2: $sliderContent2,
    sliderImage2: $sliderImage2,
    sliderTitle3: $sliderTitle3,
    sliderContent3: $sliderContent3,
    sliderImage3: $sliderImage3,
    sliderTitle4: $sliderTitle4,
    sliderContent4: $sliderContent4,
    sliderTitle5: $sliderTitle5,
    infoTitle1: $infoTitle1,
    infoImage1: $infoImage1,
    infoTitle2: $infoTitle2,
    infoImage2: $infoImage2,
    infoTitle3: $infoTitle3,
    infoImage3: $infoImage3,
    infoTitle4: $infoTitle4,
    infoContent4: $infoContent4,
    buttonName: $buttonName,
    buttonLink: $buttonLink,
    sliderImage5: $sliderImage5
  ) {
    status
  }
}`;

export const getAllCommonSettingsData = gql`
{
  getAllCommonSettingsData {
    status
    errorMessage
    count
    results {
      id
      typeName
      typeLabel
      fieldType
      isEnable
      step
    }
  }
}
`;

export const getCurrenciesQuery = gql`
{
  getCurrencies {
    id
    symbol
    isEnable
    isPayment
    isBaseCurrency
    status
  }
}
`;

export const getConfigSettingsData = `query getConfigSettings($name: String) {
  getConfigSettings(name: $name) {
      title
      name
      value
  }
}`;
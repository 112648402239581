import showToaster from '../../../helpers/showToaster';
import history from '../../../history';
import { getAllShopModifier } from './getAllShopModifier';
import { updateModifierMutation } from '../../lib/graphql';
import {
	SHOP_MODIFIER_UPDATE_START,
	SHOP_MODIFIER_UPDATE_SUCCESS,
	SHOP_MODIFIER_UPDATE_ERROR
} from '../../../constants/index';

const addModifier = (values) => {
	return async (dispatch, getState, { client }) => {

		dispatch({
			type: SHOP_MODIFIER_UPDATE_START,
			payload: {
				updateLoading: true
			}
		});
		try {
			if (values?.id) {
				const { data } = await client.mutate({
					mutation: updateModifierMutation,
					variables: values
				});

				if (data.updateModifierGroup?.status == 200) {

					dispatch({
						type: SHOP_MODIFIER_UPDATE_SUCCESS,
						payload: {
							updateLoading: false
						}
					});
					showToaster({ messageId: 'modifierChangesUpdated', toasterType: 'success' })
					history.push('/restaurant/menu/modifier-groups');
				} else {

					dispatch({
						type: SHOP_MODIFIER_UPDATE_ERROR,
						payload: {
							updateLoading: false
						}
					});
					showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: updateModifierGroup?.errorMessage })
				}
			} else {
				const { data } = await client.mutate({
					mutation: updateModifierMutation,
					variables: values
				});

				if (data.updateModifierGroup?.status === 200) {

					dispatch({
						type: SHOP_MODIFIER_UPDATE_SUCCESS,
						payload: {
							updateLoading: false
						}
					});
					showToaster({ messageId: 'modifierChangesCreated', toasterType: 'success' })
					history.push('/restaurant/menu/modifier-groups');
				} else {

					dispatch({
						type: SHOP_MODIFIER_UPDATE_ERROR,
						payload: {
							updateLoading: false
						}
					});
					showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: updateModifierGroup?.errorMessage })
				}
			}
			dispatch(getAllShopModifier());

		} catch (error) {
			showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: error })
			dispatch({
				type: SHOP_MODIFIER_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
}

export default addModifier;
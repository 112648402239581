import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './FoodChoiceForm.css';
import { Modal } from 'react-bootstrap';

import { connect } from 'react-redux';
import { closeFoodChoiceModal } from '../../../actions/modalActions';
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import FoodChoiceForm from './FoodChoiceForm';

class FoodChoiceModal extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        const { modalStatus, closeFoodChoiceModal, paginationData } = this.props;
        return (
            <div>
                <Modal show={modalStatus} animation={false} onHide={closeFoodChoiceModal} >
                    <div>
                        <Modal.Header closeButton>
                            <Modal.Title><FormattedMessage {...messages.foodChoice} /></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FoodChoiceForm paginationData={paginationData}/>
                        </Modal.Body>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapState = state => ({
    modalStatus: state.modalStatus.isFoodChoiceModalOpen,
})

const mapDispatch = {
    closeFoodChoiceModal
};

export default withStyles(s)(connect(mapState, mapDispatch)(FoodChoiceModal))
import { initialize } from 'redux-form';
import showToaster from '../../../helpers/showToaster';
import { getSubMenu as query } from '../../lib/graphql/restaurant';
import { openSubMenuModal } from '../../../actions/siteadmin/modalActions';

export default function editItem(id) {
	return async (dispatch, getState, { client }) => {
		try {

			const { data } = await client.query({
				query,
				variables: {
					id
				},
				fetchPolicy: 'network-only'
			});

			if (data?.getSubMenu) {
				dispatch(initialize('AddSubMenuForm', data?.getSubMenu));
				dispatch(openSubMenuModal());

			} else {
				showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.getSubMenu?.errorMessage })
			}
		} catch (err) {
			showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: err })
		}
	}
};

import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
//Style
import cx from 'classnames';
import {
    Form,
    Col,
    Row,
    FormControl,
    FormGroup,
    Container
} from 'react-bootstrap';

import Loader from '../../../Common/Loader/Loader';
import ImageUploadComponent from '../../../ImageUploadComponent/ImageUploadComponent';

import messages from '../../../../locale/messages';
import validate from './validate';
import { api, partnerHomepageUploadDir } from '../../../../config';

import s from './BannerForm.css';
export class BannerForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }

    render() {
        const { handleSubmit, bannerImage1, loading, submitting, initialValues, bannerImageLoader } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col lg={8} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <h1 className={s.titleTextAdmin}>{formatMessage(messages.bannerSection)}</h1>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12} className={s.space2}>
                                                <Form.Group className={s.space3}>
                                                    <div className={s.profileImgSection}>
                                                        <ImageUploadComponent
                                                            className={cx(s.btnSecondary, s.profileNoPadding)}
                                                            subTextClass={s.subText}
                                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                            defaultMessage={formatMessage(messages.chooseFile)}
                                                            loaderName={'bannerImage1Loader'}
                                                            postUrl={api.apiEndpoint + '/uploadPartnerHomepageImage'}
                                                            loader={bannerImageLoader}
                                                            fieldName={'bannerImage1'}
                                                            formName={'BannerForm'}
                                                            imageSrc={api.apiEndpoint + partnerHomepageUploadDir+ 'medium_' + bannerImage1}
                                                            inputContainer={'.dzInputContainerRestaurantImage'}
                                                            inputContainerClass={'dzInputContainerRestaurantImage'}
                                                            label={formatMessage(messages.imageLabel)}
                                                            isDefaultImg={bannerImage1 ? false : true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <Field name="bannerTitle1" type="text" component={this.renderField} label={formatMessage(messages.title1Label)} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <Field name="bannerTitle2" type="text" component={this.renderField} label={formatMessage(messages.title2Label)} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <Field name="bannerContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.description1Label)} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                                <Form.Group className={s.noMargin}>
                                                    <div className={s.displayInlineBlock}>
                                                        <Loader
                                                            type={"button"}
                                                            label={formatMessage(messages.submitButton)}
                                                            show={loading}
                                                            buttonType={'submit'}
                                                            className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                                                            disabled={submitting || loading}
                                                            isSuffix={true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

BannerForm = reduxForm({
    form: 'BannerForm',
    onSubmit: submit,
    validate
})(BannerForm);

const selector = formValueSelector('BannerForm')

const mapState = (state) => ({
    bannerImage1: selector(state, 'bannerImage1'),
    loading: state.loader.UpdatePartnerHomepage,
    bannerImageLoader: state.loader.bannerImage1Loader
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(BannerForm)));
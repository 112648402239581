import showToaster from '../../../helpers/showToaster';
import { updateFoodStatusData as mutation, getOrders as query } from '../../lib/graphql';

export default function updateFoodStatus(id, isOrderDetailPage, status) {
    return async (dispatch, getState, { client }) => {
        try {
            let refetchQueries = [];

            if (isOrderDetailPage) {
                refetchQueries = [{
                    query,
                    variables: { id }
                }];
            }

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    status
                },
                refetchQueries
            });

            if (data?.updateFoodStatus?.status === 200) {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'success', requestContent: data?.updateFoodStatus?.successMessage })
            } else {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updateFoodStatus?.errorMessage });
            }
            return true;

        } catch (error) {
            showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: error });
        }
    }
};
import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import {
  Form,
  Col,
  Card,
  Row,
  FormControl,
  FormGroup,
  Badge,
  Container,
} from 'react-bootstrap';

import Link from '../../../Link/Link';
import Loader from '../../../Common/Loader/Loader';
import PlacesSuggest from '../../../Shop/PlacesSuggest/PlacesSuggest';
import CountryList from '../../../Shop/CountryList/CountryList';
import CustomCheckbox from '../../../Common/CustomCheckbox/CustomCheckbox';
import ImageUploadComponent from '../../../ImageUploadComponent/ImageUploadComponent';

import submit from './submit';
import messages from '../../../../locale/messages';
import validate from './validate';
import { api, restaurantImageUploadDir } from '../../../../config';

import s from './EditRestaurantForm.css';

export class EditRestaurantForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneCountryCode: '',
      phoneDialCode: '',
      defaultPreparationTime: 55,
    };
  }

  static defaultProps = {
    loading: false,
  };

  componentWillMount() {
    const { initialValues } = this.props;
    const { defaultPreparationTime } = this.state;
    let preparationTime = initialValues?.shopEstimatedTime
      ? initialValues?.shopEstimatedTime
      : defaultPreparationTime;

    this.setState({
      phoneCountryCode: initialValues?.phoneCountryCode,
      phoneDialCode: initialValues?.phoneDialCode,
      defaultPreparationTime: preparationTime,
    });
  }

  renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    labelClass,
    fieldClass,
    placeholder,
    disabled,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          {...input}
          placeholder={placeholder}
          type={type}
          className={fieldClass}
          disabled={disabled}
        />
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </Form.Group>
    );
  };

  renderFieldPhoneNumber = ({
    input,
    label,
    writeOnce,
    type,
    meta: { touched, error },
    fieldClass,
    placeholder,
    disabled,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group>
        <Form.Control
          {...input}
          readOnly={writeOnce}
          placeholder={placeholder}
          type={type}
          className={fieldClass}
          disabled={disabled}
        />
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </Form.Group>
    );
  };

  renderFormControlTextArea = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.space3}>
          <div>
            <label className={s.labelText}>{label}</label>
          </div>
          <div>
            <FormControl
              {...input}
              className={cx(s.inputNoRadius, className)}
              placeholder={label}
              as="textarea"
              rows="4"
            >
              {children}
            </FormControl>
            {touched && error && (
              <span className={s.errorMessage}>{formatMessage(error)}</span>
            )}
          </div>
        </FormGroup>
      </div>
    );
  };

  checkboxGroup = ({
    label,
    name,
    options,
    input,
    meta: { touched, error },
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={'adminCheckbox'}>
        <ul className={s.listStyle}>
          {options?.map((option, index) => {
            if (option.isActive) {
              return (
                <li className={s.listSectionWidth} key={index}>
                  <span className={s.checkBoxSection}>
                    <CustomCheckbox
                      key={index}
                      className={'icheckbox_square-green'}
                      name={`${input.name}[${index}]`}
                      value={option?.id}
                      checked={input?.value.indexOf(option?.id) !== -1}
                      onChange={event => {
                        const newValue = [...input.value];
                        if (event === true) {
                          newValue?.push(option?.id);
                        } else {
                          newValue?.splice(newValue?.indexOf(option?.id), 1);
                        }
                        input.onChange(newValue);
                      }}
                    />
                  </span>
                  <span className={cx(s.checkBoxLabel, 'checkBoxLabelRTL')}>
                    {option?.categoryName}
                  </span>
                </li>
              );
            }
          })}
        </ul>
        {error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };

  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl as="select" {...input} className={className}>
          {children}
        </FormControl>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };

  checkboxHorizontalGroup = ({ label, input, checked, meta: { error } }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={'adminCheckbox'}>
        <div className={s.flexCheckform}>
          <CustomCheckbox
            className={'icheckbox_square-green'}
            onChange={event => {
              input.onChange(event);
            }}
            checked={checked}
            value={true}
          />
          <span className={cx(s.checkBoxLabel, 'checkBoxLabelRTL')}>
            {label}
          </span>
        </div>
        {error && (
          <span className={cx(s.errorMessage, s.errorTop)}>
            {formatMessage(error)}
          </span>
        )}
      </div>
    );
  };

  handleLocationData = async location => {
    const { change } = this.props;

    let addressLabel, addressPosition, locationArray;
    addressLabel = location?.label;
    addressPosition = location?.location;
    locationArray = addressLabel
      .split(',')
      .slice(-3)
      .reverse();
    await Promise.all([
      change('address', addressLabel),
      change('city', locationArray[2]?.trim()),
      change('state', locationArray[1]?.trim()),
      change('country', locationArray[0]?.trim()),
      change('lat', addressPosition?.lat),
      change('lng', addressPosition?.lng),
    ]);
  };

  handleCountryChange = async country => {
    const { change } = this.props;

    this.setState({
      phoneCountryCode: country?.countryCode,
      phoneDialCode: country?.dialCode,
    });
    await Promise.all([
      change('phoneCountryCode', country?.countryCode),
      change('phoneDialCode', country?.dialCode),
    ]);
  };

  handleDropzone = (fileName, fieldName) => {
    const { change } = this.props;
    change(fieldName, fileName);
  };

  handleDefaultPreparationTime = action => {
    const { defaultPreparationTime } = this.state;
    const { change } = this.props;

    let preparationTime =
      action == 'add' ? defaultPreparationTime + 5 : defaultPreparationTime - 5;

    if (preparationTime <= 55 && preparationTime >= 5) {
      this.setState({ defaultPreparationTime: preparationTime });
      change('shopEstimatedTime', preparationTime);
    }
  };

  render() {
    const {
      handleSubmit,
      loading,
      submitting,
      initialValues,
      getCuisine,
      getDietary,
      getShop,
      imageLoader,
    } = this.props;
    const {
      availableCurrencies,
      picture,
      isDoorDelivery,
      isTakeAway,
    } = this.props;
    const {
      phoneCountryCode,
      phoneDialCode,
      defaultPreparationTime,
    } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.space5, s.spaceTop5, 'tabLabelText')}>
        <Container fluid>
          <Row>
            <Col
              xl={10}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={s.blackCenter}
            >
              <div className={s.blackCenterSection}>
                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <h1 className={s.titleTextAdmin}>
                      {formatMessage(messages.editRestaurant)}
                      <span
                        className={cx(s.userStatusBadge, 'userStatusBadgeRTL')}
                      >
                        <Badge
                          pill
                          variant={
                            getShop?.isActive === 1 && getShop?.isBan === 0
                              ? 'success'
                              : 'danger'
                          }
                        >
                          {getShop?.isActive === 1 && getShop?.isBan === 0
                            ? formatMessage(messages.online)
                            : formatMessage(messages.offline)}
                        </Badge>{' '}
                        {getShop?.isActive === 1 && getShop?.isBan === 0 && (
                          <Badge
                            pill
                            variant={
                              getShop?.activeStatus === 'active'
                                ? 'success'
                                : 'danger'
                            }
                          >
                            {getShop?.activeStatus === 'active'
                              ? formatMessage(messages.eligibleForAOrder)
                              : formatMessage(messages.notEligibleForAOrder)}
                          </Badge>
                        )}
                      </span>
                    </h1>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <Row>
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className={cx(s.space3)}>
                              <div className={cx(s.profileImgSection)}>
                                <ImageUploadComponent
                                  className={cx(s.btnSecondary, s.profileNoPadding)}
                                  subTextClass={s.subText}
                                  subText={formatMessage(messages.maximumUploadSizeLabel)}
                                  defaultMessage={formatMessage(messages.chooseFile)}
                                  loaderName={'profileImageLoader'}
                                  postUrl={api.apiEndpoint + '/uploadRestaurantImage'}
                                  loader={imageLoader}
                                  fieldName={'picture'}
                                  imageSrc={api.apiEndpoint + restaurantImageUploadDir + picture}
                                  label={formatMessage(messages.restaurantImage)}
                                  inputContainer={'.dzInputContainerRestaurantImage'}
                                  inputContainerClass={'dzInputContainerRestaurantImage'}
                                  handleDropzone={this.handleDropzone}
                                  isDefaultImg={picture ? false : true}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <div>
                                <Field
                                  name="shopName"
                                  type="text"
                                  placeholder={formatMessage(messages.shopName)}
                                  component={this.renderField}
                                  label={formatMessage(messages.shopName)}
                                  labelClass={s.labelText}
                                  fieldClass={s.formControlInput}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <div>
                                <Field
                                  name="description"
                                  type="text"
                                  placeholder={formatMessage(
                                    messages.restaurantDescription,
                                  )}
                                  component={this.renderFormControlTextArea}
                                  label={formatMessage(
                                    messages.restaurantDescription,
                                  )}
                                  labelClass={s.labelText}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <div>
                                <Field
                                  name="firstName"
                                  type="text"
                                  placeholder={formatMessage(
                                    messages.firstName,
                                  )}
                                  component={this.renderField}
                                  label={formatMessage(messages.firstName)}
                                  labelClass={s.labelText}
                                  fieldClass={s.formControlInput}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <div>
                                <Field
                                  name="lastName"
                                  type="text"
                                  placeholder={formatMessage(messages.lastName)}
                                  component={this.renderField}
                                  label={formatMessage(messages.lastName)}
                                  labelClass={s.labelText}
                                  fieldClass={s.formControlInput}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={5} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <div>
                                <Field
                                  name="email"
                                  type="text"
                                  placeholder={formatMessage(messages.email)}
                                  component={this.renderField}
                                  label={formatMessage(messages.email)}
                                  labelClass={s.labelText}
                                  fieldClass={s.formControlInput}
                                  disabled
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col xl={7} lg={12} md={12} sm={12} xs={12}>
                            <Row>
                              <Col lg={12} md={12} sm={12} xs={12}>
                                <div>
                                  <label className={s.labelText}>
                                    {formatMessage(messages.phoneNumber)}
                                  </label>
                                </div>
                              </Col>
                              <Col
                                xl={5}
                                lg={5}
                                md={12}
                                sm={12}
                                xs={12}
                                className={s.space2}
                              >
                                {phoneCountryCode && (
                                  <CountryList
                                    className={cx(
                                      s.formControlSelect,
                                      s.formControlInput,
                                      s.space1,
                                    )}
                                    defaultCountry={phoneCountryCode}
                                    handleCountryChange={
                                      this.handleCountryChange
                                    }
                                    disabled
                                  />
                                )}
                              </Col>
                              <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                                <Form.Group className={s.noMargin}>
                                  <div className={s.countrySection}>
                                    <span className={s.space1}>
                                      {phoneDialCode}
                                    </span>
                                  </div>
                                  <div className={s.phoneNumberSection}>
                                    <Field
                                      name="phoneNumber"
                                      type="text"
                                      placeholder={formatMessage(
                                        messages.phoneNumber,
                                      )}
                                      component={this.renderFieldPhoneNumber}
                                      fieldClass={cx(
                                        s.formControlInput,
                                        s.inputRadius,
                                      )}
                                      disabled
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <div
                                className={cx(
                                  'siteAdminProfile',
                                  'adminEditForm',
                                )}
                              >
                                <label>{formatMessage(messages.address)}</label>
                                <PlacesSuggest
                                  label={formatMessage(messages.address)}
                                  className={s.space3}
                                  handleData={this.handleLocationData}
                                  defaultValue={initialValues?.address}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={4} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <div>
                                <Field
                                  name="zipcode"
                                  type="text"
                                  placeholder={formatMessage(messages.zipcode)}
                                  component={this.renderField}
                                  label={formatMessage(messages.zipcode)}
                                  labelClass={s.labelText}
                                  fieldClass={s.formControlInput}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={4} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <div>
                                <Field
                                  name="businessId"
                                  type="text"
                                  placeholder={formatMessage(messages.businessId)}
                                  component={this.renderField}
                                  label={formatMessage(messages.businessId)}
                                  labelClass={s.labelText}
                                  fieldClass={s.formControlInput}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={4} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <div>
                                <Field
                                  name="healthMinistryId"
                                  type="text"
                                  placeholder={formatMessage(messages.healthMinistryId)}
                                  component={this.renderField}
                                  label={formatMessage(messages.healthMinistryId)}
                                  labelClass={s.labelText}
                                  fieldClass={s.formControlInput}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={4} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <Form.Label>
                                {formatMessage(messages.priceRange)}
                              </Form.Label>
                              <Field
                                name="priceRange"
                                type="Select"
                                className={cx(
                                  s.formControlSelect,
                                  s.formControlSelectWidth,
                                  s.commonBorder,
                                  s.formControlInput,
                                )}
                                component={this.renderFormControlSelect}
                                placeholder={formatMessage(messages.priceRange)}
                              >
                                <option value="">
                                  {formatMessage(messages.choosePriceRange)}
                                </option>
                                <option value={1}>$</option>
                                <option value={2}>$$</option>
                                <option value={3}>$$$</option>
                                <option value={4}>$$$$</option>
                              </Field>
                            </Form.Group>
                          </Col>
                          <Col xl={4} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <Form.Label>
                                {formatMessage(messages.dietaryType)}
                              </Form.Label>
                              <Field
                                name="dietaryType"
                                className={cx(
                                  s.formControlSelect,
                                  s.formControlInput,
                                )}
                                component={this.renderFormControlSelect}
                                placeholder={formatMessage(
                                  messages.dietaryType,
                                )}
                              >
                                <option value="">
                                  {formatMessage(messages.chooseDietary)}
                                </option>
                                {getDietary?.settings?.map(item => (
                                  <option value={item?.id}>
                                    {item?.itemName}
                                  </option>
                                ))}
                              </Field>
                            </Form.Group>
                          </Col>
                          <Col xl={4} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <div>
                                <Field
                                  name="password"
                                  type="text"
                                  placeholder={formatMessage(messages.password)}
                                  component={this.renderField}
                                  label={formatMessage(messages.password)}
                                  labelClass={s.labelText}
                                  fieldClass={s.formControlInput}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <label className={s.labelNumberText}>
                              {formatMessage(messages.DeliveryTypeText)}
                            </label>
                            <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <Form.Group className={s.formGroup}>
                                  <Field
                                    name="isDoorDelivery"
                                    component={this.checkboxHorizontalGroup}
                                    label={formatMessage(
                                      messages.isDoorDelivery,
                                    )}
                                    checked={isDoorDelivery}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <Form.Group className={s.formGroup}>
                                  <Field
                                    name="isTakeAway"
                                    component={this.checkboxHorizontalGroup}
                                    label={formatMessage(messages.isTakeAway)}
                                    checked={isTakeAway}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className={s.space3}>
                              <label className={s.labelNumberText}>
                                {formatMessage(messages.category)}
                              </label>
                              <Field
                                name="categoryType"
                                component={this.checkboxGroup}
                                options={getCuisine?.result}
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className={s.space3}>
                              <div>
                                <label>
                                  {formatMessage(messages.userStatus)}
                                </label>
                                <br />
                                <Field
                                  name="userStatus"
                                  className={cx(
                                    s.formControlSelect,
                                    s.formControlInput,
                                  )}
                                  component="select"
                                >
                                  <option value="pending">
                                    {formatMessage(messages.pending)}
                                  </option>
                                  <option value="active">
                                    {formatMessage(messages.active)}
                                  </option>
                                  <option value="inactive">
                                    {formatMessage(messages.inactive)}
                                  </option>
                                </Field>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={6} xs={12}>
                            <Form.Group
                              className={s.space3}
                              controlId="exampleForm.ControlSelect1"
                            >
                              <div>
                                <label>
                                  {formatMessage(messages.banStatus)}
                                </label>
                                <br />
                                <Field
                                  name="isBan"
                                  className={cx(
                                    s.formControlSelect,
                                    s.formControlInput,
                                  )}
                                  component="select"
                                >
                                  <option value="0">
                                    {formatMessage(messages.permit)}
                                  </option>
                                  <option value="1">
                                    {formatMessage(messages.ban)}
                                  </option>
                                </Field>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={cx(
                      s.textAlignRight,
                      s.spaceTop1,
                      'textAlignLeftRTL',
                    )}
                  >
                    <Form.Group className={s.noMargin}>
                      <div className={s.displayInlineBlock}>
                        <Loader
                          type={'button'}
                          label={formatMessage(messages.update)}
                          show={loading}
                          buttonType={'submit'}
                          className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                          disabled={submitting || loading}
                          isSuffix={true}
                        />
                      </div>
                      <Link
                        to={'/siteadmin/restaurant/list'}
                        className={cx(
                          s.backBtn,
                          s.btnSecondary,
                          s.linkBtnInline,
                          'backBtnRTL',
                        )}
                      >
                        {formatMessage(messages.goBack)}
                      </Link>
                    </Form.Group>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

EditRestaurantForm = reduxForm({
  form: 'EditRestaurantForm',
  onSubmit: submit,
  validate,
})(EditRestaurantForm);

const selector = formValueSelector('EditRestaurantForm');

const mapState = state => ({
  loading: state?.loader?.UpdateRestaurant,
  picture: selector(state, 'picture'),
  availableCurrencies: state?.currency?.availableCurrencies,
  isDoorDelivery: selector(state, 'isDoorDelivery'),
  isTakeAway: selector(state, 'isTakeAway'),
  imageLoader: state.loader.profileImageLoader,
});

const mapDispatch = {
  change
};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(EditRestaurantForm)),
);

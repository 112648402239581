import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import ViewBookingTransactionHistory from './ViewBookingTransactionHistory';
import { restrictUrls } from '../../../helpers/adminPrivileges';
import messages from '../../../locale/messages';

function action({ store, intl, params }) {
    const title = intl.formatMessage(messages.bookingPaymentDetails);

    let isAdminAuthenticated = store.getState()?.runtime?.isAdminAuthenticated;
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }
    // Admin restriction
    if ((!restrictUrls('/siteadmin/transaction-history/completed-bookings/', adminPrivileges, privileges)) && (!restrictUrls('/siteadmin/transaction-history/failed-bookings/', adminPrivileges, privileges)) && (!restrictUrls('/siteadmin/transaction-history/bookings/', adminPrivileges, privileges))) {
        return { redirect: '/siteadmin' };
    }
    const id = Number(params.id);
    const pageType = params.pageType;

    return {
        title,
        component: (
            <AdminLayout>
                <ViewBookingTransactionHistory title={title} id={id} pageType={pageType} />
            </AdminLayout>
        )
    }
}

export default action;
import { initialize } from 'redux-form';
import showToaster from '../../../helpers/showToaster';
import { getItemQuery } from '../../lib/graphql';
import {
	SHOP_ITEM_UPDATE_START,
	SHOP_ITEM_UPDATE_SUCCESS,
	SHOP_ITEM_UPDATE_ERROR
} from '../../../constants/index';

export default function editItem(id) {
	return async (dispatch, getState, { client }) => {

		try {
			dispatch({
				type: SHOP_ITEM_UPDATE_START,
				payload: {
					updateLoading: true
				}
			});

			let errorMessage;

			const { data: { getItem } } = await client.query({
				query: getItemQuery,
				variables: {
					id
				},
				fetchPolicy: 'network-only',
			});

			if (getItem) {
				dispatch(initialize('AddItemForm', getItem));
				dispatch({
					type: SHOP_ITEM_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
			} else {
				dispatch({
					type: SHOP_ITEM_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});

				errorMessage = getItem?.errorMessage;
				showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: errorMessage })
			}
		} catch (err) {
			showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: err })
			dispatch({
				type: SHOP_ITEM_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};

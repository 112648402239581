import showToaster from '../../helpers/showToaster';
import { uploadProfileImageMutation as mutation } from '../lib/graphql';
import {
  PROFILE_IMAGE_UPLOAD_ERROR,
  PROFILE_IMAGE_UPLOAD_START,
  PROFILE_IMAGE_UPLOAD_SUCCESS
} from '../../constants/index'
import { removeImage } from '../../helpers/removeImage';

const uploadProfileImage = (id, fileName, oldFile) => {
  return async (dispatch, getState, { client }) => {
    try {
      let url = "/deleteProfileImage";

      dispatch({
        type: PROFILE_IMAGE_UPLOAD_START
      });
      const { data } = await client.mutate({
        mutation,
        variables: {
          userId: id,
          picture: fileName
        }
      });

      if (oldFile !== undefined) {
        removeImage(url, oldFile)
      };

      if (data?.uploadProfileImage?.status == "200") {
        dispatch({
          type: PROFILE_IMAGE_UPLOAD_SUCCESS
        });
        showToaster({ messageId: 'profileImageUploaded', toasterType: 'success' });

      } else {
        dispatch({
          type: PROFILE_IMAGE_UPLOAD_ERROR
        });
      }
    } catch (err) {
      dispatch({
        type: PROFILE_IMAGE_UPLOAD_ERROR
      });
    }
  };
};

export default uploadProfileImage;
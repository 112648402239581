import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { flowRight as compose } from 'lodash';
import cx from 'classnames';

import { api, profilePhotouploadDir } from '../../../../config';

import chatIcon from '../../../../../public/Icons/chatIcon.svg';
import defaultProfile from '../../../../../public/RestaurantIcon/chatDefaultProfile.png';
import batchIcon from '../../../../../public/Icons/driverChatIcon.svg';

import s from './UserDetails.css';
import bt from '../../../../components/commonStyle.css';
class UserDetails extends React.Component {
  render() {
    const { userDetails, deliveryPartnerDetails } = this.props;
    let userImage = userDetails?.profile?.picture ? api.apiEndpoint + profilePhotouploadDir + 'small_' + userDetails?.profile?.picture : defaultProfile,
      driverImage = deliveryPartnerDetails?.profile?.picture ? api.apiEndpoint + profilePhotouploadDir + 'small_' + deliveryPartnerDetails?.profile?.picture : defaultProfile;
    return (
      <div className={cx(s.containerWidth, s.hiddenXs, 'bgBlack')}>
        <div className={cx(s.displayGrid)}>
          <div className={cx(s.textAlignRight, 'textAlignLeftRTL')}>{userDetails?.profile?.firstName}</div>
          <div className={cx(s.dFlex, s.userAndHostImgContainer)}>
            <img src={userImage} className={s.icon} />
            <div className={s.centerChatIcon}>
              <img src={chatIcon} responsive />
            </div>
            <div className={s.positionRelative}>
              <img src={driverImage} className={s.icon} />
              <img src={batchIcon} className={cx(s.batchIcon, 'driverBatchIconRTL')} />
            </div>
          </div>
          <div className={cx(s.textAlignLeft, 'textAlignRightRTL')}>{deliveryPartnerDetails?.profile?.firstName}</div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(s, bt)
)(UserDetails);
import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import ManageFoodChoice from './ManageFoodChoice';
import messages from '../../../locale/messages';
import { restrictUrls } from '../../../helpers/adminPrivileges';

function action({ store, intl }) {
    const title = intl.formatMessage(messages.manageFoodChoices);

    //From Redux Store
    let isAdminAuthenticated = store.getState()?.runtime?.isAdminAuthenticated;
    let adminPrivileges =  store.getState()?.account?.privileges?.privileges;
    let privileges =  store.getState()?.commonSettings?.privileges;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }

    if (!restrictUrls('/siteadmin/food-choices', adminPrivileges, privileges)) {
      return { redirect: '/siteadmin' };
  }
    
    return {
        title,
        component: (
            <AdminLayout>
               <ManageFoodChoice title={title}/>
            </AdminLayout>
        ),
    }
}

export default action;
import showToaster from '../../../helpers/showToaster';
import { getPaymentMethods as query } from '../../lib/graphql';
import { processStripePayment } from '../../../core/payment/stripe/processStripePayment';
import { setLoaderComplete, setLoaderStart } from '../../loader/loader';
import {
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  ADD_PAYOUT_ERROR,
} from '../../../constants';

export function addPayout(
  payEmail,
  address1,
  address2,
  city,
  state,
  country,
  zipcode,
  currency,
  firstname,
  lastname,
  accountNumber,
  routingNumber,
  ssn4Digits,
  businessType,
  bankCode,
  branchCode,
  bankName,
  branchName,
  accountOwnerName,
  transitNumber,
  institutionNumber,
  bsbNumber,
  sortCode,
  accountToken,
  personToken
) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: ADD_PAYOUT_START
    });

    try {
      let userDetails, bankDetails;

      const { data } = await client.query({
        query,
        fetchPolicy: 'network-only'
      });

      if (data?.getPaymentMethods?.length > 0) {
        if (!data?.getPaymentMethods[0]?.isEnable) {
          dispatch({
            type: ADD_PAYOUT_ERROR,
          });
          showToaster({ messageId: 'featureNotAvailable', toasterType: 'warning' })
          return;
        }
      };

      dispatch(setLoaderStart('AddPayoutForm'));

      userDetails = {
        payEmail
      };

      bankDetails = {
        payEmail,
        address1,
        address2,
        city,
        state,
        country,
        zipcode,
        currency,
        firstname,
        lastname,
        accountNumber,
        routingNumber,
        ssn4Digits,
        businessType,
        bankCode,
        branchCode,
        bankName,
        branchName,
        accountOwnerName,
        transitNumber,
        institutionNumber,
        bsbNumber,
        sortCode,
        accountToken,
        personToken
      };

      const { status, errorMessage, accountId } = await processStripePayment(
        'addPayout',
        userDetails,
        bankDetails
      );

      if (status == '200' && accountId) {

        dispatch({
          type: ADD_PAYOUT_SUCCESS
        });
        dispatch(setLoaderComplete('AddPayoutForm'));

      } else {

        showToaster({ messageId: 'failedMessage', toasterType: 'error', requestContent: errorMessage })

        dispatch({
          type: ADD_PAYOUT_ERROR
        });
        dispatch(setLoaderComplete('AddPayoutForm'));
      }

    } catch (error) {

      dispatch({
        type: ADD_PAYOUT_ERROR
      });
      dispatch(setLoaderComplete('AddPayoutForm'));
    }
  };
};
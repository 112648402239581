import showToaster from '../../../helpers/showToaster';
import { updateCategoryImageMutation as mutation } from '../../lib/graphql';
import { removeImage } from '../../../helpers/removeImage';
import {
  CATEGORY_IMAGE_UPLOAD_START,
  CATEGORY_IMAGE_UPLOAD_SUCCESS,
  CATEGORY_IMAGE_UPLOAD_ERROR
} from '../../../constants';

export default function updateCategoryImage(id, fileName, oldPicture) {

  return async (dispatch, getState, { client }) => {
    try {

      dispatch({
        type: CATEGORY_IMAGE_UPLOAD_START,
        payload: {
          imageUploadLoading: true
        }
      })

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          fileName
        }
      });

      if (data?.updateCategoryImage?.status === 200) {

        dispatch({
          type: CATEGORY_IMAGE_UPLOAD_SUCCESS,
          payload: {
            imageUploadLoading: false
          }
        })
        showToaster({ messageId: 'cuisineImageUploaded', toasterType: 'success' })
      } else {

        dispatch({
          type: CATEGORY_IMAGE_UPLOAD_ERROR,
          payload: {
            imageUploadLoading: false
          }
        })
      }
      let url = "/deleteCategoryImage";

      if (oldPicture !== null) {
        removeImage(url, oldPicture)
      };

    } catch (err) {

      dispatch({
        type: CATEGORY_IMAGE_UPLOAD_ERROR,
        payload: {
          imageUploadLoading: false
        }
      })
    }
  }
};

import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import { api } from '../../../config';

import FoodBG from '../../../../public/SiteImages/bg-green.svg';

import s from './HomeBanner.css';

class RestaurantBanner extends React.Component {
    constructor(props) {
        super(props);
    }

    scrollTop = () => {
        window.scrollTo({
            top: screen.height,
            behavior: 'smooth'
        })
    }

    render() {
        const { data } = this.props;
        let homeSettings = {};

        data?.homePageData?.map((item) => {
            homeSettings[item.name] = item.value
        });

        return (
            <div className={cx(s.layout4Container)}>
                <div className={cx(s.bannerBackgroundImage)}
                    style={{ backgroundImage: `url(${api.apiEndpoint + "/images/homepage/" + homeSettings?.homeSectionImage1})` }} />
                <Container className={cx(s.bannerLayoutContainer)}>
                    <Row>
                        <div className={s.bannerDisplayGrid}>
                            <div className={s.searchFormContainer}>
                                <div className={s.searchFormWrap}>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <h1 className='homeBannerTextEllipsis'>
                                            {homeSettings?.homeSectionTitle1}
                                        </h1>
                                        <p>
                                            {homeSettings?.homeSectionDescription}
                                        </p>
                                    </Col>
                                </div>
                            </div>
                            <div className={s.formSection}>
                                <div className={s.platebgSection}>
                                    <img src={FoodBG} className={s.platebg} />
                                </div>
                                <div className={cx(s.plateZoom, 'plateZoomRTL')}>
                                    <img src={api.apiEndpoint + "/images/homepage/" + homeSettings?.homeSectionImage2} className={s.plateImage} />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapState = (state) => ({});

const mapDispatch = {};

export default withStyles(s)(connect(mapState, mapDispatch)(RestaurantBanner));
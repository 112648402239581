import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl } from 'react-bootstrap';
import s from './CurrencyList.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import messages from '../../../locale/messages';
import CustomPagination from '../../CustomPagination';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import Link from '../../Link/Link';
import { connect } from 'react-redux';
import { updateCurrencyStatus, setBaseCurrency, allowPaymentCurrency } from '../../../actions/siteadmin/Currency/updateCurrency';
import debounce from '../../../helpers/debounce';

export class CurrencyList extends Component {

	static propTypes = {
		CurrencyData: PropTypes.object,
		updateCurrencyStatus: PropTypes.any.isRequired,
		setBaseCurrency: PropTypes.any.isRequired,
		managePaymentCurrency: PropTypes.any.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			currentPage: 1,
			searchList: ''
		}
		this.handleSearchChange = debounce(this.handleSearchChange.bind(this), 250);
		this.paginationData = this.paginationData.bind(this)
	}

	handleSearchChange(searchList) {
		const { CurrencyData: { refetch } } = this.props
		let variables = {
			currentPage: 1,
			searchList
		}
		this.setState({ currentPage: 1, searchList })
		refetch(variables)
	}

	async handleUpdateStatus(id, status, symbol) {
		const { updateCurrencyStatus } = this.props;
		const { currentPage } = this.state;
		updateCurrencyStatus(id, status, symbol, currentPage);

	}

	async handleBaseCurrency(id) {
		const { setBaseCurrency, CurrencyData: { refetch } } = this.props;
		await setBaseCurrency(id);
		await refetch()
	}

	managePaymentCurrency(id, isPayment) {
		const { allowPaymentCurrency } = this.props;
		allowPaymentCurrency(id, isPayment);
	}

	paginationData(currentPage) {
		const { CurrencyData: { refetch } } = this.props;
		let variables = { currentPage };
		this.setState({ currentPage });
		refetch(variables);
	}

	render() {
		const { CurrencyData, CurrencyData: { getCurrency } } = this.props;
		const { currentPage } = this.state;
		const { formatMessage } = this.props.intl;

		return (
			<div className={cx(s.widthInner, s.tableSection, 'widthInnerNoBorder')}>
				<div className={cx(s.alignItemCenter, s.tabelExport)}>
					<div className={s.searchInput}>
						<FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleSearchChange(e.target && e.target.value)} className={s.formControlInput} />
					</div>
				</div>
				<div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
					<Table className="table">
						<thead>
							<tr>
								<th scope="col"><FormattedMessage {...messages.id} /></th>
								<th scope="col"><FormattedMessage {...messages.symbol} /></th>
								<th scope="col"><FormattedMessage {...messages.baseCurrency} /></th>
								<th scope="col"><FormattedMessage {...messages.status} /></th>
								<th scope="col"><FormattedMessage {...messages.enableDisable} /></th>
								<th scope="col"><FormattedMessage {...messages.setBaseCurrency} /></th>
							</tr>
						</thead>
						<tbody>
							{

								CurrencyData && CurrencyData.getCurrency && CurrencyData.getCurrency.currencyList && CurrencyData.getCurrency.currencyList.length > 0 && CurrencyData.getCurrency.currencyList.map((item, index) => {

									return (

										<tr key={index}>
											<td data-label={formatMessage(messages.id)}>{item.id}</td>
											<td data-label={formatMessage(messages.symbol)}>{item.symbol}</td>
											{item.isBaseCurrency == 1 && <td data-label={formatMessage(messages.baseCurrency)}>{formatMessage(messages.active)}</td>}
											{item.isBaseCurrency == 0 && <td data-label={formatMessage(messages.baseCurrency)}> &nbsp; </td>}
											{item.isEnable && <td data-label={formatMessage(messages.status)}>{formatMessage(messages.enabled)}</td>}
											{!item.isEnable && <td data-label={formatMessage(messages.status)}>{formatMessage(messages.disabled)}</td>}
											<td data-label={formatMessage(messages.enableDisable)}> <a href="javascript:void(0)" onClick={() => this.handleUpdateStatus(item.id, item.isEnable, item.symbol)} >
												{
													item.isEnable && <span> {formatMessage(messages.disable)} </span>
												}

												{
													!item.isEnable && <span> {formatMessage(messages.enable)} </span>
												}
											</a>
											</td>
											<td data-label={formatMessage(messages.setBaseCurrency)}>
												<span>
													{
														!item.isBaseCurrency && item.isEnable && <a href="javascript:void(0)" onClick={() => this.handleBaseCurrency(item.id)}>
															{formatMessage(messages.setAsBaseCurrency)}
														</a>
													}
													{
														(item.isBaseCurrency || !item.isEnable) && <span> &nbsp;</span>
													}
												</span>
											</td>
										</tr>
									)
								})
							}
							{
								((CurrencyData && CurrencyData.getCurrency && CurrencyData.getCurrency.currencyList.length == 0)) && (
									<tr>
										<td colSpan={50} className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
									</tr>
								)
							}
						</tbody>
					</Table>
				</div>
				{
					CurrencyData && CurrencyData.getCurrency && CurrencyData.getCurrency.currencyList && CurrencyData.getCurrency.currencyList.length > 0
					&& <div className={cx(s.space5, s.spaceTop5)}>
						<CustomPagination
							total={CurrencyData.getCurrency.count}
							currentPage={currentPage}
							defaultCurrent={1}
							defaultPageSize={10}
							change={this.paginationData}
							paginationLabel={formatMessage(messages.currenciesLabel)}
						/>
					</div>
				}
			</div >
		)
	}
}

const mapDispatch = {
	updateCurrencyStatus,
	setBaseCurrency,
	allowPaymentCurrency
};
const mapState = (state) => ({});

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(CurrencyList)));
import React, { Component } from 'react'
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo'
import withStyles from 'isomorphic-style-loader/withStyles';

import Loader from '../../../components/Common/Loader/Loader';
import StaticPageEditForm from '../../../components/SiteAdmin/StaticPageEditForm'

import getEditStaticPage from './getEditStaticPage.graphql'
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

import s from './EditStaticPage.css';
export class EditStaticPage extends Component {

  static defaultProps = {
    data: {
      loading: true
    }
  }

  render() {
    const { data: { loading, getEditStaticPage }, title } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            <div className={s.heading}>
              <FormattedMessage {...messages.editPageDetails} />
            </div>
            <div>

              {
                !loading && <StaticPageEditForm title={title} initialValues={getEditStaticPage && getEditStaticPage.result} />
              }
            </div>
          </div>
        </div>
      </Loader>
    )
  }
}


export default compose(withStyles(s), graphql(getEditStaticPage, {
  options: (props) => ({
    variables: {
      id: props.id
    },
    fetchPolicy: 'network-only',
    ssr: false
  })
}))(EditStaticPage)

import showToaster from '../../helpers/showToaster';
import { removeStaticPage, updateStaticPageImageMutation } from '../lib/graphql';
import { removeImage } from '../../helpers/removeImage';
import {
	ADMIN_UPDATE_STATIC_START,
	ADMIN_UPDATE_STATIC_SUCCESS,
	ADMIN_UPDATE_STATIC_ERROR
} from '../../constants';

export function deleteStaticPageImage(id, fileName) {
	return async (dispatch, getState, { client }) => {
		try {
			let url = "/deleteStaticBannerImage";

			dispatch({
				type: ADMIN_UPDATE_STATIC_START,
				payload: {
					loading: true
				}
			});
			const { data } = await client.mutate({
				mutation: removeStaticPage,
				variables: {
					id
				},
			});

			if (data) {
				dispatch({
					type: ADMIN_UPDATE_STATIC_SUCCESS,
					payload: {
						loading: true
					}
				});
				await removeImage(url, fileName);
			}
		} catch (error) {
			dispatch({
				type: ADMIN_UPDATE_STATIC_ERROR,
				payload: {
					loading: true
				}
			});
			return false;
		}
		return true;
	};
};

export default function updateStaticPageImage(id, pageBanner, oldPicture) {
	return async (dispatch, getState, { client }) => {
		try {
			dispatch({
				type: ADMIN_UPDATE_STATIC_START,
				payload: {
					loading: true
				}
			});
			const { data } = await client.mutate({
				mutation: updateStaticPageImageMutation,
				variables: {
					id,
					pageBanner
				}
			});

			let url = "/deleteStaticBannerImage";

			if (data?.updateStaticPageImage?.status === "200") {
				dispatch({
					type: ADMIN_UPDATE_STATIC_SUCCESS,
					payload: {
						loading: false
					}
				});
				showToaster({ messageId: 'imageUploaded', toasterType: 'success' });

				if (oldPicture !== null) {
					removeImage(url, oldPicture);
				};
			} else {
				dispatch({
					type: ADMIN_UPDATE_STATIC_ERROR,
					payload: {
						loading: false
					}
				});
			}
		} catch (err) {
			dispatch({
				type: ADMIN_UPDATE_STATIC_ERROR,
				payload: {
					loading: false
				}
			});
		}
	};
};

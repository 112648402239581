
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
// Style
import s from './Page.css';
import { api, staticpageUploadDir } from '../../config';

class Page extends React.Component {
  render() {
    const { data } = this.props;

    return (
          <div>
            {
              data && data.pageBanner && <div
                className={s.backGroungImg}
                style={{ backgroundImage: `url(${api.apiEndpoint + staticpageUploadDir + data.pageBanner})` }}
              />
            }
            {
              data && <div className={s.containerWidth}>
                <div className={s.staticContainer}>
                  <h1>{data.metaTitle}</h1>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.content }}
                  />
                </div>
              </div>
            }
          </div>
    );
  }
}

export default withStyles(s)(Page);

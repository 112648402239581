import fetch from 'node-fetch';
import { change } from 'redux-form';
import { checkShopUser as query } from '../../lib/graphql';

function checkUserEmail(values) {

    return async (dispatch, getState) => {
        const response = await fetch('/graphql', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query,
                variables: {
                    email: values.email
                }
            }),
            credentials: 'include'
        });

        const { data } = await response.json();

        if (data?.checkShopUser?.status === 200) {
            await dispatch(change('LoginForm', 'page', '2'));
            return {
                status: 200
            }
        } else {
            return {
                status: 400,
                errorMessage: data?.checkShopUser?.errorMessage
            }
        }
    }

}

export default checkUserEmail;